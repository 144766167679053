const MY_INFO = (state, data) => {
    state.myInfo = data;
};
const UPDATE_MY_INFO = (state, data) => {
    state.updateMyInfo = data;
};
const IS_UPDATE_USER = (state, data) => {
    state.isUpdateUser = data
};
const CHANGE_PASSWORD = (state, data) => {
    state.changePassword = data;
}; 
const IS_CHANGE_PASSWORD = (state, data) => {
    state.isChangePwd = data
};
const REPORT_WIN = (state, data) => {
    state.reportWin = data;
}; 
const REPORT_BETTING = (state, data) => {
    state.reportBetting = data;
};
const REPORT_STATEMENT = (state, data) => {
    state.reportStatement = data;
};
const UPDATE_LOADING = (state, data) => {
    state.loading = data;
};
const UPDATE_USER_INFO = (state, data) => {
    state.updateUserInfo = data;
};

export default {
    MY_INFO,
    UPDATE_MY_INFO,
    CHANGE_PASSWORD,
    REPORT_WIN,
    REPORT_BETTING,
    REPORT_STATEMENT,
    UPDATE_LOADING,
    UPDATE_USER_INFO,
    IS_UPDATE_USER,
    IS_CHANGE_PASSWORD
};
