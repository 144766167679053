<template>
    <div>
    <nav class=" d-md-none bottom-nav fixed-bottom d-flex flex-row align-items-center w-100">
      <div class="w-100 h-100 align-self-center  d-flex justify-content-between">
        <a @click="_goToRoute('/home')"  :class="$route.path=='/home' ? 'active focus' : ''" class=" btn btn-tab-radio d-flex flex-column align-items-center h-100 ">
          <span class=" name" v-if="passUserInfo && passUserInfo.home_channel && passUserInfo.home_channel.length > 0"><img @click="_goToRoute('/home')" src="../assets/images/cockfight icon.png" class="dancing-image" 
            width="35" height="35" 
            style="object-fit:cover"></span>
        </a> 
        <!-- <a @click="_goToRoute('/live')"  :class="$route.path=='/live' ? 'active focus' : ''" class=" btn btn-tab-radio d-flex flex-column align-items-center h-100 ">
            <img @click="_goToRoute('/live')" src="../assets/images/live-casino-icon.png" class="dancing-image" 
            width="35" height="35" 
            style="object-fit:cover">
            
      </a> -->
      <a @click="_goToRoute('/LOTTO')"  :class="$route.path=='/LOTTO' || $route.path=='/YUKI' || $route.path=='/LOTTERY' ? 'active focus' : ''" class=" btn btn-tab-radio d-flex flex-column align-items-center h-100 ">
            <img @click="_goToRoute('/LOTTO')" src="../assets/images/h_slot_machine.png" class="dancing-image"  
            width="35" height="35" 
            style="object-fit:cover">
      </a>
            <a href="#" @click="drawer = !drawer" class=" btn btn-tab-radio d-flex flex-column align-items-center h-100 ">
              <span style="margin-top: -0.3rem;">
                <svg data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="" class="svg-inline--fa1 fa-bars fa-w-14 fa-sm">
                  <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                </svg>
              </span> 
              <span style="margin-top: -0.3rem; display: none;">
                <svg data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" class="svg-inline--fa1 fa-times-circle fa-w-16 fa-sm">
                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
            </svg>
          </span> 
          <span class=" name">More</span></a></div>
    </nav>
    <v-navigation-drawer v-model="drawer" left fixed temporary>
            <SideBar :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeLogout="handleLogout"></SideBar>
        </v-navigation-drawer>
        </div>
</template>
<script>
import "@/assets/css/header.css";
import SideBar from './SideBar.vue';
import { mapMutations } from "vuex";
export default {
    components: { SideBar },
    props: ['passUserInfo'],
    data: () => ({
        drawer: false,
        websiteLogo:'//static3.goperya.com/img/logo-3.png?v=10.60'
    }),
    methods:{
        handleDrawer(getDrawer){
            this.drawer = getDrawer;
        },
        _goToRoute(path) {
            var selectedChannel = 1;
            if(path == '/home'){
                selectedChannel = this.$cookies.get('2bwinnwer_home_channel') ? this.$cookies.get('2bwinnwer_home_channel') : 1;
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/LOTTO'){
                selectedChannel = this.$cookies.get('2bwinnwer_slot_channel') ? this.$cookies.get('2bwinnwer_slot_channel') : 90
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/live'){
                selectedChannel = this.$cookies.get('2bwinnwer_live_channel') ? this.$cookies.get('2bwinnwer_live_channel') : 6
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }
            this.$router.push({ path }).catch(() => { /* ignore */ });
        },
        handleLogout(){
            this.$emit("invokeLogout");
        },
        
        ...mapMutations("$_modules", [
            "UPDATE_SOUND",
            "UPDATE_CHANNEL_ID"
        ]),
    }
}
</script>