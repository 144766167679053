const UPDATE_LOADING = (state, data) => {
    state.loading = data;
};
const REPORT_RESULT = (state, data) => {
    state.reportResult = data;
};

export default {
    UPDATE_LOADING,
    REPORT_RESULT
};