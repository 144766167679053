import Request from '@/services/axios'

let request = new Request;

const fetchMyInfo = async () => {
	return await request.get({
		url: 'user'
	});
};
const fetchUpdateMyInfo = async ({commit}, data) => {
    const response = await request.put({
        url: 'user',
        data
    })
    if (response.data.code) {
        commit('IS_UPDATE_USER', response.data.code);
        commit('UPDATE_MY_INFO', response.data.data);
    }
};
const fetchChangePassword = async ({commit}, data) => {
    const response = await request.post({
        url: 'user/changepassword',
        data
    })
    if (response.data.code) {
        commit('IS_CHANGE_PASSWORD', response.data);
        commit('CHANGE_PASSWORD', response.data.data);
    }
};
const fetchReportBetting = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'report/betting',
        data
    })
    if (response.data.code) {
        commit('REPORT_BETTING', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};
const fetchReportWin = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'report/win',
        data
    })
    if (response.data.code) {
        commit('REPORT_WIN', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};
const fetchReportStatement = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.get({
        url: 'report',
        data
    })
    if (response.data.code) {
        commit('REPORT_STATEMENT', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};
const fetchUserInfo = async ({commit}, data) => {
    const response = await request.get({
        url: 'user',
        data
    })
    if (response.data.code) {
        commit('UPDATE_USER_INFO', response.data.data);
    }
};

export default {
	fetchMyInfo,
    fetchUpdateMyInfo,
    fetchChangePassword,
	fetchReportBetting,
	fetchReportWin,
	fetchReportStatement,
    fetchUserInfo
};