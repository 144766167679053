<template>
    <v-flex class="login-logo-section-mobile p-5">
        <div class="m-auto text-center">
            <div id="wrapper">
            <div class="auth-page login-wrapper">
                <div class="login-panel position-relative py-2">
                    <div class="login-header">
                        <img :src="formLogo" class="d-block mx-auto login-header-img w-50">
                    </div>
                    <v-form ref="form" v-model="validate" lazy-validation @submit.prevent="login" class="form-container">
                        <div class="form-group fg-container">
                            <div class="py-2 auth-style">
                                <div
                                    class="auth-field-opt btn-group btn-group-toggle justify-content-between flex w-100 align-items-center my-2 fg-container">
                                    <button type="button" class=" mb-3 btn btn-tab-radio px-1 active focus btn-tab-switch">
                                        {{$t('title.login')}}
                                    </button>
                                </div>
                                <input v-model="passUser.user_name" placeholder="User name" label="Username"
                                    :rules="[rules.required]" required type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="eye-toggable auth-style" style="position: relative;">
                                <input v-model="passUser.password" :rules="[rules.required, rules.min]"
                                    :placeholder="$t('title.password')" :type="!isShowText ? 'password':'text'" required
                                    autocomplete="on"
                                    class="form-control form-control-user">
                                <div class="eye" @click="isShowText = !isShowText">
                                    <svg v-if="isShowText" class="svg-inline--fa fa-eye fa-w-16" data-prefix="fas" data-icon="eye"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                        data-fa-i2svg="">
                                        <path fill="currentColor"
                                            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                        </path>
                                    </svg>
                                    <svg v-else  class="svg-inline--fa fa-eye fa-w-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                       <path fill="currentColor" d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z"/>
                                    </svg>
                                    <!-- <i class="fas fa-eye"></i> -->
                                </div>
                            </div>
                        </div>
                        <div class="mt-3"><button v-if="!passLoading" type="submit"
                                class="btn btn-secondary btn-user btn-block mb-1 fg-container">
                                {{$t('title.log')}}
                            </button> <button v-if="passLoading" type="button" disabled="disabled" id="login-submitting"
                                class="btn btn-secondary btn-user btn-block fg-container">
                                {{$t('title.logging')}}
                            </button></div>
                    </v-form>
                  
                    <div class="lang-switcher-wr my-6">
                        {{$t('title.changeLanguage')}}:
                        <div>
                            <div role="group" aria-label="Language Switcher"
                                class="btn-group btn-group-toggle lang-switcher">
                                <a href="#" data-language="en" class="lang-item btn btn-sm  lang-eng" :class="passLanguage=='english' ? 'active':''" @click="_changeLanguage('english')">
                                    <img src="/images/flag-en.webp" alt="english" >English</a>
                                <a href="#" data-language="cn" class="lang-item btn btn-sm lang-cn" :class="passLanguage=='khmer' ? 'active':''"  @click="_changeLanguage('khmer')">
                                    <img src="/images/flag-kh.png" alt="khmer"> ខ្មែរ</a>
                                <a href="#" data-language="ph" class="lang-item btn btn-sm lang-ph" :class="passLanguage=='thai' ? 'active':''"  @click="_changeLanguage('thai')">
                                    <img src="/images/flag-th.jpg" alt="thai"> แบบไทย
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="py-3 outlet">
            <div class="outlet-container mx-auto py-2 py-sm-3">
                <hr class="line-seperate-form-mobile">
                <img class="img-fluid-mobile-text pb-5" src="//static3.goperya.com/img/playnow_using.png?v=10.60">
                <div class="row mt-4 mt-sm-0">
                    <div class="col-12 col-sm-4 py-1 py-sm-0 px-5 px-sm-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="img-fluid-mobile"
                                    src="//static3.goperya.com/landing/img/gcash.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>GCash</span></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 my-3 my-sm-0 px-5 px-sm-0 pt-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="img-fluid-mobile"
                                    src="//static3.goperya.com/img/paymaya.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>PayMaya</span></div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 py-1 py-sm-0 px-5 px-sm-0">
                        <div class="row d-flex flex-column align-items-center">
                            <div class="col-5 col-sm-12">
                                <img class="img-fluid-mobile" src="//static3.goperya.com/img/visa.png?v=10.60">
                            </div>
                            <div class="col-8 col-sm-12 text-center"><span>Visa Mastercard</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <nav class="fab-container fab-landing">
            <a class="buttons main-fab animated infinite pulse delay-2s flex-v-center"
                href="https://direct.lc.chat/12021612/8" target="_blank" id="chatBtn" data-toggle="tooltip"
                data-placement="left" title="Need help? Chat with us!">
                <svg class="svg-inline--fa fa-user-headset fa-w-14 fab-el-ic" style="" data-prefix="fas"
                    data-icon="user-headset" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                    data-fa-i2svg="">
                    <path fill="currentColor"
                        d="M320 352h-23.1a174.08 174.08 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM48 224a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.13 329.87 0 224 0S32 86.13 32 192v16a16 16 0 0 0 16 16zm160 0h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64z">
                    </path>
                </svg>
            </a>
        </nav> -->
        </div>
    </v-flex>
</template>   
<script>
import "@/assets/css/login.css";
export default {
    props: ['passUser', 'passValid', 'passLoading', 'passLanguage'],
    data() {
        return {
            rules: {
                required: [(value) => !!value || 'Required.'],
                min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            validate:false,
            formLogo: '/images/logo.png',
            isShowText : false
        }
    },
    methods: {
        reset() {
            this.$refs.form.reset();
        },
        async login() {
            if (this.$refs.form.validate()) {
                this.$emit("login", this.passUser);
            } else {
                this.reset();
            }
        },
        _changeLanguage(language){
            this.$emit("changeLanguage",language);
        }
    }
}
</script>
<style>
.auth-style> ::placeholder {
  color: white!important;
  opacity: 1; /* Firefox */
}</style>