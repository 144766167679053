const LATEST_FIVE_RESULTS = (state, data) => {
    state.lastFiveResults = data;
};
const CURRENT_BETTING = (state, data) => {
    state.currentBetting = data;
};
const GET_BUTTON = (state, data) => {
    state.buttonMoney = data;
};
const INPUT_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount = data;
};
const UPDATE_BETTING_AMOUNT = (state, data) => {
    state.bettingAmount =  parseFloat(state.bettingAmount) + parseFloat(data);
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATE_TOTAL_BETTING_AMOUNT = (state, data) => {
    console.log("==>", state.bettingAmount, data)
    // state.bettingAmount =  parseInt(state.bettingAmount) + parseInt(data);
};
const UPDATE_DATA_BETTING = (state, data) => {
    state.bettingData =  data
};
const APPEND_CURRENT_BETTING = (state, data) => {
    state.currentBetting.total_amount = state.currentBetting.total_amount + data.total_amount;
    state.currentBetting.list.unshift(data);
};
const PREVIOUS_BETTING = (state, data) => {
    state.previousBetting = data;
};
const CURRENT_GAME = (state, data) => {
    state.currentGame = data;
};
const CHANNEL_POST = (state, data) => {
    state.lotteryChannel = data;
};
const LATEST_ONE_RESULTS = (state, data) => {
    state.lastOneResult = data;
};
const LATEST_TWENTY_RESULTS = (state, data) => {
    state.lastTwentyResult = data;
};
const IS_BETTING = (state, data) => {
    state.is_Betting = data;
};

const UPDATE_CLIENT_TIME = (state, data) => {
    state.clientTime = data;
};


// New define
const UPDATE_BETTING_TYPE = (state, data) => {
    state.bettingType = data;
    state.selectedListBettingNumber = [];
    state.numbers.forEach((item, index) => state.selectedNumber[index] = false)
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_CHANNEL_POST = (state, data) => {
    if(!state.selectedPost.includes(data)) {
        state.selectedPost.push(data)
    }
    else{
        state.selectedPost.splice(state.selectedPost.indexOf(data), 1);
    }
    if(state.selectedPost.length <5 )  state.selectedAllPost = false;
    if(state.selectedPost.length == 5 )  state.selectedAllPost = true;
    state.totalInvoice = state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_SELECT_ALL_CHANNEL_POST = (state, data) => {
    state.selectedAllPost = !data;
    if(state.selectedPost.length <5 ) state.selectedPost=["A","B","C","D","E"];
    else state.selectedPost = [];
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UPDATED_SELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber.push(data);
    // console.log(state.selectedPost.length+"--"+ state.selectedListBettingNumber+"--"+state.bettingAmount)
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_LIST_BETTING = (state, data) => {
    state.selectedListBettingNumber =  state.selectedListBettingNumber.filter((n) => {
        return n != data
      });
      state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const UNSELECT_SPECIFIC_BETTING = (state, data) => {
    state.selectedNumber[data] = false;
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};

const FILTER_UNSELECTED = (state, data) => {
    state.selectedListBettingNumber = state.selectedListBettingNumber.filter((number) => {
        return !data.xcludeArr.includes(number);
      }
    );
    state.totalInvoice =  state.selectedPost.length * state.selectedListBettingNumber.length *  state.bettingAmount;
};
const CLEAR_TO_DEFAULT = (state) => {
    state.bettingType = 1;
    state.selectedPost=["A"];
    state.bettingAmount = 0;
    state.selectedAllPost = false;
    state.selectedListBettingNumber = [];
    state.totalInvoice = 0;
    state.numbers.forEach((item, index) => state.selectedNumber[index] = false);
};



export default {
    //define
    UPDATE_CLIENT_TIME,
    CLEAR_TO_DEFAULT,
    UPDATE_BETTING_TYPE,
    UPDATED_CHANNEL_POST,
    UPDATED_SELECT_ALL_CHANNEL_POST,
    UPDATED_SELECT_LIST_BETTING,
    UNSELECT_LIST_BETTING,
    UNSELECT_SPECIFIC_BETTING,
    FILTER_UNSELECTED,
    // --
    UPDATE_DATA_BETTING,
    UPDATE_BETTING_AMOUNT,
    LATEST_FIVE_RESULTS,
    LATEST_TWENTY_RESULTS,
    CURRENT_BETTING,
    PREVIOUS_BETTING,
    CURRENT_GAME,
    CHANNEL_POST,
    LATEST_ONE_RESULTS,
    APPEND_CURRENT_BETTING,
    IS_BETTING,
    GET_BUTTON,
    INPUT_BETTING_AMOUNT,
    UPDATE_TOTAL_BETTING_AMOUNT
};
