export default {
	username: 'Username',
	password: 'Password',
	login: 'Login',
	contact_us: 'Contact us',
	//validation
	invalid: {
		username: 'The input is not valid username!'
	},
	//required
	required: {
		username: 'Please input your username!',
		password: 'Please input your Password!'
	},
	//error
	error: {
		login_failed: "Your username or password doesn't exist."
	}
};
