<template>
  <div class="result-img w-100 row space-screen">
    <div class="elem-1" style="z-index:1000;">
      <div class="row w-100 m-0">
        <div class="text-left reserse-width-card  pl-2">

          <img :src="`/images/cards/${passPlayerCard2}`" class="card-style" />
          <img :src="`/images/cards/${passPlayerCard1}`" class="card-style ml-2" />
          <img :src="`/images/cards/${passPlayerCard3}`" class="card-style-rotate ml-3 " />

        </div>
        <div class="text-right w-50 pr-5 hide-small-screen  pt-2">
          <span style="font-size:20px; font-weight: 900; color:#167df1; text-shadow: 1px 1px black;"> Player </span>
          <span style="font-size:20px; font-weight: 900; color:white; text-shadow: 1px 1px black"> {{ passPlayerPoint }}
          </span>
        </div>
      </div>
    </div>

    <div class="elem-2" style="z-index:1000; ">
      <div class="row w-100 m-0">
        <div class="text-left pl-5 w-50 hide-small-screen pt-2">

          <span style="font-size:20px; font-weight: 900; color:white; text-shadow: 1px 1px black"> {{ passBankerPoint }}
          </span>
          <span style="font-size:20px; font-weight: 900; color:#f34141; text-shadow: 1px 1px black"> Banker </span>
        </div>
        <div class="text-right reserse-width-card pr-2">
          <img :src="`/images/cards/${passBankerCard3}`" class="card-style-rotate mr-3 " />
          <img :src="`/images/cards/${passBankerCard2}`" class="card-style mr-2" />
          <img :src="`/images/cards/${passBankerCard1}`" class="card-style " />


        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import PrintBetting from "./components/PrintBetting";
export default {
  props: ["passPlayerCard1", "passPlayerCard2", "passPlayerCard3", "passBankerCard1", "passBankerCard2", "passBankerCard3", "passPlayerPoint", "passBankerPoint"],
  components: {
    // PrintBetting
  },
}
</script>
<style scoped>
@media (max-width: 768px) {
  .hide-small-screen {
    display: none !important;
  }

  .reserse-width-card {
    width: 100% !important;
  }

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
  left: 0;
  bottom: 0px; */
    z-index: 100;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {

    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
    right: 0;
    bottom: 0px; */
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */

  }

  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 768px) {
  .reserse-width-card {
    width: 50% !important;
  }

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
    left: 0;
    bottom: 0px; */
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;

    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {

    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
    right: 0;
    bottom: 0px; */
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */

  }

  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 900px) {

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
    left: 0;
    bottom: 0px; */
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;

    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {

    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
  right: 0;
  bottom: 0px; */
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */

  }

  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 1280px) {

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";
    /* position: absolute;
    left: 0;
    bottom: 0px; */
    z-index: 1000;

    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {
    width: 50%;
    height: 52px;
    content: "";
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 1440px) {

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {
    width: 50%;
    height: 52px;
    content: "";
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */
  }
  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 1920px) {

  .elem-1 {
    /* background: rgb(243, 65, 65); */
    width: 50%;
    height: 52px;
    content: "";

    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
    -o-border-image: linear-gradient(100deg , rgba(22, 125, 241),white) 1;
    border-image: linear-gradient(100deg, rgba(22, 125, 241),white) 1;

    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

  }

  .elem-2 {

    width: 50%;
    height: 52px;
    content: "";
    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65));
    -o-border-image: linear-gradient(100deg,white, rgba(243, 65, 65),) 1;
    border-image: linear-gradient(100deg, white, rgba(243, 65, 65)) 1;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */

  }

  .card-style {
    width: 28px;
    height: 35px;
    margin-top: 8px;
  }

  .card-style-rotate {
    width: 28px;
    height: 35px;
    margin-top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (min-width: 960px) {
  .space-screen {
    margin:0px;
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 1px;
  }

}
</style>
