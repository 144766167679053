<template>
  <div>
    <lottery-info class="d-none d-sm-block"/>
    <v-row no-gutters class="mx-0">
      <lottery-betting class="d-none d-sm-block"/>
      <list-lottery-result />
    </v-row>
  </div>
</template>

<script>
import ListLotteryResult from "./SubComponent/ListLotteryResult";
import LotteryInfo from "@/modules/MachineGame/Lottery/components/SubComponent/LotteryInfo";
import LotteryBetting from "@/modules/MachineGame/Lottery/components/SubComponent/LotteryBetting";

export default {
  components: {
    ListLotteryResult,
    LotteryInfo,
    LotteryBetting,
  },
  data() {
    return {
    };
  },
};
</script>
