const UPDATE_LOADING = (state, data) => {
    state.loading = data;
};
const REPORT_BETTING = (state, data) => {
    state.reportBetting = data;
};

export default {
    UPDATE_LOADING,
    REPORT_BETTING
};
