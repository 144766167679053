<template>
  <div class="trends limit-on-tab">
    <div>
      <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center"
        style="background-color: #ffffff !important;">
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle"
            :class="detectTrendColor(passSelectedChannel, 'meron')">{{ passSelectedChannel == 18 ? resultObj.summary.B
              : passSelectedChannel == 19 ? resultObj.summary.DRAGON : resultObj.summary.MERON }}</span>
          <p class="mb-0 count-label">{{ passSelectedChannel == 18 ? $t('title.banker') : passSelectedChannel == 19 ?
            $t('title.dragon') : $t(sameGameFindName(passSelectedChannel) + 'MERON') }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle "
            :class="detectTrendColor(passSelectedChannel, 'wala')">{{ passSelectedChannel == 18 ? resultObj.summary.P
              : passSelectedChannel == 19 ? resultObj.summary.TIGER : resultObj.summary.WALA }}</span>
          <p class="mb-0 count-label">{{ passSelectedChannel == 18 ? $t('title.player') : passSelectedChannel == 19 ?
            $t('title.TIGER') : $t(sameGameFindName(passSelectedChannel) + 'WALA') }}</p> 
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle "
            :class="detectTrendColor(passSelectedChannel, 'tie')">{{ passSelectedChannel == 18 ? resultObj.summary.T
              : passSelectedChannel == 19 ? resultObj.summary.TIE : resultObj.summary.TIE }}</span>
          <p class="mb-0 count-label">{{ $t(sameGameFindName(passSelectedChannel) + 'draw') }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle bg-secondary">{{
          resultObj.summary.CANCEL }}</span>
          <p class="mb-0 count-label">{{ $t('title.cancel') }}</p>
        </div>
      </div>
      <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
        <div class="table-responsive pb-3 gp-scroll">
          <table class="trend-table">
            <tbody>
              <tr v-for="row in rows" :key="row">
                <td v-for="col in (channel.fix_column+8)" :key="col">
                  <div v-if="channel.column - channel.fix_column <= 0">
                    <div v-for="(result, i) in resultObj.results" :key="i">
                      <span v-if="col == result.cid && row == result.rid">
                        <div class="trend-item" :class="'' + _resultColor(passSelectedChannel, result.color)">
                          {{ result.name != 'CANCEL' ? result.fight_no : 'x'}}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(result, i) in resultObj.results" :key="i">
                      <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid">
                        <div class="trend-item" :class="'' + _resultColor(passSelectedChannel, result.color)">
                          {{ result.name != 'CANCEL' ? result.fight_no : 'x'}}
                        </div>
                      </span>
                    </div>                     
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import Mixin from "../../../../mixin/index";
export default {
  props: ["rows", "cols"],
  data() {
    return {
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_dashboard", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    ...mapGetters("$_modules", {
      passSelectedChannel: "getChannelId"
    }),
    orderResult() {
      return this.getListResults();
    },
  },
  methods: {
    getListResults() {
      if (this.resultObj.results !== null && this.resultObj.results.length > 0) {
        let col = this.channel.column;
        let fixCol = this.channel.fix_column
        if (col - fixCol <= 0) {
          return this.resultObj.results;
        } else {
          for (var index = this.resultObj.results.length - 1; index >= 0; index--) {
            this.resultObj.results[index].cid = index + (col - fixCol)
          }
          return this.resultObj.results;
        }
      }
      return this.resultObj.results;
    },
    
  },
};
</script>

<style scoped>
.history-result td {
  text-align: center;
  height: 45px;
  width: 45px;
  padding: 2px;
  background: #f2f6f9;
  border: 1px solid #96afde;
  vertical-align: middle;
  box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
  -moz-box-shadow: 0 0 2px 1px #fff;
  -webkit-box-shadow: 0 0 2px 1px #fff;
  -ms-box-shadow: 0 0 2px 1px #fff;
  -o-box-shadow: 0 0 2px 1px #fff;
}

.round-blue {
  background: url(../../../../assets/images/round-blue.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.round-red {
  background: url(../../../../assets/images/round-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.cycle-blue {
  background: url(../../../../assets/images/cycle-blue.png) no-repeat center;
  background-size: 35px;
  display: block;
  height: 35px;
}

.cycle-red {
  background: url(../../../../assets/images/cycle-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.center-padding-0 {
  text-align: center;
  padding: 0px !important;
}

.result-table {
  width: 100%;
  margin: 0;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  ;
}

.trend-table{
  border-spacing: 0px !important;
}
.trend-table td {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 10px;
  text-align: center;
  border: 1px solid #c5c5c5;
  padding: 1px;
}

td>div {
  width: 29px;
}
</style>