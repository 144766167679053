<template>
  <v-row no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" class="plus-middle-center-box">
      <div class="plus-middle-center-box-border">
        <div class="plus-middle-center-row-col">
          <div class="plus-middle-center-col">
            <div class="plus-middle-content">
              <input type="radio" @click="_selectType(1)" class="plus-middle-en-c" name="rd-play" 
                :checked="getBettingType == 1 "/>
              <span>{{$t('title.normal_betting')}}</span>
            </div>
          </div>
          <div class="plus-middle-center-col">
            <div class="plus-middle-content">
              <input type="radio" @click="_selectType(2)" class="plus-middle-en-c" name="rd-play" 
                :checked="getBettingType == 2 "/>
              <span>{{$t('title.go_with_tail')}}</span>
            </div>
          </div>
          <div class="plus-middle-center-col">
            <div class="plus-middle-content">
              <input type="radio" @click="_selectType(3)" class="plus-middle-en-c" name="rd-play" 
                :checked="getBettingType == 3"/>
              <span>{{$t('title.go_with_head')}}</span>
            </div>
          </div>
      
        </div>
        <div class="plus-middle-center-row-col">
          <div class="plus-middle-center-col" >
            <div class="plus-middle-content">
              <input type="checkbox" class="plus-middle-en-b" @click="_selectedAllChannelPost()" :checked="getSelectAllPost"  />
              <span>{{$t('title.all_post')}}</span>
            </div>
          </div>
          <div class="plus-middle-center-col" v-for="channelPost in getLotteryChannel" :key="channelPost._id">
            <div class="plus-middle-content">
              <input type="checkbox" class="plus-middle-en-b" :value="channelPost.name" v-model="getSelectedChannelPost" @click="_selectedChannelPost(channelPost.name)"/>
              <span>{{`${$t('title.post_channel')} ${ channelPost.name} (x90)`}}</span>
            </div>
          </div>
          
        </div>
      </div>

    </v-col>
  </v-row>
</template>
<script>
import {  mapGetters, mapMutations  } from "vuex";
export default {
  data() {
    return {
      selectedChannelPost: this.getSelectedChannelPost,
      selectAll: false,
    }
  },
  computed: {
    ...mapGetters("$_machine_lottery", {
     getLotteryChannel:'getLotteryChannel',
     getBettingType:'getSelectedBettingType',
     getSelectedChannelPost:'getSelectedChannelPost',
     getSelectAllPost:"getSelectAllPost"
    }),
  },
  methods:{
    _selectType(type){ 
      this.UPDATE_BETTING_TYPE(type);
    },
    _selectedChannelPost(post){
      this.UPDATED_CHANNEL_POST(post)
    },
    _selectedAllChannelPost(){
      this.UPDATED_SELECT_ALL_CHANNEL_POST(this.getSelectAllPost)
    },
    ...mapMutations("$_machine_lottery", [
    "UPDATE_BETTING_TYPE",
    "UPDATED_CHANNEL_POST",
    "UPDATED_SELECT_ALL_CHANNEL_POST"
  ]),
  }
}
</script>