const reportBetting = [
	{
		text: 'Fight No',
		align: 'start',
		sortable: false,
		value: 'fight_no'
	},
	{ text: 'Group No', value: 'group' ,sortable: false},
	{ text: 'Bet Name', value: 'bet_name' ,sortable: false},
	{ text: 'Date Time', value: 'date' ,sortable: false},
	{ text: 'Amount', value: 'amount' ,sortable: false},
	{ text: 'Payout', value: 'payout' ,sortable: false},
	{ text: 'Cast', value: 'cast' ,sortable: false},
	{ text: 'Amount Win', value: 'amount_win' ,sortable: false},
	{ text: 'Result Name', value: 'result_name' ,sortable: false},
	{ text: 'Result', value: 'is_win' ,sortable: false},
	{ text: 'Action', value: 'print' ,sortable: false},
];

export default reportBetting;