<template>
    <div>
     <CockFightBettingComponent 
         v-if="passSelectedChannel == 15  || passSelectedChannel == 16  || passSelectedChannel == 1  || passSelectedChannel == 2 || passSelectedChannel == 3 ||passSelectedChannel == 4 "
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
     
         <KlaklokBettingComponent 
             v-if="passSelectedChannel == 5"
             :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
             :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
             :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
             :passSelectedChannel="passSelectedChannel"
             :passUserCurrency="passUserCurrency"/>
 
         <BaccaratBettingComponent 
         v-if="passSelectedChannel == 6 || passSelectedChannel == 7"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
 
         <TigerDragonBettingComponent 
         v-if="passSelectedChannel == 8 || passSelectedChannel == 9"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
 
         <LottoBettingComponent 
         v-if="passSelectedChannel == 10"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
 
         <YukiBettingComponent 
         v-if="passSelectedChannel == 11"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
 
         <AbongBettingComponent 
         v-if="passSelectedChannel == 12 || passSelectedChannel == 17"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>

         <FanTanBettingComponent 
         v-if="passSelectedChannel == 13"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>

         <TaiSievBettingComponent 
         v-if="passSelectedChannel == 14"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passSelectedChannel="passSelectedChannel"
         :passUserCurrency="passUserCurrency"/>
         
         <Han2BettingComponent 
         v-if="passSelectedChannel == 20"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passUserCurrency="passUserCurrency"
         :passSelectedChannel="passSelectedChannel"/>
         
         <Han3BettingComponent 
         v-if="passSelectedChannel == 21"
         :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
         :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster"
         :passWeight="passWeight" v-on:sentSubmitBetting="receiveSubmitBetting"
         :passUserCurrency="passUserCurrency"
         :passSelectedChannel="passSelectedChannel"/>
    </div>
 </template>
 
 <script>
 
 import "@/assets/css/video-js.css";
 import "@/assets/css/homepage.css";
 import CockFightBettingComponent from "../_BettingComponentMobile/CockFightBettingComponentMobile.vue";
 import BaccaratBettingComponent from "../_BettingComponentMobile/BaccaratBettingComponent.vue";
 import TigerDragonBettingComponent from "../_BettingComponentMobile/TigerDragonBettingComponent.vue";
 import KlaklokBettingComponent from "../_BettingComponentMobile/KlaklokBettingComponent.vue";
 import LottoBettingComponent from "../_BettingComponentMobile/LottoBettingComponent.vue";
 import YukiBettingComponent from "../_BettingComponentMobile/YukiBettingComponent.vue";
 import AbongBettingComponent from "../_BettingComponentMobile/AbongBettingComponent.vue";
 import FanTanBettingComponent from "../_BettingComponentMobile/FanTanBettingComponent.vue";
 import TaiSievBettingComponent from "../_BettingComponentMobile/TaiSievBettingComponent.vue";
 import Han2BettingComponent from "../_BettingComponent/Han2BettingComponent.vue";
import Han3BettingComponent from "../_BettingComponent/Han3BettingComponent.vue";
 
 
 export default {
     components: { Han3BettingComponent, Han2BettingComponent, AbongBettingComponent, YukiBettingComponent, LottoBettingComponent, KlaklokBettingComponent, CockFightBettingComponent, BaccaratBettingComponent, TigerDragonBettingComponent, FanTanBettingComponent, TaiSievBettingComponent},
     props: [
         "passChannel",
         "passCurrentBetting",
         'passChannelStatus',
         'passGetButton',
         'passBettingTypeMaster',
         'passWeight',
         'passSelectedChannel',
         'passUserCurrency'
     ],
     data() {
         return {
             selectedButton: {},
             bettingMoney: 0,
         }
     },
     watch: {
     },
     computed: {
 
     },
     methods: {
        receiveSubmitBetting(recieveBettingData) {
            this.$emit("sentSubmitBetting", recieveBettingData);
        },
     }
 }
 </script>
 