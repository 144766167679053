<template>
  <div class="trends limit-on-tab">
    <div>
      <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-wala">{{ resultObj.summary.SMALL }}</span>
                <p class="mb-0 count-label">U</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-meron">{{ resultObj.summary.LARGE }}</span>
                <p class="mb-0 count-label">O</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-one">{{ resultObj.summary.RANGE1 }}</span>
                <p class="mb-0 count-label">1</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-two">{{ resultObj.summary.RANGE2 }}</span>
                <p class="mb-0 count-label">2</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-three">{{ resultObj.summary.RANGE3 }}</span>
                <p class="mb-0 count-label">3</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-four">{{ resultObj.summary.RANGE4 }}</span>
                <p class="mb-0 count-label">4</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-five">{{ resultObj.summary.RANGE5 }}</span>
                <p class="mb-0 count-label">5</p>
              </div>
        </div>
        <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
            <v-col cols="12" sm="12" style="padding:5px 0">
                <v-row style="padding:0; margin:0">
                    <v-col cols="6" md="6" sm="6" lg="6" style="padding:0 5px 0 0; margin:0">
                            <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in (channel.fix_column+8)" :key="col">
                        <div v-if="channel.column - channel.fix_column <= 0">
                          <div v-for="(result, i) in resultObj.results" :key="i">
                            <span v-if="col == result.cid && row == result.rid" style="padding:0;margin:0">
                              <v-avatar v-if="result && result.small_large && result.small_large.color" :color="result.small_large.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{  $t('button.' + result.small_large.name) }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <div v-for="(result, i) in resultObj.results" :key="i">
                            <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid" style="padding:0;margin:0">
                              <v-avatar v-if="result && result.small_large && result.small_large.color" :color="result.small_large.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{  $t('button.' + result.small_large.name) }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                            </div>
                    </v-col>
                    <v-col cols="6" md="6" sm="6" lg="6" style="padding:0; margin:0">
                            <div class="table-responsive pb-3 gp-scroll">
                              <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in orderList" :key="i">
                            <span v-if="col == result.col && row == result.row" style="padding:0;margin:0">
                              <v-avatar :color="result.range.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{ result.range.name === "CANCEL" ? "C" : result.range.name}}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                            </div>
                    </v-col>
                </v-row>
            </v-col>
        </div>
        <!-- <v-tabs  background-color="transparent">
          <v-tab>
            <v-icon left>
              mdi-file-export
            </v-icon>
            {{ $t("title.bet_small_large") }}
          </v-tab>
          <v-tab>
            <v-icon left>
              mdi-file-multiple
            </v-icon>
            {{ $t("title.bet_between") }}
          </v-tab>
          <v-tab-item>
            <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-wala">{{ resultObj.summary.SMALL }}</span>
                <p class="mb-0 count-label">U</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-meron">{{ resultObj.summary.LARGE }}</span>
                <p class="mb-0 count-label">O</p>
              </div>
            </div>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in (channel.fix_column+8)" :key="col">
                        <div v-if="channel.column - channel.fix_column <= 0">
                          <div v-for="(result, i) in resultObj.results" :key="i">
                            <span v-if="col == result.cid && row == result.rid" style="padding:0;margin:0">
                              <v-avatar v-if="result && result.small_large && result.small_large.color" :color="result.small_large.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{  $t('button.' + result.small_large.name) }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <div v-for="(result, i) in resultObj.results" :key="i">
                            <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid" style="padding:0;margin:0">
                              <v-avatar v-if="result && result.small_large && result.small_large.color" :color="result.small_large.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{  $t('button.' + result.small_large.name) }}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-one">{{ resultObj.summary.RANGE1 }}</span>
                <p class="mb-0 count-label">1</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-two">{{ resultObj.summary.RANGE2 }}</span>
                <p class="mb-0 count-label">2</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center mr-1"><span
                  class="count rounded-circle trend-three">{{ resultObj.summary.RANGE3 }}</span>
                <p class="mb-0 count-label">3</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-four">{{ resultObj.summary.RANGE4 }}</span>
                <p class="mb-0 count-label">4</p>
              </div>
              <div class="bad-cont d-inline-flex align-items-center "><span
                  class="count rounded-circle trend-five">{{ resultObj.summary.RANGE5 }}</span>
                <p class="mb-0 count-label">5</p>
              </div>
            </div>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in orderList" :key="i">
                            <span v-if="col == result.col && row == result.row" style="padding:0;margin:0">
                              <v-avatar :color="result.range.color" size="25"
                                style="margin-top:2px; color:white;font-weight: bold;">
                                {{ result.range.name === "CANCEL" ? "C" : result.range.name}}
                              </v-avatar>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
        </v-tabs> -->
    </div>
  </div>
</template>
<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
export default {
  props: ["rows", "cols"],
  data() {
      return {
      fixCol: 30,
      fixColBac: 20
      }
  },
  computed: {
  ...mapGetters("$_dashboard_live", {
    resultObj: "getResult",
    channel: "getChannel",
  }),
  orderList() {
    return this.getListResults();
  },
  orderBaccaratResult() {
    return this.getBaccaratResults();
  },
  getColor(resultName) {
    if (resultName === 'BANKER') {
      return "error"
    } else if (resultName === 'PLAYER') {
      return "primary";
    } else if (resultName === 'TIE') {
      return "success";
    } else {
      return "default";
    }
  }
},
methods: {
  getListResults() {
    let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results !== null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
           
            if (pre.range.name != item.range.name) {
              // console.log(pre.range.name, item.range.name, pre.col, pre.row);
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
              // console.log(newResult);
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
      }
      // console.log("newResult", newResult);
      return newResult;
  },
  findImageOfEachYear(yearId) {
    switch (yearId) {
      case 1:
        return "images/1.png";
      case 2:
        return "images/2-1.png";
      case 3:
        return "images/3.png";
      case 4:
        return "images/4.png";
      case 5:
        return "images/5.png";
      case 6:
        return "images/6.png";
      case 7:
        return "images/7.png";
      case 8:
        return "images/8.png";
      case 9:
        return "images/9.png";
      case 10:
        return "images/10.png";
      case 11:
        return "images/11.png";
      case 12:
        return "images/12.png";
      default:
      // code block
    }
  },
},
};
</script>

<style scoped>
.history-result td {
text-align: center;
height: 45px;
width: 45px;
padding: 2px;
background: #f2f6f9;
border: 1px solid #96afde;
vertical-align: middle;
box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
-moz-box-shadow: 0 0 2px 1px #fff;
-webkit-box-shadow: 0 0 2px 1px #fff;
-ms-box-shadow: 0 0 2px 1px #fff;
-o-box-shadow: 0 0 2px 1px #fff;
}
.round-blue {
  background: url(../../../../assets/images/round-blue.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.round-red {
  background: url(../../../../assets/images/round-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.cycle-blue {
  background: url(../../../../assets/images/cycle-blue.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.cycle-red {
  background: url(../../../../assets/images/cycle-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.center-padding-0 {
  text-align: center;
  padding: 0px !important;
}

.result-table {
  width: 100%;
  margin: 0;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  ;
}
.trend-table{
  border-spacing: 0px !important;
}
.trend-table td {
  width: 25px;
  height: 25px;
  font-size: 10px;
  text-align: center;
  padding: 0px;
}
.trend-table tr, td {
  border: 1px solid #c5c5c5;
}
/* table tbody{
    height: 156px;
} */

td>div {
  width: 25px;
}
</style>
