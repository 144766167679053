<template>
  <div class="result-img w-100 row space-screen" v-if="passListKlaklouk">

    <div class="elem-2" style="z-index:1000; ">
      <div class="row w-100 m-0 mx-auto justify-center ">
          <img v-if="passListKlaklouk[0] != null " :src="passListKlaklouk[0].image ? `/images/klaklok/${passListKlaklouk[0].image}` : ''"
            class="card-style-klaklok" />
          <img v-if="passListKlaklouk[1] != null " :src="passListKlaklouk[1].image ? `/images/klaklok/${passListKlaklouk[1].image}` : ''"
            class="card-style-klaklok mx-3" />
          <img v-if="passListKlaklouk[2] != null " :src="passListKlaklouk[2].image ? `/images/klaklok/${passListKlaklouk[2].image}` : ''"
            class="card-style-klaklok" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ["passListKlaklouk", "getResult"],
  components: {
    // PrintBetting
  },
}
</script>
<style scoped>
.elem-2 {
  width: 100%;
  height: 50px;
  content: "";
  position: absolute;
  right: 0;
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  /* border-style: solid;
  border-width: 2px; */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.7), rgba(0, 0, 0, 0.2));
  /* -o-border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
  border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1; */
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  /* background: rgb(0, 0, 0,0.5); */

}

.card-style-klaklok {
  width: 51px;
  height: 47px;
  padding-top:3px;
}
@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    
  }
}
</style>
