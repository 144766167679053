import Request from '@/services/axios'

let request = new Request;

const fetchReportWithdraw = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'report/withdraw',
        data
    })
    if (response.data.code) {
        commit('REPORT_WITHDRAW', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

export default {
	fetchReportWithdraw
};