<template>
    <div>
        <div class="g-summary">
            <div class="g-header" style="height:46px; padding: 0px;">
                <div class="g-name" style="height:46px">
                    <div class="video-title" direction="left;" style="width:100%; height:46px">
                        <v-tooltip bottom v-for="(item, index) in userInfo.home_channel" :key="index" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text  v-bind="attrs" v-on="on"
                                    class="btn-switch-channel pa-0"
                                    style="border-radius: 0!important;"
                                    :style="userInfo.home_channel.length - (passStartIndex-1) <= 5 ? `width:${100/(userInfo.home_channel.length - (passStartIndex))}%` : 'width:20%;' ">
                                    <div v-if="index >= passStartIndex && index <= passEndIndex"
                                        class=" infinite pulse delay-2s w-100 channel-select"
                                        @click="_switchChannel(item)"
                                        :class="item == passSelectedChannel ? 'class-actived' : 'class-not-active'"
                                        :disabled="item == passSelectedChannel">
                                        <img :src="correctChannelName(item)" class="img-channel-logo"/>
                                        <div
                                            class="dot-position" :style="item == passSelectedChannel ? 'color:black' :'color:white' ">
                                            <v-chip :color="resultChannelColor(item)"
                                                class="animated infinite pulse delay-2s chip-size"
                                                >
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div v-else class="to-hidden">
                                    </div>
                                </v-btn>
                            </template>
                            <span>{{  getGameName(item) }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import Mixin from "../../../../mixin/index";
import { mapGetters } from "vuex";
export default {
    props: [
        'passChannelStatus',
        "passSelectedChannel",
        "passStartIndex",
        "passEndIndex"
    ],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    },
    methods: {
        resultChannelColor(index) {
            if (this.passChannelStatus) {
                for (var i = 0; i < this.passChannelStatus.length; i++) {
                    if (this.passChannelStatus[i].channel_type == index) {
                        if (this.passChannelStatus[i].status == null) {
                            return "orange"
                        } else {
                            if (this.passChannelStatus[i].status) {
                                return "green"
                            }
                            return "red"
                        }
                    }
                }
            }
        },
        _switchChannel(selectedChannel) {
            this.$emit("sentSetChannel", selectedChannel);
        }
    }
}
</script>

<style>
.btn-switch-channel:has(.to-hidden) {
    display: none !important
}
.btn-switch-channel{
    max-height:47px!important;
    padding-top:0!important;
}
.video-section {
    position: relative;
    overflow: hidden;
    background-color: #003366
}
.v-btn:not(.v-btn--round).v-size--default{
    height: 50px !important;
}
.img-channel-logo{
    width:40px;
    height:40px;
    margin-top:-3px;
}
.class-actived{
    font-weight:bold!important; 
    color:black!important; 
    height:50px!important; 
    background-color: #cccccc!important; 
    position: relative!important;
    padding-top:0px;
}
.class-not-active{
    color:white!important; 
    height:47px!important; 
    position: relative!important;
    padding-top:2px;
}
.dot-position {
    position:absolute;
    top:18px;
    left: 75%;
    font-weight:700;
    color:white
}
.chip-size{
    height: 4px!important; 
    padding: 4px!important; 
    margin: -8px!important; 
    bottom:-3px;
}

</style>