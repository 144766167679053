import klaklouk from '@/modules/MachineGame/klaklouk';
import Layout from '@/layouts/default';

const routes = [
    {
        path: '/klaklouk',
            component: Layout,
            meta: {
                permission: 'master',
                requiresAuth: true
            },
            children: [
                {
                    path: '',
                    component: klaklouk,
                    name: 'klaklouk'
                }
            ]
    }
];

export default routes;