export default {
	update: 'อัปเดต',
	delete: 'ลบ',
	delete_all: 'ลบทั้งหมด',
	view: 'ดู',
	cancel: 'ยกเลิก',
	yes: 'ค่ะ',
	save: 'บันทึก',
	detail: 'รายละเอียด',
	create: 'สร้าง',
	upload_file: 'อัพโหลดไฟล์',
	clear: 'ชัดเจน',
	filter: 'กรอง',
	local_leng: 'ខ្មែរ',
	create_invoice: 'สร้างใบแจ้งหนี้',
	print: 'พิมพ์',	
	change_password: 'เปลี่ยนรหัสผ่าน',
	logout: 'ออกจากระบบ',
	meron:'เดิมพัน MERON',
	tie:'เดิมพัน TIE',
	wala:'เดิมพัน WALA',
	search:'ค้นหา',
	today:'วันนี้',
	last_day:'วันสุดท้าย',
	this_week:'ในสัปดาห์นี้',
	last_week:'อาทิตย์ที่แล้ว',
	this_month:'เดือนนี้',
	last_month:'เดือนที่แล้ว',
	small : "U",
	large : 'O',
	odd : "O",
	even : 'E',
	range : 'พิสัย',
	login:'เข้าสู่ระบบ',
	home:'บ้าน',
	all:'ทั้งหมด',
};
