<template>
    <div class="top-nav-fixed">
        <div id="top-nav-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
            <div class="w-sm-50 text-center d-flex align-items-center ">
                <div class="nav-icon-wr d-none d-md-flex align-items-center">
                    <v-btn text class="bm-icon mx-auto pl-2 " color="white" @click="_onSubmitDrawer(!passDrawer)"><svg data-prefix="fa" data-icon="bars"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""
                            class="svg-inline--fa fa-bars fa-w-14 fa-lg">
                            <path fill="currentColor"
                                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                            </path>
                        </svg>
                    </v-btn>
                    <div class="d-none d-sm-flex row col text-left hori-pa-15 "><span
                            class="name font-weight-bold mt-5 mx-5">{{passUserInfo && passUserInfo.user_name }}</span>
                            <div class="nav-icon-wr pl-2 ml-2" v-if="passUserInfo && passUserInfo.home_channel && passUserInfo.home_channel.length > 0">
                                <img @click="_goToRoute('/home')" src="../assets/images/cockfight icon.png" class="dancing-image" 
                                width="47" height="47" 
                                style="object-fit:cover" :class="$route.path=='/home' ? 'dancing-image-active' : ''">
                        </div>
                        
                        <div class="nav-icon-wr pt-3 pl-2 ml-2" style="margin-top:-2px; position: relative;" v-if="passUserInfo && passUserInfo.home_channel && passUserInfo.live_channel.length > 0">
                                <img @click="_goToRoute('/live')" src="../assets/images/live-casino-icon.png" class="dancing-image"  
                                width="45" height="45" 
                                style="object-fit:cover" :class="$route.path=='/live' ? 'dancing-image-active' : ''">
                                <img class="lotto-home" src="../assets/images/new-icon.gif" width="35" height="35" 
                                style="object-fit:cover; position: absolute; top: 23px;
                                right: -11px;" >
                        </div>
                        <div class="nav-icon-wr mt-1 pl-2 ml-2" style="margin-top:-2px; position: relative" v-if="passUserInfo&& passUserInfo.slot_channel&& passUserInfo.slot_channel.length > 0">
                                <img @click="_goToRoute('/LOTTO')" src="../assets/images/h_slot_machine.png" class="dancing-image"  
                                width="45" height="43" 
                                style="object-fit:cover" :class="$route.path=='/LOTTO' || $route.path=='/YUKI' || $route.path=='/LOTTERY' ? 'dancing-image-active' : ''">
                                <img class="lotto-home" src="../assets/images/new-icon.gif" width="35" height="35" 
                                style="object-fit:cover; position: absolute; top: 23px;
                                right: -11px;" >
                        </div>
                    </div>
                </div>
                <div class=" d-md-none nav-title-wr gold-text text-left pl-2 "></div>
            </div>
            <div class="nav-title-wr gold-text text-sm-center d-none d-md-block "></div>
            <div class="nav-credits-wr w-25 w-sm-50 gold-text pt-0">
                <a href="#" class="d-flex align-items-center justify-content-end gp-credits">
                    <div class="bg-success add-btn">
                        <svg data-prefix="fas" data-icon="coins" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512" data-fa-i2svg="" class="svg-inline--fa fa-coins fa-w-16">
                            <path fill="currentColor"
                                d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z">
                            </path>
                        </svg>
                    </div>
                    <div class="credits-data d-flex ">
                        <small class="mr-1">CREDITS:</small>
                        <small class="pr-2 gp-yellow-text font-weight-bold">{{
                            currencyFormat(passUserInfo.balance)
                        }}</small>
                    </div>
                    <v-btn @click="changeSound" icon>
                        <v-icon>{{ isSound ? 'mdi-volume-high' : 'mdi-volume-mute' }}</v-icon>
                    </v-btn>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import "@/assets/css/header.css";
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ['passUserInfo', 'passDrawer'],
    data: () => ({
        websiteLogo: '//static3.goperya.com/img/logo-3.png?v=10.60',
    }),
    computed: {
        ...mapGetters("$_modules", {
            isSound: "getIsSound"
        })
    },
    methods: {
        _onSubmitDrawer(data) {
            this.$emit("invokeDrawer", data);
        },
        currencyFormat(num) {
            if (num) {
                num = parseFloat(num)
                return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
            return num
        },
        changeSound() {
            this.UPDATE_SOUND(!this.isSound)
        },
        _goToRoute(path) {
            var selectedChannel = 1;
            if(path == '/home'){
                selectedChannel = this.$cookies.get('2bwinnwer_home_channel') ? this.$cookies.get('2bwinnwer_home_channel') : 1;
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/LOTTO'){
                selectedChannel = this.$cookies.get('2bwinnwer_slot_channel') ? this.$cookies.get('2bwinnwer_slot_channel') : 90
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }else if(path == '/live'){
                selectedChannel = this.$cookies.get('2bwinnwer_live_channel') ? this.$cookies.get('2bwinnwer_live_channel') : 6
                this.$cookies.set("2bwinnwer_channel_type", selectedChannel);
                this.UPDATE_CHANNEL_ID(selectedChannel);
            }
            this.$router.push({ path }).catch(() => { /* ignore */ });
        },
        ...mapMutations("$_modules", [
            "UPDATE_SOUND",
            "UPDATE_CHANNEL_ID"
        ]),
    }
}
</script>

<style>
.dancing-image {
    animation: dancing-image 1s ease infinite;
  }
  
  @keyframes dancing-image {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.04, 1.04);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .dancing-image:hover {
    box-shadow: 0px 2px 0px #f7bf30;
    -moz-box-shadow: 0px 2px 0px #f7bf30;
    -webkit-box-shadow: 0px 2px 0px #f7bf30;
    animation : none;
    cursor: pointer;
  }
  .lotto-home:hover{
    cursor: pointer;
  }
</style>
