<template>
  <div>
    <div class="lotto-nav-head">
      <Tabs />
    </div>
    <!-- top -->
    <v-row no-gutters>
      <v-col cols="3" class="yuki-padding-6">
        <div class="yuki-left-top">
          <div class="yk-l-top">
            <ul>
              <li>{{ $t("title.game_no") }}</li>
              <li>
                {{ currentGame && currentGame.date ? (currentGame.date.split("-").join("").slice(0, 8) + "#" +
                  currentGame.no) : " " }}
              </li>
            </ul>
          </div>
          <div class="yk-l-middle">
            <span>{{ $t("title.pleaseBet") }}</span>
          </div>
          <div class="yk-l-bottom">
            <span
              :style="passClientTime ? (passClientTime.time <= 10 ? 'color:darkred!important; text-shadow:1px 1px gold' : '') : ''">
              {{ passClientTime && passClientTime.time ? passClientTime.time : 0 }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="yuki-padding-6">
        <div class="curtain-container">

          <v-img src="images/yuki/Untitled2.png" style="z-index: 5 !important" class="curtains-header"></v-img>
          <div v-if="passClientRandom.is_open == true"
            :class="passClientRandom.is_open == true ? 'open-curtain slideInUp' : ''">
            <v-img src="images/yuki/curtains.png" class="curtains"></v-img>
          </div>
        </div>
        <v-img v-if="passClientResult" style="z-index: 0" class="result-img-blink result-img" :src="findImageOfEachAnimal(passClientResult.between_year.year_number)
          " :lazy-src="findImageOfEachAnimal(passClientResult.between_year.year_number)" />
        <v-img v-if="passRandomNumber && !passClientResult" class="result-img"
          :src="findImageOfEachAnimal(passRandomNumber)" :lazy-src="findImageOfEachAnimal(passRandomNumber)" />
        <div class="yuki-middle-border-left"></div>
        <div class="yuki-middle-border-right"></div>
        <div class="yuki-middle-border-bottom"></div>
      </v-col>


      <!-- <v-col cols="6" class="yuki-padding-6 ">
                <div class="yuki-middle-top">
                    <img class="yuki-middle-top-img-top" src="images/yuki/Untitled2.png"/>
                    <div class="yuki-middle-top-img-bottom-box">
                        <img class="yuki-middle-top-img-bottom" src="images/yuki/Untitled.png"/>
                    </div>
                    

                    <div class="yuki-middle-border-left"></div>
                    <div class="yuki-middle-border-right"></div>
                    <div class="yuki-middle-border-bottom"></div>
                </div>
            </v-col>
           -->

      <v-col cols="3" class="yuki-padding-6">
        <div class="yuki-right-top">
          <div class="yk-r-bet-limit">
            <span>{{ $t("title.betLimit") }}</span>
          </div>
          <div class="yk-r-top">
            <ul>
              <li>{{ $t("title.min") }}: <span>{{ rgetLimit.amount_min ? rgetLimit.amount_min.toLocaleString("en") :
                "0.00" }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span></li>
              <li>{{ $t("title.max") }}: <span>{{ rgetLimit.amount_max ? rgetLimit.amount_max.toLocaleString("en") :
                "0.00" }} {{ _displayCurrencySymbol(userInfor.account_currency) }}</span></li>
            </ul>
          </div>
          <div class="yk-r-middle">
            <span>{{ $t("header.balance") }}</span>
          </div>
          <div class="yk-r-bottom">
            <span style="color:#cc962a; font-weight:700">{{ userInfor && userInfor.balance ?
              _formatCurrency(userInfor) : '' }}{{ userInfor.currencySymbol }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="page-bet">
      <v-col cols="12" sm="12" md="12" class="yuki-padding-6 ">
        
        <!--  -->
        <v-row no-gutters>
          <v-col lg="6" sm="6" md="6">
            <v-row no-gutters v-if="passSixBtn">

              <v-col v-for="item in passSixBtn" :key="item._id" cols="4" sm="4" md="4" class="child-flex">
                <v-card tile outlined class="hold" style="margin: 0.5px " @click="_selected(item._id, 1)">
                  <input type="checkbox" v-if="item" :id=item._id :value=item._id v-model="checkedOneSix"
                    class="checkbox-style" @change="calculateMoney($event)" />
                  <v-img aspect-ratio="1">
                    <div class="bet-bet-Box-parent" style="position: relative; height: 100%; width: 100%">
                      <div style="position: absolute; height: 100%; width: 100%">
                        <div class="bet-Box" :class="item.year_number == 1 ||
                            item.year_number == 2 ||
                            item.year_number == 3
                            ? 'bet-Box-blue'
                            : 'bet-Box-red'
                          ">
                          <div class="btn-top-img">
                            <v-img style="overflow: initial" :src="findImageOfEachAnimal2(item.year_number)" :lazy-src="findImageOfEachAnimal2(item.year_number)
                              "></v-img>
                          </div>
                          <div class="my-edit-num">{{ item.year_number }}</div>
                          <div class="btn-bottom-img">
                            <v-img :src="findImageOfNumber(item.year_number)"
                              :lazy-src="findImageOfNumber(item.year_number)"></v-img>
                          </div>
                          <!--
                              <div class="btn-bottom-text-color d-flex" :class="
                                item.year_number == 1 ||
                                item.year_number == 2 ||
                                item.year_number == 3
                                  ? 'btn-bottom-text-blue'
                                  : 'btn-bottom-text-red'
                              ">
                                <span class="">{{ item.name }}</span>
                                <span class="">{{ item.payout }}</span>
                              </div>
                              -->
                        </div>
                      </div>
                      <span class="yuki-span-bet-name" :class="item.year_number == 1 ||
                          item.year_number == 2 ||
                          item.year_number == 3
                          ? 'yuki-btn-bottom-text-blue'
                          : 'yuki-btn-bottom-text-red'
                        ">{{ $t(_g_translate(item.name)) }}</span>
                      <span class="yuki-span-bet-payout" :class="item.year_number == 1 ||
                          item.year_number == 2 ||
                          item.year_number == 3
                          ? 'yuki-btn-bottom-text-blue'
                          : 'yuki-btn-bottom-text-red'
                        ">{{ item.payout }}</span>

                    </div>
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
              <!-- thin -->
              
              <v-col cols="4" sm="4" md="4" class="mt-1">
                <v-card v-if="passSmallBig.length > 0" tile outlined :color="passSmallBig[0].color"
                  @click="_selected(passSmallBig[0]._id, 5)" class="hold-thin betting-btn-container">
                  <div>
                    <input type="checkbox" v-if="passSmallBig && passSmallBig[0]" :id=passSmallBig[0]._id
                      :value=passSmallBig[0]._id v-model="checkedSmallBig" class="checkbox-style"
                      @change="calculateMoney($event)" />
                    <v-btn dark x-large text class="col-white zoom">

                      <span class="col-white">{{
                        passSmallBig.length > 0 && passSmallBig[0].name
                        ? $t(_g_translate(passSmallBig[0].name))
                        : "loading..."
                      }}</span>
                    </v-btn>
                    <span class="bottom-payout">{{
                      passSmallBig.length > 0 && passSmallBig[0].payout
                      ? passSmallBig[0].payout
                      : "loading..."
                    }}</span>
                  </div>
                </v-card>
              </v-col>
              <v-col v-if="passRangeSmall" cols="4" sm="4" md="4" class="mt-1">
                <v-card v-if="passSmallBig.length > 0" tile outlined :color="passSmallBig[1].color"
                  @click="_selected(passSmallBig[1]._id, 5)" class="hold-thick betting-btn-container">
                  <div>
                    <input type="checkbox" v-if="passSmallBig && passSmallBig[1]" :id=passSmallBig[1]._id
                      :value=passSmallBig[1]._id v-model="checkedSmallBig" class="checkbox-style"
                      @change="calculateMoney($event)" />
                    <v-btn class="zoom" color="#ff0000" dark x-large text>

                      <span class="col-white">{{
                        passSmallBig.length > 0 && passSmallBig[1].name
                        ? $t(_g_translate(passSmallBig[1].name))
                        : "loading..."
                      }}</span>
                    </v-btn>
                    <span class="bottom-payout">{{
                      passSmallBig.length > 0 && passSmallBig[1].payout
                      ? passSmallBig[1].payout
                      : "loading..."
                    }}</span>
                  </div>
                </v-card>
              </v-col>
              <!--  -->
             
              <v-col cols="4" sm="4" md="4" class="mt-1" @click="_openBettingDialog(passRangeSmall[0])">
                <v-card v-if="passRangeSmall.length > 0" tile outlined :color="passRangeSmall[0].color"
                  @click="_selected(passRangeSmall[0]._id, 3)" class="betting-btn-container">
                  <div>
                    <input type="checkbox" v-if="passRangeSmall && passRangeSmall[0]" :id=passRangeSmall[0]._id
                      :value=passRangeSmall[0]._id v-model="checkedRangeSmall" class="checkbox-style"
                      @change="calculateMoney($event)" />
                    <v-btn class="zoom" color="#0000ff" dark x-large text>

                      <span class="col-white">{{
                        passRangeSmall[0].name
                      }}</span>
                    </v-btn>
                    <span class="bottom-payout">{{
                      passRangeSmall[0].payout
                    }}</span>
                  </div>
                </v-card>
              </v-col>
              <!--  -->
              <!--  -->
            </v-row>
          </v-col>
          <!-- end thin -->
          <v-col sm="6" md="6">
            <v-row no-gutters v-if="passSixToTwelve">

              <v-col cols="4" sm="4" md="4" v-for="item in passSixToTwelve" :key="item._id">
                <v-card tile outlined class="hold" style="margin: 0.5px" @click="_selected(item._id, 2)">
                  <input type="checkbox" :id=item._id :value=item._id v-model="checkedSevenUp" class="checkbox-style"
                    @change="calculateMoney($event)" />
                  <v-img aspect-ratio="1">
                    <div class="bet-bet-Box-parent" style="position: relative; height: 100%; width: 100%">
                      <div style="position: absolute; height: 100%; width: 100%">
                        <div class="bet-Box" :class="item.year_number == 7 ||
                            item.year_number == 8 ||
                            item.year_number == 9
                            ? 'bet-Box-blue'
                            : 'bet-Box-red'
                          ">
                          <div class="btn-top-img">
                            <v-img style="overflow: initial" :src="findImageOfEachAnimal2(item.year_number)" :lazy-src="findImageOfEachAnimal2(item.year_number)
                              "></v-img>
                          </div>
                          <div class="my-edit-num">{{ item.year_number }}</div>
                          <div class="btn-bottom-img">
                            <v-img :src="findImageOfNumber(item.year_number)"
                              :lazy-src="findImageOfNumber(item.year_number)"></v-img>
                          </div>
                          <!--
                              <div class="btn-bottom-text-color d-flex" :class="
                                item.year_number == 7 ||
                                item.year_number == 8 ||
                                item.year_number == 9
                                  ? 'btn-bottom-text-blue'
                                  : 'btn-bottom-text-red'
                              ">
                                <span class="">{{ item.name }}</span>
                                <span class="">{{ item.payout }}</span>
                              </div>
                              -->


                        </div>
                      </div>

                      <span class="yuki-span-bet-name" :class="item.year_number == 7 ||
                          item.year_number == 8 ||
                          item.year_number == 9
                          ? 'yuki-btn-bottom-text-blue'
                          : 'yuki-btn-bottom-text-red'
                        ">{{ $t(_g_translate(item.name)) }}</span>
                      <span class="yuki-span-bet-payout" :class="item.year_number == 7 ||
                          item.year_number == 8 ||
                          item.year_number == 9
                          ? 'yuki-btn-bottom-text-blue'
                          : 'yuki-btn-bottom-text-red'
                        ">{{ item.payout }}</span>

                    </div>
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>

              <!--  -->
              <!--  -->
              
              <v-col v-if="passRangeSmall" cols="4" sm="4" md="4" class="mt-1">
                <v-card v-if="passRangeSmall.length > 0" tile outlined :color="passRangeSmall[1].color"
                  @click="_selected(passRangeSmall[1]._id, 3)" class="betting-btn-container">
                  <div>
                    <input type="checkbox" v-if="passRangeSmall && passRangeSmall[1]" :id=passRangeSmall[1]._id
                      :value=passRangeSmall[1]._id v-model="checkedRangeSmall" class="checkbox-style"
                      @change="calculateMoney($event)" />
                    <v-btn class="zoom" color="#0000ff" dark x-large text>
                      <span class="col-white">{{
                        passRangeSmall[1].name
                      }}</span>
                    </v-btn>
                    <span class="bottom-payout">{{
                      passRangeSmall[1].payout
                    }}</span>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="4" sm="4" md="4" class="mt-1" v-for="item in passRangeBig" :key="item._id">
                <v-card v-if="passRangeBig.length > 0" tile outlined :color="item.color" @click="_selected(item._id, 4)"
                  class="betting-btn-container">
                  <div>
                    <input type="checkbox" :id=item._id :value=item._id v-model="checkedRangeBig" class="checkbox-style"
                      @change="calculateMoney($event)" />
                    <v-btn class="zoom" color="#ff0000" dark x-large text>
                      <span class="col-white">{{ item.name }}</span>
                    </v-btn>
                    <span class="bottom-payout">{{ item.payout }}</span>
                  </div>
                </v-card>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
        <!-- end 1 row -->
        <!-- start 2 row -->

        <v-row no-gutters style="padding-top: 3px !important;">
          <v-col lg="3" sm="3" md="3">
            <v-btn v-if="passOddPair" text block x-large role="button"
              style="color: #ffffff; border-radius: 0 !important; padding: 3px" :style="passOddPair.length > 0
                  ? 'background-color:' + passOddPair[1].color
                  : ''
                " @click="_selected(passOddPair[1]._id, 6)">
              <input v-if="passOddPair && passOddPair[1]" type="checkbox" :id=passOddPair[1]._id :value=passOddPair[1]._id
                v-model="checkedOddPair" class="checkbox-style1" @change="calculateMoney($event)">
              <span style="z-index: 9">{{
                passOddPair.length > 0 ? $t(_g_translate(passOddPair[1].name)) : "loading..."
              }}</span>

              <span class="payout">{{
                passOddPair.length > 0 && passOddPair[1].payout
                ? passOddPair[1].payout
                : "loading..."
              }}</span>
            </v-btn>
          </v-col>
          <v-col lg="3" sm="3" md="3">
            <v-btn v-if="passOddPair" text block x-large role="button"
              style="color: #ffffff; border-radius: 0 !important; padding: 3px" :style="passOddPair.length > 0 ? ' background-color:' + passOddPair[0].color : ''
                " @click="_selected(passOddPair[0]._id, 6)">
              <input type="checkbox" v-if="passOddPair && passOddPair[0]" :id=passOddPair[0]._id :value=passOddPair[0]._id
                v-model="checkedOddPair" class="checkbox-style1" @change="calculateMoney($event)" />

              <span style="z-index: 9; ">{{
                passOddPair.length > 0 ? $t(_g_translate(passOddPair[0].name)) : "loading..."
              }}</span>
              <span class="payout">{{
                passOddPair.length > 0 ? passOddPair[0].payout : "loading..."
              }}</span>
            </v-btn>
          </v-col>
          <v-col lg="3" sm="3" md="3">
            <v-btn v-if="passBlueRed" text block x-large role="button"
              style="color: #ffffff; border-radius: 0 !important; padding: 3px"
              :style="passBlueRed.length > 0 ? ' background-color:' + passBlueRed[1].color : ''"
              @click="_selected(passBlueRed[1]._id, 7)">
              <input type="checkbox" v-if="passBlueRed && passBlueRed[1]" :id=passBlueRed[1]._id :value=passBlueRed[1]._id
                v-model="checkedBlueRed" class="checkbox-style1" @change="calculateMoney($event)" />
              <span style="z-index: 9; ">{{
                passBlueRed.length > 0 ? $t(_g_translate(passBlueRed[1].name)): "loading..."
              }}</span>
              <span class="payout">{{
                passBlueRed.length > 0 && passBlueRed[1].payout
                ? passBlueRed[1].payout
                : "loading..."
              }}</span>
            </v-btn>
          </v-col>
          <v-col lg="3" sm="3" md="3">
            <v-btn text block x-large role="button" style="color: #ffffff; border-radius: 0 !important; padding: 3px; "
              :style="passBlueRed.length > 0 ? ' background-color:' + passBlueRed[0].color : ''"
              @click="_selected(passBlueRed[0]._id, 7)">
              <input type="checkbox" v-if="passBlueRed && passBlueRed[0]" :id=passBlueRed[0]._id :value=passBlueRed[0]._id
                v-model="checkedBlueRed" class="checkbox-style1" @change="calculateMoney($event)" />
              <span style="z-index: 9">{{
                passBlueRed.length > 0 ? $t(_g_translate(passBlueRed[0].name)): "loading..."
              }}</span>
              <span class="payout">{{
                passBlueRed.length > 0 ? passBlueRed[0].payout : "loading..."
              }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <!-- end 2 row -->

      </v-col>
    </v-row>
    <v-row no-gutters>

      <!--
            <v-col cols="12"  class="yuki-padding-6 yuki-year-box">
                <div  class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/rat.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/ox.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/tiger.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/horse.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/goat.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-blue">
                        <img src="images/yuki/monkey.png" />
                    </div>
                    
                </div>

                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/rabet.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/gragon.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/snake.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/rooster.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/dog.png" />
                    </div>
                    
                </div>
                <div class="yuki-year-item">
                    <div class="yuki-year-item-border-red">
                        <img src="images/yuki/pig.png" />
                    </div>
                    
                </div>
            </v-col>

            <v-col cols="12" class="yuki-padding-6 yuki-odd-even-box">
                <div class="yuki-odd-even-item ">
                    <div class="yk-color-blue">
                        <span>ខៀវ</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-even">
                        <span>គូរ</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-blue">
                        <span>1-2-3</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-blue">
                        <span>7-8-9</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-red">
                        <span>ក្រហម</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-odd">
                        <span>សេស</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-red">
                        <span>4-5-6</span>
                    </div>
                </div>
                <div class="yuki-odd-even-item">
                    <div class="yk-color-red">
                        <span>10-11-12</span>
                    </div>
                </div>
            </v-col>
          -->

      <v-col cols="12" class="yuki-padding-6">
        <div class="yuki-input-bet">
          <div class="yk-bet-input-number">
            <input placeholder="ENTER BET AMOUNT" type="number" v-model="amount" pattern="[0-9]*" min="1"
              onlynumber="true" class="" onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="color:black!important; border:2px solid #0c478b!important" />
            <span><v-icon @click="handleClear">mdi-history</v-icon></span>
          </div>
          <div class="yk-bet-bet-btn">
            <v-btn large class="yk-bet-bet-btn-content" style="width: 100%;" color="primary" :disabled="isBetting"
              @click="handleOk">{{ $t('title.submit_betting') }}</v-btn>
          </div>
        </div>

      </v-col>

      <v-col cols="12" class="yuki-padding-6">
        <div class="justify-content-center row text-center mx-2 my-6 px-0 " style="margin-top: 0 !important;">
          <!--
                    <div class="text-center w-100 display-money mb-5">
                            {{
                                    userInfor && userInfor.currency_type == 2
                                    ? formatMoney(passPlaceCoin) :
                                    formatMoney(passPlaceCoin)
                            }}
                    </div>
                    -->
          <div class="row ma-0 w-100 mx-auto text-center">
            <div class="row coin-container ma-0 px-0 gp-scroll ">
              <div v-for="(button, index) in passPlaceCoin" :key="button._id" class=" ma-0 p-0 col-2 ">
                <div @click="_inputAmount(button.amount)">
                  <img :src="_detectCoin(index)" class="btn-coin text-center">
                  <div text :style="`color:${_detectColor(index)};`" class="text btn-bet-circle prevent-select">
                    {{ button.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Tabs from "../../../../components/machine/Tabs.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Tabs,
  },
  props: [
    'passGetButton',
    'passChannel',
    'passRandomNumber',
    'passClientRandom',
    'passClientResult',
    "passClientTime",
  ],
  data() {
    return {
      selectedButton: {},
      betType: 0,
      selectBetType: [],
      checkedOneSix: [],
      checkedSevenUp: [],
      checkedRangeSmall: [],
      checkedRangeBig: [],
      checkedSmallBig: [],
      checkedOddPair: [],
      checkedBlueRed: [],
      totalMoney: 0,
      rgetLimit: [],
      amount: 0,
      errorMessage: null,
      isBetting: false,
    }
  },
  async created() {
    this.getLimit();
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_machine_yuki", {
      currentGame: "getCurrentGame",
      is_Betting: "getIsBetting",
      passPlaceCoin: "getButtonMoney",
      getLastOneResult: "getLastOneResult",
      passSixBtn: "getFirstSixBtn",
      passSixToTwelve: "getSixToTwelve",
      passRangeSmall: "getRangeSmall",
      passRangeBig: "getRangeBig",
      passSmallBig: "getSmallBig",
      passOddPair: "getOddPair",
      passBlueRed: "getBlueRed"
    }),
  },
  methods: {
    async getLimit() {
      await this.$request
        .getSlot({
          url: "m_yuki/limit",
        })
        .then((res) => {
          if (res.data.code) {
            this.rgetLimit = res.data.data;

          }
        });
    },
    _g_translate(x) {
      switch (x) {
        case "ជូត":
          return "title.yuki_wipe";
        case "ឆ្លូវ":
          return "title.yuki_street";
        case "ខាល":
          return "title.yuki_karl";
        case "ថោះ":
          return "title.yuki_thao";
        case "រោង":
          return "title.yuki_factory";
        case "ម្សាញ់":
          return "title.yuki_net";
        case "ស្តើង":
          return "title.yuki_thin";
        case "ក្រាស់":
          return "title.yuki_thick";
        case "មមី":
          return "title.yuki_mimi";
          case "មមែ":
          return "title.yuki_mom";
        case "វក":
          return "title.yuki_vk";
        case "រកា":
          return "title.yuki_roka";
        case "កុរ":
          return "title.yuki_kor";
        case "សេស":
          return "title.yuki_odd";
        case "គូ":
          return "title.yuki_pair";
        case "ក្រហម":
          return "title.yuki_red";
        case "ខៀវ":
          return "title.yuki_blue";
        case "ច":
          return "title.yuki_dog";
        default:
          return 'yuki_null'
      }
    },
    _selected(id, type) {
      if (type == 1) {
        if (this.checkedOneSix.includes(id)) {
          let i = this.checkedOneSix.map(item => item).indexOf(id) // find index of your object
          this.checkedOneSix.splice(i, 1)
        } else {
          this.checkedOneSix.push(id)
        }
      } else if (type == 2) {
        if (this.checkedSevenUp.includes(id)) {
          let i = this.checkedSevenUp.map(item => item).indexOf(id) // find index of your object
          this.checkedSevenUp.splice(i, 1)
        } else {
          this.checkedSevenUp.push(id)
        }
      } else if (type == 3) {
        if (this.checkedRangeSmall.includes(id)) {
          let i = this.checkedRangeSmall.map(item => item).indexOf(id) // find index of your object
          this.checkedRangeSmall.splice(i, 1)
        } else {
          this.checkedRangeSmall.push(id)
        }
      } else if (type == 4) {
        if (this.checkedRangeBig.includes(id)) {
          let i = this.checkedRangeBig.map(item => item).indexOf(id) // find index of your object
          this.checkedRangeBig.splice(i, 1)
        } else {
          this.checkedRangeBig.push(id)
        }
      } else if (type == 5) {
        if (this.checkedSmallBig.includes(id)) {
          let i = this.checkedSmallBig.map(item => item).indexOf(id) // find index of your object
          this.checkedSmallBig.splice(i, 1)
        } else {
          this.checkedSmallBig.push(id)
        }
      } else if (type == 6) {
        if (this.checkedOddPair.includes(id)) {
          let i = this.checkedOddPair.map(item => item).indexOf(id) // find index of your object
          this.checkedOddPair.splice(i, 1)
        } else {
          this.checkedOddPair.push(id)
        }
      } else if (type == 7) {
        if (this.checkedBlueRed.includes(id)) {
          let i = this.checkedBlueRed.map(item => item).indexOf(id) // find index of your object
          this.checkedBlueRed.splice(i, 1)
        } else {
          this.checkedBlueRed.push(id)
        }
      }
      this.calculateMoney()
    },
    getAmount(money) {
      if (this.amount) this.amount = parseInt(this.amount) + parseInt(money);
      else this.amount = parseInt(money);
      if (this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0) {
        this.totalMoney = this.amount
        // console.log(`checkboxbtn: ${this.totalMoney}`);
      } else {
        this.calculateMoney()
      }
      this.errorMessage = null;
    },
    calculateMoney() {
      this.$nextTick(() => {
        if (this.amount) {
          this.totalMoney = 0
          if (this.checkedOneSix.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedOneSix.length)
          }
          if (this.checkedSevenUp.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedSevenUp.length)
          }
          if (this.checkedRangeSmall.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedRangeSmall.length)
          }
          if (this.checkedRangeBig.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedRangeBig.length)
          }
          if (this.checkedSmallBig.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedSmallBig.length)
          }
          if (this.checkedOddPair.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedOddPair.length)
          }
          if (this.checkedBlueRed.length > 0) {
            this.totalMoney = this.totalMoney + (this.amount * this.checkedBlueRed.length)
          }
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox: ${this.totalMoney}`);
        } else {
          this.totalMoney = 0;
          this.UPDATE_TOTAL_INVOICE(this.totalMoney);
          // console.log(`checkbox unchecked: ${this.totalMoney}`);
        }
      })
    },
    handleClear() {
      this.amount = 0;
      this.totalMoney = 0;
      this.UPDATE_TOTAL_INVOICE(this.totalMoney);
      //this.amount = null;
      this.errorMessage = null;
    },
    async handleOk() {
      const { amount, isBetting, userInfor } = this;
      if (isBetting) return;
      this.isBetting = true;
      // console.log(amount);
      if (!amount || amount < 1) {
        this.errorMessage = "Please enter your bet amount!";
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (userInfor.balance <= amount) {
        this.errorMessage = "Not Enough Balance";
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (amount < userInfor.amount_min || amount > userInfor.amount_max) {
        this.errorMessage = `Bet amount must between bet limit`;
        this.$toastr.e(`${this.errorMessage}`)
        // console.log(this.errorMessage);
        this.isBetting = false;
        return;
      }
      if (this.checkedOneSix.length <= 0 &&
        this.checkedSevenUp.length <= 0 &&
        this.checkedRangeSmall.length <= 0 &&
        this.checkedRangeBig.length <= 0 &&
        this.checkedSmallBig.length <= 0 &&
        this.checkedOddPair.length <= 0 &&
        this.checkedBlueRed.length <= 0) {
        this.errorMessage = `Please select your bet!`;
        this.$toastr.e(`${this.errorMessage}`)
        this.isBetting = false;
        return;
      }
      try {
        let betData = [];
        let betAmount = parseInt(amount);
        if (this.checkedOneSix.length > 0) {
          this.checkedOneSix.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedSevenUp.length > 0) {
          this.checkedSevenUp.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedRangeSmall.length > 0) {
          this.checkedRangeSmall.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedRangeBig.length > 0) {
          this.checkedRangeBig.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedSmallBig.length > 0) {
          this.checkedSmallBig.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedOddPair.length > 0) {
          this.checkedOddPair.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        if (this.checkedBlueRed.length > 0) {
          this.checkedBlueRed.forEach((yuki_id) => {
             betData.push({ yuki_id : yuki_id, amount : betAmount })
          })
        }
        // console.log(betData);
        const response = await this.createBet(betData);
        if (response.data.code) {
          this.amount = 0;
          this.UPDATE_TOTAL_INVOICE(0);
          this.fetchUserInfo();
          this.checkedOneSix = [],
            this.checkedSevenUp = [],
            this.checkedRangeSmall = [],
            this.checkedRangeBig = [],
            this.checkedSmallBig = [],
            this.checkedOddPair = [],
            this.checkedBlueRed = [],
            this.fetchCurrentBetting();
          this.$toastr.s(`${response.data.message.descriptions}`)
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
          this.error = true;
        }
        this.isBetting = false;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    _detectColor(index) {
      switch (index) {
        case 0:
          return 'black';
        case 1:
          return 'black';
        case 2:
          return 'black';
        case 3:
          return 'black';
        case 4:
          return 'black';
        case 5:
          return 'black';
        case 6:
          return 'black';
        case 7:
          return 'black';
        case 99:
          return 'white';
        default:
          return ''
      }
    },
    _detectCoin(index) {
      switch (index) {
        case 0:
          return '/images/coins/blue.png';
        case 1:
          return '/images/coins/pink.png';
        case 2:
          return '/images/coins/green.png';
        case 3:
          return '/images/coins/cyan.png';
        case 4:
          return '/images/coins/orange.png';
        case 5:
          return '/images/coins/red.png';
        case 6:
          return '/images/coins/blue.png';
        case 7:
          return '/images/coins/pink.png';
        case 33:
          return '/images/coins/grey.png';
        default:
          return ''
      }
    },
    _inputAmount(money) {

      // if (!this.passChannel.is_open) {
      //     this.$toastr.e(this.$t("title.gameIsClose"));
      //     return false;
      // }
      /*
      this.bettingMoney = parseInt(this.bettingMoney);
      this.bettingMoney += parseInt(money)
      this.$emit("handleInputMonay", money);*/
      this.amount = parseInt(this.amount) + parseInt(money);
      this.calculateMoney()
    },
    formatCoinCurrency(amount) {
      switch (amount) {
        case 1000:
          return '1ព';
        case 5000:
          return '5ព';
        case 10000:
          return '1ម';
        case 20000:
          return '2ម';
        case 50000:
          return '5ម';
        case 100000:
          return '10ម';
        case 200000:
          return '20ម';
        case 500000:
          return '50ម';
        case 1000000:
          return '1ល';
        case 5000000:
          return '5ល';
        default:
          return amount;
      }
    },
    formatBtnCurrencyKh(amount) {
      switch (true) {
        case (amount >= 1000 && amount <= 9999):
          return amount / 1000 + "ព";
        case (amount >= 10000 && amount <= 999999):
          return amount / 10000 + "ម";
        case (amount >= 1000000 && amount <= 99999999999):
          return amount / 1000000 + "ល";
        default:
          return amount
      }
    },
    formatBtnCurrency(amount) {
      switch (true) {
        case (amount >= 1000 && amount <= 9999):
          return amount / 1000 + "K";
        default:
          return amount
      }
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return "images/1.png";
        case 2:
          return "images/2-1.png";
        case 3:
          return "images/3.png";
        case 4:
          return "images/4.png";
        case 5:
          return "images/5.png";
        case 6:
          return "images/6.png";
        case 7:
          return "images/7.png";
        case 8:
          return "images/8.png";
        case 9:
          return "images/9.png";
        case 10:
          return "images/10.png";
        case 11:
          return "images/11.png";
        case 12:
          return "images/12.png";
        default:
        // code block
      }
    },
    findImageOfNumber(yearId) {
      switch (yearId) {
        case 1:
          return "images/number/1.png";
        case 2:
          return "images/number/2.png";
        case 3:
          return "images/number/3.png";
        case 4:
          return "images/number/4.png";
        case 5:
          return "images/number/5.png";
        case 6:
          return "images/number/6.png";
        case 7:
          return "images/number/7.png";
        case 8:
          return "images/number/8.png";
        case 9:
          return "images/number/9.png";
        case 10:
          return "images/number/10.png";
        case 11:
          return "images/number/11.png";
        case 12:
          return "images/number/12.png";
        default:
        // code block
      }
    },
    findImageOfEachAnimal(yearId) {
      switch (yearId) {
        case 1:
          return "images/animal/1.png";
        case 2:
          return "images/animal/2.png";
        case 3:
          return "images/animal/3.png";
        case 4:
          return "images/animal/4.png";
        case 5:
          return "images/animal/5.png";
        case 6:
          return "images/animal/6.png";
        case 7:
          return "images/animal/7.png";
        case 8:
          return "images/animal/8.png";
        case 9:
          return "images/animal/9.png";
        case 10:
          return "images/animal/10.png";
        case 11:
          return "images/animal/11.png";
        case 12:
          return "images/animal/12.png";
        default:
        // code block
      }
    },
    findImageOfEachAnimal2(yearId) {
      switch (yearId) {
        case 1:
          return "images/animal/1 - 1.png";
        case 2:
          return "images/animal/2 - 2.png";
        case 3:
          return "images/animal/3 - 3.png";
        case 4:
          return "images/animal/4 - 4.png";
        case 5:
          return "images/animal/5 - 5.png";
        case 6:
          return "images/animal/6 - 6.png";
        case 7:
          return "images/animal/7 - 7.png";
        case 8:
          return "images/animal/8 - 8.png";
        case 9:
          return "images/animal/9 - 9.png";
        case 10:
          return "images/animal/10 - 10.png";
        case 11:
          return "images/animal/11 - 11.png";
        case 12:
          return "images/animal/12 - 12.png";
        default:
        // code block
      }
    },
    formatMoney(amount) {
      return amount.toLocaleString("en");
    },
    ...mapActions("$_machine_yuki", [
      "createBet",
      "fetchCurrentBetting",
    ]),
    ...mapMutations("$_dashboard", [
      "BETTING_DIALOG"
    ]),
    ...mapMutations("$_machine_yuki", [
      "BET_TYPE",
      "IS_BETTING",
      "UPDATE_TOTAL_INVOICE"
    ]),
    ...mapActions("$_modules", [
      "fetchUserInfo"
    ]),
  }
};
</script>
<style scoped>
.checkbox-style {
  position: absolute;
  top: 1.5px;
  right: 0px;
  z-index: 9;
}

input[type=checkbox] {
  transform: scale(1.2);
  margin-right: 2px;
}

.v-dialog>.v-card>.v-card__text {
  padding-bottom: 10px !important;
}

.v-btn:not(.v-btn--round).v-size--x-large {
  height: 50px;
  min-width: 60px;
  padding: 0 0px;
}

.v-btn__content {
  height: 100% !important;
}

.checkbox-style1 {
  position: absolute;
  top: 1.5px;
  right: -2px;
  margin-top: -10px
}



@media(max-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.2rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-coin-cover {
    width: 100%
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:760px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:960px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.25rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 1rem;
    font-weight: 900;
    text-shadow: 1px 0px 1px black;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

@media(min-width:1280px) {
  .btn-coin {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0.3rem;
    margin: 0px !important;
  }

  .btn-coin-cover:hover {
    cursor: pointer;
  }

  .money-in-coin-position {
    margin-top: -52px;
    margin-bottom: 25px;
    z-index: 100;
    font-weight: 900;
    font-size: 16px;
  }

  .btn-bet-circle {
    font-family: 'khmer mef1';
    position: absolute;
    font-size: 16px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -51%);
    padding: 0px;
    margin: 0px !important;
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.hold-coin {
  position: sticky;
  left: 0px;
  z-index: 100;
  background: #013366;
  font-size: bolder;
  border-right: 2px dashed white;
}

.coin-container {
  overflow: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.coin-container:hover {
  cursor: pointer;
}

.display-money {
  background-color: rgb(255, 255, 255, .9);
  height: 40px;
  border-radius: 5px;
  margin: 5px 0px 7px 0px;
  color: black;
  font-weight: bolder;
  text-shadow: 1px 0.2px #406386;
  font-size: 1.3rem;
  padding-top: 5px;
}


.yk-bet-input-number span .v-icon.v-icon {
  line-height: 45px;
  color: red !important;
}

/*.bet-Box{
  background: none;
}*/
.btn-top-img {
  width: 70%;
  position: absolute;
  top: 2%;
  left: 0%;
}

.bet-Box {
  background: none;
}

.bet-Box-blue {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(0, 0, 255, .8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.bet-Box-red {
  background: linear-gradient(237deg, transparent, transparent 93.71%),
    linear-gradient(137deg, transparent, transparent 92.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, .0) 50.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.7) 0%, rgba(0, 0, 255, .0) 55.71%),
    linear-gradient(316deg, rgba(255, 0, 0, 0.8) 30%, rgba(0, 0, 255, .0) 30.71%);
  height: 100%;
  width: 100%;
  position: relative;
}

.my-edit-num {

  position: absolute;
  bottom: 12%;
  right: 4%;
  color: white;
  font-weight: 800;
}

.bottom-payout {
  color: white;
}

.v-chip,
.v-chip__content,
.v-list-item__title,
span {
  color: white;
  font-weight: 700;
}

.yuki-span-bet-name {
  position: absolute;
  top: 62%;
  left: 12%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-span-bet-payout {
  position: absolute;
  top: 25%;
  left: 66%;
  font-size: 1rem;
  font-weight: 900;
}

.yuki-btn-bottom-text-blue {
  color: blue;
}

.yuki-btn-bottom-text-red {
  color: red;
}

.curtain-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.curtains {
  width: 100%;
  height: 355px;
  z-index: 0 !important;
  margin-top: -119px !important;
  margin-bottom: 20px;
  object-fit: contain;
  margin-left: 0px;
}

.curtains-header {
  width: 100%;
  height: 50px !important;
  z-index: 1 !important;
}

.result-img {
  width: 33%;
  height: auto;
  position: absolute !important;
  top: 42%;
  right: 34%;
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.result-img-blink {
  transition: 0.3s ease-in;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.open-curtain {
  position: absolute;
  width: 100%;
  -webkit-animation: curtain 14.2s !important;
  animation: curtain 14.2s !important;
  z-index: 1;
  padding-left: 20px;
}

@keyframes curtain {
  0% {
    left: -30px;
  }

  50% {
    left: 43%;
  }

  100% {
    left: 100%;
  }
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)>.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}

.classMobile {
  padding: 0 !important;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}</style>
