<template>
  <div>
    <div class="d-flex flex-column text-center m-4">
      <div class="d-flex flex-column mx-auto w-50">
        <img class="w-100" :src="websiteLogo" />
      </div>
    </div>
    <div class="d-flex flex-column text-center m-4">
      <h6 class="name font-weight-bold mb-0">{{ passUserInfo.user_name }}</h6>
      <!-- <small class="gp-yellow-text">{{ passUserInfo.account_info }}</small> -->
    </div>
    <div class="menu-container mb-5">
      <a href="/" class="sb-item-row">
        <span class="sb-icon">
          <svg class="svg-inline--fa-1 fa-play fa-w-14" data-prefix="fa" data-icon="play" role="img"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
            <path fill="currentColor"
              d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z">
            </path>
          </svg>
        </span>
        <span class="sb-text">{{ $t("title.play") }}</span>
      </a>
      <hr />
      <div class="row no-gutters px-5 sub-menu">
        <div class="col">{{ $t("title.report") }}</div>
      </div>
      <a href="/allstatement" class="sb-item-row "><span class="sb-icon">
          <svg class="svg-inline--fa-1 fa-cart-plus fa-w-18" data-prefix="fa" data-icon="list" role="img"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor"
              d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z">
            </path>
          </svg>

        </span>
        <span class="sb-text">{{ $t("title.all_report") }}</span></a>
      

      <hr />
      <div class="sub-menu">{{ $t("title.history") }}</div>
      <a href="/deposit" class="sb-item-row"><span class="sb-icon"><svg class="svg-inline--fa-1 fa-box-check fa-w-20"
            data-prefix="fad" data-icon="box-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
            data-fa-i2svg="">
            <g class="fa-group">
              <path class="fa-secondary" fill="currentColor"
                d="M448 128c-106 0-192 86-192 192s86 192 192 192 192-86 192-192-86-192-192-192zm114.1 147.8l-131 130a11 11 0 0 1-15.6-.1l-75.7-76.3a11 11 0 0 1 .1-15.6l26-25.8a11 11 0 0 1 15.6.1l42.1 42.5 97.2-96.4a11 11 0 0 1 15.6.1l25.8 26a11 11 0 0 1-.1 15.5z">
              </path>
              <path class="fa-primary" fill="currentColor"
                d="M240 0H98.6a47.87 47.87 0 0 0-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240zm208 80a221.93 221.93 0 0 1 27.2 1.7l-16.3-48.8A47.83 47.83 0 0 0 413.4 0H272v157.4C315.9 109.9 378.4 80 448 80zM208 320a238.53 238.53 0 0 1 20.2-96H0v240a48 48 0 0 0 48 48h256.6C246.1 468.2 208 398.6 208 320zm354.2-59.7l-25.8-26a11 11 0 0 0-15.6-.1l-97.2 96.4-42.1-42.5a11 11 0 0 0-15.6-.1l-26 25.8a11 11 0 0 0-.1 15.6l75.7 76.3a11 11 0 0 0 15.6.1l131-130a11 11 0 0 0 .1-15.5z">
              </path>
            </g>
          </svg>
        </span>
        <span class="sb-text">{{ $t("title.cash_in") }}</span></a>
      <a href="/withdraw" class="sb-item-row"><span class="sb-icon"><svg class="svg-inline--fa-1 fa-coins fa-w-16"
            data-prefix="fa" data-icon="coins" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            data-fa-i2svg="">
            <path fill="currentColor"
              d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z">
            </path>
          </svg>
        </span>
        <span class="sb-text">{{ $t("title.cash_out") }}</span>
      </a>
      <hr />

      <div class="sub-menu">{{ $t("title.account_settings") }}</div>
      <a href="/my-info" class="sb-item-row"><span class="sb-icon"><svg class="svg-inline--fa-1 fa-pencil fa-w-16"
            data-prefix="fa" data-icon="pencil" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            data-fa-i2svg="">
            <path fill="currentColor"
              d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z">
            </path>
          </svg></span>
        <span class="sb-text">{{ $t("title.edit_acount") }}</span></a>

        <a class="sb-item-row">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span class="sb-icon"
              ><img class="w-100" src="/images/icons8-language-96.png"
            /></span>
            <span
              class="sb-text"
              v-bind="attrs"
              v-on="on"
              style="color: white; padding: 5px"
            >
              {{ $t("title.languages") }}
            </span>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="handleChangeLanguage(item.title)"
            >
              <v-list-item-icon>
                <img :src="item.icon" style="width: 30px" />
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </a>

      <a href="#" class="sb-item-row" @click="_onSubmitLogout"><span class="sb-icon">
          <svg class="svg-inline--fa-1 fa-sign-out-alt fa-w-16" data-prefix="fas" data-icon="sign-out-alt" role="img"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="currentColor"
              d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z">
            </path>
          </svg>
        </span>
        <span class="sb-text">{{ $t("title.logout") }}</span></a>
    </div>
  </div>
</template>
<script>
import "@/assets/css/header.css";
export default {
  props: ["passUserInfo"],
  data: () => ({
    drawer: false,
    websiteLogo: "/images/logo.png",
    khmer: "/images/flag-kh.png",
    eng: "/images/flag-en.webp",
    thai: "/images/flag-th.jpg",
    vietnam: "/images/flag-vn.jpg",
    lang: "/images/icons8-language-96.png",
    items: [
      { title: "Khmer", icon: "/images/flag-kh.png" },
      { title: "English", icon: "/images/flag-en.webp" },
      { title: "Thai", icon: "/images/flag-th.jpg" },
      { title: "Vietnam", icon: "/images/flag-vn.png" },
    ],
  }),
  methods: {
    _onSubmitLogout() {
      this.$emit("invokeLogout");
    },
    handleChangeLanguage(language) {
      this.lang = language;
      // console.log(language);
      switch (language) {
        case "English":
          this.changeLangEn();
          break;
        case "Khmer":
          this.changeLangKh();
          break;
        case "Thai":
          this.changeLangTh();
          break;
        case "Vietnam":
          this.changeLangVN();
          break;
      }
    },
    changeLangEn() {
      let lang = this.$cookies.get("local");

      this.$i18n.locale = lang && lang == "english" ? "english" : "english";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangKh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangTh() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
      this.$cookies.set("local", this.$i18n.locale);
    },
    changeLangVN() {
      let lang = this.$cookies.get("local");
      this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
      this.$cookies.set("local", this.$i18n.locale);
    },
  },
};
</script>