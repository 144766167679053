<template>
  <v-row>

    <v-col cols="12" sm="12" class="mx-auto">
      <div class="g-summary mt-4 mx-auto">
        <div class="g-header video-section rounded-top" style="height:50px; padding: 0px; margin-top: -6px; ">
          <div class="g-name" style="height:50px">
            <div class="video-title" direction="left;" style="width:100%; height:50px">
              <v-tooltip bottom v-for="(item, index) in userInfo.live_channel" :key="index" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="btn-switch-channel pa-0" v-bind="attrs" v-on="on"
                  :style="userInfo.live_channel.length <= 7 ? `width:${100/userInfo.live_channel.length}%` : 'width:14.28%;' "
                  style="margin-top:0px;  border-radius: 0px;">
                  <div v-if="index <= 6"
                  class=" infinite pulse delay-2s w-100 channel-select pt-2 "
                  @click="_switchChannel(item)"
                  :style="item == passSelectedChannel ? 'font-weight:bold; color:black; height:50px; background-color: #cccccc; position: relative; padding-top:7px' : 'color:white; height:50px; position: relative; padding-top:7px'"
                  :disabled="item == passSelectedChannel">
                  <img :src="correctChannelName(item)" class="img-channel-logo"/>
                  <div
                      style="position:absolute; top:18px; left: 75%; font-weight:700; color:white" :style="item == passSelectedChannel ? 'color:black' :'color:white' ">
                      <v-chip :color="resultChannelColor(item)"
                          class="animated infinite pulse delay-2s"
                          style="height: 4px; padding: 4px; margin: -8px!important; bottom:-3px">
                      </v-chip>
                  </div>
              </div>
                    <div v-else class="to-hidden">
                    </div>
                  </v-btn>
                </template>
                <span>{{  getGameName(item) }}</span>
              </v-tooltip>
              
            </div>
          </div>

        </div>
      </div>
      <VideoLiveForDesktop :channel="passChannel" :passSelectedChannel="passSelectedChannel" />
      <div :style=" passChannel.is_open != true  && passSelectedChannel!=15  && passSelectedChannel!=16 && passSelectedChannel!=1 && passSelectedChannel!=2 && passSelectedChannel!=3 &&passSelectedChannel!=4 &&  passSelectedChannel!=10 &&  passSelectedChannel!=20&&  passSelectedChannel!=21? 'height:38px; width:100%' : passSelectedChannel==10 ?'height:78px; width:100%': ''">
        <ResultVideoKlaKlok v-if="(passSelectedChannel == 5) && passChannel.is_open != true"
          :passListKlaklouk="listKlaklouk" getResult:="getResult" />
        <ResultVideoBaccarat
          v-if="(passSelectedChannel == 6 || passSelectedChannel == 7) && passChannel.is_open != true"
          :passPlayerCard1="playerCard1" :passPlayerCard2="playerCard2" :passPlayerCard3="playerCard3"
          :passBankerCard1="bankerCard1" :passBankerCard2="bankerCard2" :passBankerCard3="bankerCard3"
          :passPlayerPoint="playerPoint" :passBankerPoint="bankerPoint" />
        <ResultVideoDragon v-if="(passSelectedChannel == 8 || passSelectedChannel == 9) && passChannel.is_open != true"
          :passDragonCard="dragonCard" :passTigerCard="tigerCard" />
        <ResultVideoLotto v-if="passSelectedChannel == 10 && passChannel.is_open != true" :passListLotto="listLotto" />
        <ResultVideoYuki v-if="passSelectedChannel == 11 && passChaserInfo.home_channelnnel.is_open != true" :passListYuki="listYuki" />
        <ResultVideoApong v-if="(passSelectedChannel == 12 || passSelectedChannel == 17) && passChannel.is_open != true"
          :passListAbong="listAbong" />
          <ResultVideoFantan v-if="(passSelectedChannel == 13) && passChannel.is_open != true"
                                        :passListAbong="listAbong" />
                                    <ResultVideoTaisiv v-if="(passSelectedChannel == 14) && passChannel.is_open != true"
                                        :passListTaisiv="listTaiSiv" />
      </div>
      <div class="g-summary" :class="passSelectedChannel!=15 && passSelectedChannel!=16 &&  passSelectedChannel!=1 && passSelectedChannel!=2 && passSelectedChannel!=3 && passSelectedChannel!=4 && passSelectedChannel!=20&& passSelectedChannel!=21? 'pt-4' : ''" style="border-bottom:3px solid black">
        <div class="g-header video-section rounded-top" style="height:46px; padding: 0px; margin-top:-2px ">
          <div class="g-name" style="height:50px">
            <div class="video-title" direction="left;" style="width:100%; height:50px">
              <v-tooltip top v-for="(item, index) in userInfo.live_channel" :key="index" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="btn-switch-channel pa-0"
                  :style="userInfo.live_channel.length <= 7 ? `width:${100/userInfo.live_channel.length}%` : 'width:14.28%;' "
                    style="margin-top:-2.5px;  border-radius: 0px;">
                    <div v-if="index >= 7"
                    class=" infinite pulse delay-2s w-100 channel-select pt-2 "
                    @click="_switchChannel(item)"
                    :style="item == passSelectedChannel ? 'font-weight:bold; color:black; height:50px; background-color: #cccccc; position: relative;' : 'color:white; height:50px; position: relative;'"
                    :disabled="item == passSelectedChannel">
                    <img :src="correctChannelName(item)" class="img-channel-logo"/>
                    <div
                        style="position:absolute; top:18px; left: 75%; font-weight:700; color:white" :style="item == passSelectedChannel ? 'color:black' :'color:white' ">
                        <v-chip :color="resultChannelColor(item)"
                            class="animated infinite pulse delay-2s"
                            style="height: 4px; padding: 4px; margin: -8px!important; bottom:-3px">
                        </v-chip>
                    </div>
                </div>
                    <div v-else class="to-hidden">
                    </div>
                  </v-btn>
                </template>
                <span>{{  getGameName(item) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="g-summary" 
      v-if="userInfo.live_channel && userInfo.live_channel.length >= 15"
      :class="passSelectedChannel!=15 && passSelectedChannel!=16 &&  passSelectedChannel!=1 && passSelectedChannel!=2 && passSelectedChannel!=3 && passSelectedChannel!=4 ? 'pt-4' : ''" style="border-bottom:3px solid black">
        <div class="g-header video-section rounded-top" style="height:46px; padding: 0px; margin-top:-2px ">
          <div class="g-name" style="height:50px">
            <div class="video-title" direction="left;" style="width:100%; height:50px">
              <v-tooltip top v-for="(item, index) in userInfo.live_channel" :key="index" >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="btn-switch-channel pa-0 pt-1"
                  :style="userInfo.live_channel.length <= 7 ? `width:${100/userInfo.live_channel.length}%` : 'width:14.28%;' "
                    style="margin-top:-2.5px;  border-radius: 0px;">
                    <div v-if="index >= 14"
                    class=" infinite pulse delay-2s w-100 channel-select pt-2 "
                    @click="_switchChannel(item)"
                    :style="item == passSelectedChannel ? 'font-weight:bold; color:black; height:50px; background-color: #cccccc; position: relative;' : 'color:white; height:50px; position: relative;'"
                    :disabled="item == passSelectedChannel">
                    <img :src="correctChannelName(item)" class="img-channel-logo"/>
                    <div
                        style="position:absolute; top:18px; left: 75%; font-weight:700; color:white" :style="item == passSelectedChannel ? 'color:black' :'color:white' ">
                        <v-chip :color="resultChannelColor(item)"
                            class="animated infinite pulse delay-2s"
                            style="height: 4px; padding: 4px; margin: -8px!important; bottom:-3px">
                        </v-chip>
                    </div>
                </div>
                    <div v-else class="to-hidden">
                    </div>
                  </v-btn>
                </template>
                <span>{{  getGameName(item) }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <BettingComponent :passCurrentBetting="passCurrentBetting" :passChannel="passChannel"
        :passGetButton="passGetButton" :passBettingTypeMaster="passBettingTypeMaster" :passWeight="passWeight"
        v-on:sentSubmitBetting="receiveSubmitBetting" :passSelectedChannel="passSelectedChannel"
        :passUserCurrency="passUserCurrency"/>
      <ReportBetting  v-if="getIsReport"
                        :passSelectedChannel="passSelectedChannel"
                        :passLoadingTodayReport="passLoadingTodayReport" />
      <TableResult :rows="6" :cols="35" :passSelectedChannel="passSelectedChannel" style="margin-top: 2px;"/>
    </v-col>

  </v-row>
</template>
<script>

import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
import Mixin from "../../../../mixin/index";
import VideoLiveForDesktop from "../videoLiveForDesktop.vue";
import TableResult from "../TableResultMobile";
import BettingComponent from "./BettingComponent.vue";
import ReportBetting from "../ReportBetting.vue";
import ResultVideoDragon from "../_ResultOnVideo/ResultVideoTigerDragon.vue";
import ResultVideoBaccarat from "../_ResultOnVideo/ResultVideoBaccarat.vue";
import ResultVideoKlaKlok from "../_ResultOnVideo/ResultVideoKlaKlok.vue";
import ResultVideoLotto from '../_ResultOnVideo/ResultVideoLotto.vue';
import ResultVideoYuki from '../_ResultOnVideo/ResultVideoYuki.vue';
import ResultVideoApong from '../_ResultOnVideo/ResultVideoApong.vue';
import ResultVideoTaisiv from "../_ResultOnVideo/ResultVideoTaisiv.vue";
import ResultVideoFantan from "../_ResultOnVideo/ResultVideoFantan.vue";
export default {
  components: {ResultVideoFantan, ResultVideoTaisiv, ResultVideoBaccarat,ResultVideoDragon, ResultVideoKlaKlok, ResultVideoLotto, ResultVideoYuki, ResultVideoApong,  VideoLiveForDesktop, TableResult, BettingComponent, ReportBetting },
  props: [
    "passChannel",
    "passCurrentBetting",
    "passChannelStatus",
    "passResult",
    "passGetStatus",
    "passGetStatusColor",
    "passGetResultStatus",
    "passUserInfo",
    "passWeight",
    'passIsBetting',
    'passBettingTypeMaster',
    'passGetButton',
    'passTodayReport',
    "passLoadingTodayReport",
    "passChannelList",
    "passSelectedChannel",
    "passNewChannelList",
    "passUserCurrency"
  ],
  data() {
    return {
      active_tab: 0,
      channelList: [],
      channelSelected: 1,
      tabs: null,
      bettingMoney: 0,
      loading: false,
      error: false,
      bettingObj: {
        number_id: null,
        amount: null
      },
      currencyType: "",
      betSelected: null,
      bettingStatus: "",
      isBetsuccess: true,
      isReport: true,
      numberRule: val => {
        if (val < 0) return 'Please enter a positive number'
        return true
      },
      items: [
        { title: '1' },
        { title: '2' },
        { title: '3' },
        { title: '4' },
      ],
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    ...mapGetters("$_dashboard", {
      channelObj: "getChannel",
      // Tiger Dragon
      dragonCard: "getDragonCard",
      tigerCard: "getTigerCard",
      //Baccarat
      playerCard1: "getPlayerCard1",
      playerCard2: "getPlayerCard2",
      playerCard3: "getPlayerCard3",
      bankerCard1: "getBankerCard1",
      bankerCard2: "getBankerCard2",
      bankerCard3: "getBankerCard3",
      bankerPoint: "getBankerPoint",
      playerPoint: "getPlayerPoint",
      getIsReport: "getIsReport",
      //Lotto
      listLotto: "getListLotto",
      // klaklouk
      listKlaklouk: "getListKlaklouk",
      // abong
      listAbong: "getListAbong",
      // yuki
      listYuki: "getListYuki",
       // taisiv
       listTaiSiv: "getListTaiSiv"
    }),
  },
  methods: {
    setIsReport(){
      this.isReport = !this.isReport
    },
    checkStatus() {
      if (this.passChannel.is_open == null) {
        return "text-warning";
      } else {
        if (this.passChannel.is_open) {
          return "text-success";
        }
        return "text-danger";
      }
    },
    showResultByColor(result) {
      if (result.results[0]) {
        if (result.results[0].name === "1" || result.results[0].name === "4") {
          return "red";
        } else {
          return "black";
        }
      } else {
        return "primary";
      }
    },
    getBettingStatus() {
      if (
        this.passChannel.is_open &&
        this.passUserInfo.enable &&
        this.passChannel.enable
      ) {
        return true;
      }
      return false;
    },
    checkSattus() {
      if (this.passChannel.is_open == null) {
        return "Not Open";
      } else {
        if (this.passChannel.is_open) {
          return "Open";
        }
        return "Close";
      }
    },
    resultStatue() {
      if (this.passChannel.is_open == null && this.passResult.results[0]) {
        return true;
      } else {
        return false;
      }
    },
    resultChannelColor(index) {
      // console.log(`channelStatus: ${JSON.stringify(this.channelStatus)}`)
      if (this.passChannelStatus) {
        for (var i = 0; i < this.passChannelStatus.length; i++) {
          if (this.passChannelStatus[i].channel_type == index) {
            if (this.passChannelStatus[i].status == null) {
              return "orange"
            } else {
              if (this.passChannelStatus[i].status) {
                return "green"
              }
              return "red"
            }
          }
        }
      }
    },
    payoutFormat(num) {
      return num
    },
    receiveSubmitBetting(recieveBettingData) {
      this.$emit("sentSubmitBetting", recieveBettingData);
    },
    _switchChannel(selectedChannel) {
      this.$emit("sentSetChannel", selectedChannel);
    }
  },

};
</script>
<style>
.video-section {
  position: relative;
  overflow: hidden;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 30px !important;
}
.img-channel-logo{
  width:35px;
  height:35px;
  margin-top:2px;
}
</style>