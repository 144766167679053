<template>
    <div>
        <v-app-bar app>
            <v-app-bar-nav-icon color="#ffffff"></v-app-bar-nav-icon>
            <Header :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeDrawer="handleDrawer"></Header>
        </v-app-bar>
        <v-navigation-drawer  v-model="drawer" fixed temporary>
            <SideBar :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeLogout="handleLogout"></SideBar>
        </v-navigation-drawer>
    </div>
</template>
<script>
import "@/assets/css/header.css";
import Header from './Header.vue';
import SideBar from './SideBar.vue';
export default {
    components: { Header, SideBar },
    props: ['passUserInfo'],
    data: () => ({
        drawer: false,
        websiteLogo:'//static3.goperya.com/img/logo-3.png?v=10.60'
    }),
    methods:{
        handleDrawer(getDrawer){
            this.drawer = getDrawer;
        },
        handleLogout(){
            this.$emit("invokeLogout");
        }
    }
}
</script>