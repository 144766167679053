const reportBetting = [
	{
		text: 'fight_sign',
		align: 'center',
		sortable: false,
		value: 'no'
	},
	{
		text: 'channel',
		align: 'center',
		sortable: false,
		value: 'channel_type'
	},
	// { text: 'group_no', value: 'group', sortable: false, align: 'center' },
	{ text: 'date_time', value: 'time', sortable: false, align: 'center' },
	{ text: 'amount', value: 'total_amount', sortable: false, align: 'center' },
	{ text: 'cast', value: 'cast', sortable: false, align: 'center' },
	{ text: 'amount_win', value: 'amount_win', sortable: false, align: 'center' },
	// { text: 'action', value: 'print', sortable: false, align: 'center' },
];

export default reportBetting;