<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary pt-2">
                <div class="sides-wrapper gp-panel-bg rounded"  :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                    <div class="d-flex sides">
                        <div v-if="meronButton" class="side-section meron-section pb-3 side-1"
                            :class="selectedBtnClassMeron ? 'on-active-btn' : ''"
                            @click="_allowBtnBet('selectedMeron', meronButton)">
                            <div class="side-title pt-2">
                                <div class="truncate">{{ meronButton.name }}</div>
                            </div>
                            <div class="odds  pt-5">{{ passWeight? passWeight.meron.payout: 'x.xx'}}</div>
                            <div class=" mb-0 "><span style="font-weight:600">{{
                                userInfor && userInfor.currency_type == 2 
                                    ? formatBtnCurrencyKh(passCurrentBetting.meron) 
                                    : formatBtnCurrency(passCurrentBetting.meron)
                            }}</span>
                            </div>
                            <!-- <div v-if="bettingMoney > 0 && selectedBtnClassMeron">
                                <img :src="_detectCurrentMoneyCoinMeron(bettingMoney)" class="betting-coin-meron"
                                    style="z-index:-1" />
                                <div class="money-in-coin-position-meron"
                                    :style="`color:${ _detectCurrentMoneyColorMeron(bettingMoney) } `">{{
                                    `${ `${ userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(bettingMoney) : formatBtnCurrency(bettingMoney) } ` }` }}</div>
                            </div> -->
                        </div>
                        <div class="side-section-tie tie-section mx-1 side-1"
                            :class="selectedBtnClassTie ? 'on-active-btn' : ''" @click="_allowBtnBet('selectedTie')"
                            v-if="meronButton">
                            <div class="side-title pt-2">
                                <div class="truncate">{{$t('title.draw')}}</div>
                            </div>

                            <div class="odds pt-5">{{ passWeight?`1:8`: 'x.xx' }}</div>
                            <div class="mb-0 "><span style="font-weight:600">{{
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.tie) : formatBtnCurrency(passCurrentBetting.tie) 
                            }}</span>
                            <!-- <div v-if="bettingMoney > 0 && selectedBtnClassTie" class="mb-5">
                                <img :src="_detectCurrentMoneyCoinMeron(bettingMoney)"
                                    style="z-index:-1; width:50px;height:50px" />
                                <div style="font-weight:bold; margin-top:-37px; color:black!important">{{
                                    `${ userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(bettingMoney) : formatBtnCurrency(bettingMoney) } ` }}</div>
                            </div> -->

                            </div>
                        </div>


                        <div class="side-section wala-section pb-3 side-1"
                            :class="selectedBtnClassWala ? 'on-active-btn' : ''" v-if="walaButton"
                            @click="_allowBtnBet('selectedWala')">
                            <div class="side-title pt-2">
                                <div class="truncate">{{ walaButton.name }}</div>
                            </div>
                            <div class="odds pt-5">{{ passWeight? passWeight.wala.payout: 'x.xx'}}</div>
                            <div class=" mb-0"><span style="font-weight:600">{{
                            `${ userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.wala) : formatBtnCurrency(passCurrentBetting.wala) } `
                            }}</span>
                            </div>
                            <!-- <div v-if="bettingMoney > 0 && selectedBtnClassWala">
                                <img :src="_detectCurrentMoneyCoinWala(bettingMoney)" class="betting-coin-wala "
                                    style="z-index:-1" />
                                <div class="money-in-coin-position-wala"
                                    :style="`color:${ _detectCurrentMoneyColorWala(bettingMoney) } `">
                                    {{ `${userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(bettingMoney) : formatBtnCurrency(bettingMoney)  } `
                                    }}</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                :passIsSelectedBettingButton="isSelectedBettingButton"
                v-on:handleInputMonay="_inputAmount"/>
                <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting />
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfoLive.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import LimitBetting from '../../../../components/LimitBettingLive.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo , SubmitBtn, LimitBetting},
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedCoin:false,
            isSelectedBettingButton : false
        }

    },
    computed: {

        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        _checkAllowSubmit() {
            if ( this.bettingMoney > 0 && 
                    ( this.selectedBtnClassMeron || this.selectedBtnClassTie || this.selectedBtnClassWala )) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard_live", {
            getIsReport: "getIsReport",
            dialogBetting: "getDialogBetting",
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        _setReport() {
            this.UPDATE_IS_REPORT(!this.getIsReport)
        },
        checkSattus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        checkStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _detectCurrentMoneyCoinMeron(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorMeron(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'white';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinWala(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorWala(money) {
            switch (true) {
                case money < 5:
                    return 'blue';
                case money < 10:
                    return '#F92A53';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'white';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinTie(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorTie(money) {
            switch (true) {
                case money < 5:
                    return 'blue';
                case money < 10:
                    return '#F92A53';
                case money < 20:
                    return 'green';
                case money < 50:
                    return 'red';
                case money < 100:
                    return '#f97432';
                case money < 99999999:
                    return 'white';
                default:
                    return ''
            }
        },
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'blue';
                case 1:
                    return '#F92A53';
                case 2:
                    return 'green';
                case 3:
                    return 'red';
                case 4:
                    return '#f97432';
                case 5:
                    return 'grey';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
                this.selectedCoin = true
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)
        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
            this.selectedCoin = false;
            this.isSelectedBettingButton = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.selectedButton = this.meronButton;
                    this.selectedBtnClassMeron = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassWala = false;
                    this.isSelectedBettingButton = true;
                    break;
                case 'selectedTie':
                    this.selectedButton = this.tieButton;
                    this.selectedBtnClassTie = true;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                    this.isSelectedBettingButton = true;
                    break;
                case 'selectedWala':
                    this.selectedButton = this.walaButton;
                    this.selectedBtnClassWala = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                    this.isSelectedBettingButton = true;
                    break;
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatCoinCurrency(amount){
            switch (amount) {
                case 5000:
                    return '5ព';
                case 10000:
                    return '1ម';
                case 20000:
                    return '2ម';
                case 50000:
                    return '5ម';
                case 100000:
                    return '10ម';
                case 200000:
                    return '20ម';
                default:
                    return amount;
            }
        },
        ...mapMutations("$_dashboard_live", [
            "BETTING_DIALOG",
            "UPDATE_IS_REPORT"
        ]),
    }
}
</script>
<style scoped>
@media(max-width:768px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 1;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .space-for-small-screen {
        text-align: center;
    }

    .betting-coin-meron {
        width: 55px;
        height: auto;
        margin: auto;
        bottom: 1px;
        left: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .betting-coin-wala {
        width: 55px;
        height: auto;
        margin: auto;
        bottom: 1px;
        right: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }


    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }



    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position-meron {
        margin-bottom: 15px;
    z-index: 100;
    font-weight: 900;
    z-index: 2000;
    font-size: 14px;
    width: 25px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    color: black!important;
    text-align: left;
    position: absolute;
    margin-top: -25px;
    left: 14px;
    }

    .money-in-coin-position-wala {
    margin-bottom: 15px;
    z-index: 100;
    font-weight: 900;
    z-index: 2000;
    font-size: 14px;
    width: 25px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    color: black!important;
    text-align: end;
    r: absolute;
    margin-top: -25px;
    /* margin-right: 6px; */
    margin-left: auto;
    z-index: 99999999;
    position: absolute;
    right: 17px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        width: 13.1vw;
        margin-top: -42px !important;
    }
}

@media(min-width:768px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .space-for-small-screen {
        text-align: center;
    }

    .betting-coin-meron {
        width: 55px;
        height: auto;
        margin: auto;
        bottom: 1px;
        left: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .betting-coin-wala {
        width: 55px;
        height: auto;
        margin: auto;
        bottom: 1px;
        right: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }



    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        border: 4px solid gold;
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position-meron {
        margin-bottom: 15px;
    z-index: 100;
    font-weight: 900;
    z-index: 2000;
    font-size: 14px;
    width: 25px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    color: black!important;
    text-align: left;
    position: absolute;
    margin-top: -25px;
    left: 14px;
    }

    .money-in-coin-position-wala {
    margin-bottom: 15px;
    z-index: 100;
    font-weight: 900;
    z-index: 2000;
    font-size: 14px;
    width: 25px;
    font-weight: 900;
    text-shadow: 1px 1px 1px black;
    color: black!important;
    text-align: end;
    r: absolute;
    margin-top: -25px;
    /* margin-right: 6px; */
    margin-left: auto;
    z-index: 99999999;
    position: absolute;
    right: 17px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        width: 14.1vw;
        margin-top: -49px !important;
    }
}
</style>