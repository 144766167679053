<template>
    <div class="trends limit-on-tab">
      <div>
        <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-meron">{{ resultObj.summary.one }}</span>
            <p class="mb-0 count-label">1</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-black">{{ resultObj.summary.two }}</span>
            <p class="mb-0 count-label">2</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-black">{{ resultObj.summary.three }}</span>
            <p class="mb-0 count-label">3</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-meron">{{ resultObj.summary.four }}</span>
            <p class="mb-0 count-label">4</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-black">{{ resultObj.summary.five }}</span>
            <p class="mb-0 count-label">5</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-black">{{ resultObj.summary.six }}</span>
            <p class="mb-0 count-label">6</p>
          </div>
          <!-- <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle bg-secondary">{{ resultObj.summary.CANCEL }}</span>
            <p class="mb-0 count-label">Cancelled</p>
          </div> -->
        </div>
        <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
          <div class="table-responsive pb-3 gp-scroll">
            <table class="trend-table">
              <tbody>
                <tr v-for="row in rows" :key="row">
                  <td v-for="col in cols" :key="col">
                    <div>
                      <div v-for="(result, i) in orderResult" :key="i">
                          <span v-if="col == result.col && row == result.row">
                              <v-avatar :class="result.color" size="25">
                                  <span class="white--text">
                                      {{result.name}}
                                  </span>
                              </v-avatar>
                          </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: ["rows", "cols"],
  
    computed: {
        ...mapGetters("$_dashboard", {
            resultObj: "getResult",
            channel: "getChannel",
        }),
        orderResult() {
            return this.getListResults();
        },
        number(bet) {
        return this.getNumber(bet);
        }
    },
    methods: {
        getNumber(bet){
        let number = [];
        number = bet.split(":");
        return number[1]
        },
      getListResults() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.resultObj.results !== null && this.resultObj.results.length > 0){
            let resultList = this.resultObj.results
            for (var index = resultList.length - 1; index >= 0; index--) {
                const item = resultList[index];
                if(index == resultList.length - 1){
                    newResult.push({
                        ...item,
                        row,
                        col,
                    });
                }else{
                    const pre = newResult[newResult.length - 1];
                    if(pre.name !== item.name){
                        newResult.push({ 
                        ...item,
                        row: 1,
                        col: pre.col + 1,
                        });
                    }else{
                        if (pre.row >= 6) {
                            newResult.push({
                                ...item,
                                row: 1,
                                col: pre.col + 1,
                            });
                        } else {
                            newResult.push({
                                ...item,
                                row: pre.row + 1,
                                col: pre.col,
                            });
                        }
                    }
                }
            }
        }
      // console.log(newResult)
      return newResult;
    },
      _resultColor(color) {
        switch (color) {
          case 'cir-blue':
            return 'trend-wala'
          case 'cir-red':
            return 'trend-meron'
          case 'cir-green':
            return 'trend-draw'
          case 'cir-cancel':
            return 'trend-cancel'
          default:
            return ''
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .history-result td {
    text-align: center;
    height: 45px;
    width: 45px;
    padding: 2px;
    background: #f2f6f9;
    border: 1px solid #96afde;
    vertical-align: middle;
    box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
    -moz-box-shadow: 0 0 2px 1px #fff;
    -webkit-box-shadow: 0 0 2px 1px #fff;
    -ms-box-shadow: 0 0 2px 1px #fff;
    -o-box-shadow: 0 0 2px 1px #fff;
  }
  
  .round-blue {
    background: url(../../../../assets/images/round-blue.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .round-red {
    background: url(../../../../assets/images/round-red.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-blue {
    background: url(../../../../assets/images/cycle-blue.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .cycle-red {
    background: url(../../../../assets/images/cycle-red.png) no-repeat center;
    background-size: 34px;
    display: block;
    height: 34px;
  }
  
  .center-padding-0 {
    text-align: center;
    padding: 0px !important;
  }
  
  .result-table {
    width: 100%;
    margin: 0;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
  
  .trend-table{
    border-spacing: 0px !important;
  }
  .trend-table td {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 10px;
    text-align: center;
    border: 1px solid #c5c5c5;
    padding: 0px;
  }
  
  td>div {
    width: 25px;
  }
  </style>