const UPDATE_DARK_MODE = (state, data) => {
    state.darkMode = data;
};
const UPDATE_BALANCE = (state, balance) => {
    state.userInfo.balance = balance;
};
const UPDATE_USER_INFO = (state, data) => {
    state.userInfo = data;
    state.userInfo.currencySymbol = data.currency_type === 1 ? "$" : data.currency_type === 2 ? "៛" : "฿"
};
const UPDATE_CHANNEL_ID = (state, data) => {
    // console.log(`this.channelSelected UPDATE_CHANNEL_ID ${data}`)
	state.channelId = data;
    // console.log(`this.channelSelected UPDATE_CHANNEL_ID ${state.channelId}`)
};
const UPDATE_CHANNEL_LIST = (state, data) => {
	state.channelList = data;
};
const UPDATE_SOUND= (state, data) => {
	state.isSoundOn = data
};
export default {
    UPDATE_DARK_MODE,
    UPDATE_BALANCE,
    UPDATE_USER_INFO,
	UPDATE_CHANNEL_ID,
    UPDATE_CHANNEL_LIST,
    UPDATE_SOUND
};
