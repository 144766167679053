var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pt-2 pb-3",staticStyle:{"margin-top":"5px !important"}},[_c('v-col',{staticStyle:{"padding":"0 10px 0 10px"},attrs:{"md":"12","lg":"12","sm":"12"}},[_c('div',{staticClass:"plus-middle-bottom-box row px-0 mx-auto justify-center"},_vm._l((_vm.getNumber),function(number,index){return _c('div',{key:index,staticClass:"lus-b-col pt-0 pb-1",staticStyle:{"padding":"0px 11px 5px 10px!important"}},[_c('v-btn',{class:{
            activex: _vm.getSelectedNumber[index],
            noClick: _vm.clickable,
          },staticStyle:{"font-size":"20px","font-weight":"bold","width":"45px !important","height":"45px !important"},attrs:{"id":_vm.classes,"fab":"","dark":"","small":"","color":_vm.selectedListBettingNumber.length > 0 &&
            _vm.selectedListBettingNumber.includes(number)
              ? 'red'
              : '#013062'},on:{"click":function($event){return _vm.selectNumber(index, number)},"mouseover":_vm.mouseOver}},[_vm._v(" "+_vm._s(number ? number : 0)+" ")])],1)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }