<template>
  <div>
    <div class="lotto-nav-head">
      <Tabs/>
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="plus-middle-top-box" style="overflow:hidden">
        <v-layout class=" d-sm-none" style="margin-top:-16px; margin-bottom:10px;">
          <lottery-info />
        </v-layout>
       <display-result :passRandomNumber="passRandomNumber"  :passClientRandom="passClientRandom"/>
       <lottery-post />
       <lottery-betting style="background-color:#003466" class="d-sm-none mt-2"/>
       <ninety-betting-button/>
      </v-col>
    </v-row>   
  </div>
</template>
<script>
import "@/assets/css/lottery.css";
// import {  mapGetters, mapMutations, mapActions  } from "vuex";
import LotteryInfo from "@/modules/MachineGame/Lottery/components/SubComponent/LotteryInfo";
import LotteryBetting from "@/modules/MachineGame/Lottery/components/SubComponent/LotteryBetting";
import Tabs from "../../../../components/machine/Tabs.vue";
import DisplayResult from "./SubComponent/DisplayResult";
import LotteryPost from "./SubComponent/LotteryPost";
import NinetyBettingButton from './SubComponent/NinetyBettingButton.vue';
export default {
  components: {
    Tabs,
    DisplayResult,
    LotteryPost,
    NinetyBettingButton,
    LotteryInfo,
    LotteryBetting
  },
  props: ["passRandomNumber","passClientRandom"
  ],
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>
