import Request from '@/services/axios'

let request = new Request;

const fetchReportBetting = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        //url: 'report/betting_all',
        url: 'report/statement',
        data
    })
    if (response.data.code) {
        commit('REPORT_BETTING', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

export default {
	fetchReportBetting
};