<template>
    <div>
        <div class="lotto-nav-head">
            <Tabs/>
        </div>
    <v-row class="hidden-sm-and-up" no-gutters>
    <v-img
        src="/images/bg.jpg"
        cover    
        style="background-repeat: no-repeat;
        "
      >
        <v-row no-gutters>
         <v-btn style="position: relative;top: 35px; width: 18px; height: 18px;" color="primary" fab x-small dark @click="mixedTheDeckMobile()">S</v-btn>
         <v-btn style="position: relative;top: 35px; width: 18px; height: 18px;" color="warning" fab x-small dark @click="resultTheDeckMobile()">DT</v-btn>
         <v-btn style="position: relative;top: 35px; width: 18px; height: 18px;" color="success" fab x-small dark @click="resultWinMobile()">W</v-btn>  
        </v-row>

        
        <div id="mainMobile"> 
          <div>
            <img src="" id="blockDMobile">
            <img src="" id="blockTMobile">
          </div>        
          <div id="computerHandMobile">
              <!-- the cards that not allow the user see what the computer have -->
              <div id="blockCardsMobile">
                  <img class="dragonCardMobile" src="" id="block01Mobile" @click="dragonOpenMobile()"/>
                  <img class="tigerCardMobile" src="" id="block02Mobile" @click="tigerOpenMobile()"/>
              </div>

              <div>
                <img  src="" id="blockWinMobile" @onclick="resultWin()"/>
              </div>
              <!-- the actual cards the computer have -->
              <div id="realCards">
                  <img class="dragonCardMobile" src="" id="computerCard01Mobile" />
                  <img class="tigerCardMobile" src="" id="computerCard02Mobile"/>
              </div>
          </div>        
    
          <!-- dealing cards in the start, just animation -->
          <div id="cardsDealingComputerMobile">
              <img style="z-index:99999 !important" class="dragonCardMobile" src="images/Mycards/b1fv.png" id="GFX01Mobile"/>            
          </div>
          <div id="cardsDealingUserMobile">
              <img class="tigerCardMobile" src="images/Mycards/b1fv.png" id="GFX03Mobile"/> 
          </div>
      </div>

  </v-img>
  </v-row>
    </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import $ from "jquery";
$(function(){
    $("#userAction01Mobile").hide(1);
    $("#userAction02Mobile").hide(1);
    $("#userAction03Mobile").hide(1);
    $("#userAction04Mobile").hide(1);

    $("#GFX01Mobile").hide(1);
    $("#GFX02Mobile").hide(1);
    $("#GFX03Mobile").hide(1);
    $("#GFX04Mobile").hide(1);

});
var cardsDeck = new Array();

cardsDeck[0] = new Image();
cardsDeck[0].src = "images/Mycards/2a.png";
cardsDeck[0].val = 2;
cardsDeck[0].myType = "a";

cardsDeck[1] = new Image();
cardsDeck[1].src = "images/Mycards/2b.png";
cardsDeck[1].val = 2;
cardsDeck[1].myType = "b";

cardsDeck[2] = new Image();
cardsDeck[2].src = "images/Mycards/2c.png";
cardsDeck[2].val = 2;
cardsDeck[2].myType = "c";

cardsDeck[3] = new Image();
cardsDeck[3].src = "images/Mycards/2d.png";
cardsDeck[3].val = 2;
cardsDeck[3].myType = "d";

cardsDeck[4] = new Image();
cardsDeck[4].src = "images/Mycards/3a.png";
cardsDeck[4].val = 3;
cardsDeck[4].myType = "a";

cardsDeck[5] = new Image();
cardsDeck[5].src = "images/Mycards/3b.png";
cardsDeck[5].val = 3;
cardsDeck[5].myType = "b";

cardsDeck[6] = new Image();
cardsDeck[6].src = "images/Mycards/3c.png";
cardsDeck[6].val = 3;
cardsDeck[6].myType = "c";

cardsDeck[7] = new Image();
cardsDeck[7].src = "images/Mycards/3d.png";
cardsDeck[7].val = 3;
cardsDeck[7].myType = "d";

cardsDeck[8] = new Image();
cardsDeck[8].src = "images/Mycards/4a.png";
cardsDeck[8].val = 4;
cardsDeck[8].myType = "a";

cardsDeck[9] = new Image();
cardsDeck[9].src = "images/Mycards/4b.png";
cardsDeck[9].val = 4;
cardsDeck[9].myType = "b";

cardsDeck[10] = new Image();
cardsDeck[10].src = "images/Mycards/4c.png";
cardsDeck[10].val = 4;
cardsDeck[10].myType = "c";

cardsDeck[11] = new Image();
cardsDeck[11].src = "images/Mycards/4d.png";
cardsDeck[11].val = 4;
cardsDeck[11].myType = "d";

cardsDeck[12] = new Image();
cardsDeck[12].src = "images/Mycards/5a.png";
cardsDeck[12].val = 5;
cardsDeck[12].myType = "a";

cardsDeck[13] = new Image();
cardsDeck[13].src = "images/Mycards/5b.png";
cardsDeck[13].val = 5;
cardsDeck[13].myType = "b";

cardsDeck[14] = new Image();
cardsDeck[14].src = "images/Mycards/5c.png";
cardsDeck[14].val = 5;
cardsDeck[14].myType = "c";

cardsDeck[15] = new Image();
cardsDeck[15].src = "images/Mycards/5d.png";
cardsDeck[15].val = 5;
cardsDeck[15].myType = "d";

cardsDeck[16] = new Image();
cardsDeck[16].src = "images/Mycards/6a.png";
cardsDeck[16].val = 6;
cardsDeck[16].myType = "a";

cardsDeck[17] = new Image();
cardsDeck[17].src = "images/Mycards/6b.png";
cardsDeck[17].val = 6;
cardsDeck[17].myType = "b";

cardsDeck[18] = new Image();
cardsDeck[18].src = "images/Mycards/6c.png";
cardsDeck[18].val = 6;
cardsDeck[18].myType = "c";

cardsDeck[19] = new Image();
cardsDeck[19].src = "images/Mycards/6d.png";
cardsDeck[19].val = 6;
cardsDeck[19].myType = "d";

cardsDeck[20] = new Image();
cardsDeck[20].src = "images/Mycards/7a.png";
cardsDeck[20].val = 7;
cardsDeck[20].myType = "a";

cardsDeck[21] = new Image();
cardsDeck[21].src = "images/Mycards/7b.png";
cardsDeck[21].val = 7;
cardsDeck[21].myType = "b";

cardsDeck[22] = new Image();
cardsDeck[22].src = "images/Mycards/7c.png";
cardsDeck[22].val = 7;
cardsDeck[22].myType = "c";

cardsDeck[23] = new Image();
cardsDeck[23].src = "images/Mycards/7d.png";
cardsDeck[23].val = 7;
cardsDeck[23].myType = "d";

cardsDeck[24] = new Image();
cardsDeck[24].src = "images/Mycards/8a.png";
cardsDeck[24].val = 8;
cardsDeck[24].myType = "a";

cardsDeck[25] = new Image();
cardsDeck[25].src = "images/Mycards/8b.png";
cardsDeck[25].val = 8;
cardsDeck[25].myType = "b";

cardsDeck[26] = new Image();
cardsDeck[26].src = "images/Mycards/8c.png";
cardsDeck[26].val = 8;
cardsDeck[26].myType = "c";

cardsDeck[27] = new Image();
cardsDeck[27].src = "images/Mycards/8d.png";
cardsDeck[27].val = 8;
cardsDeck[27].myType = "d";

cardsDeck[28] = new Image();
cardsDeck[28].src = "images/Mycards/9a.png";
cardsDeck[28].val = 9;
cardsDeck[28].myType = "a";

cardsDeck[29] = new Image();
cardsDeck[29].src = "images/Mycards/9b.png";
cardsDeck[29].val = 9;
cardsDeck[29].myType = "b";

cardsDeck[30] = new Image();
cardsDeck[30].src = "images/Mycards/9c.png";
cardsDeck[30].val = 9;
cardsDeck[30].myType = "c";

cardsDeck[31] = new Image();
cardsDeck[31].src = "images/Mycards/9d.png";
cardsDeck[31].val = 9;
cardsDeck[31].myType = "d";

cardsDeck[32] = new Image();
cardsDeck[32].src = "images/Mycards/10a.png";
cardsDeck[32].val = 10;
cardsDeck[32].myType = "a";

cardsDeck[33] = new Image();
cardsDeck[33].src = "images/Mycards/10b.png";
cardsDeck[33].val = 10;
cardsDeck[33].myType = "b";

cardsDeck[34] = new Image();
cardsDeck[34].src = "images/Mycards/10c.png";
cardsDeck[34].val = 10;
cardsDeck[34].myType = "c";

cardsDeck[35] = new Image();
cardsDeck[35].src = "images/Mycards/10d.png";
cardsDeck[35].val = 10;
cardsDeck[35].myType = "d";

cardsDeck[36] = new Image();
cardsDeck[36].src = "images/Mycards/J1.png";
cardsDeck[36].val = 11;
cardsDeck[36].myType = "a";

cardsDeck[37] = new Image();
cardsDeck[37].src = "images/Mycards/J2.png";
cardsDeck[37].val = 11;
cardsDeck[37].myType = "b";

cardsDeck[38] = new Image();
cardsDeck[38].src = "images/Mycards/J3.png";
cardsDeck[38].val = 11;
cardsDeck[38].myType = "c";

cardsDeck[39] = new Image();
cardsDeck[39].src = "images/Mycards/J4.png";
cardsDeck[39].val = 11;
cardsDeck[39].myType = "d";

cardsDeck[40] = new Image();
cardsDeck[40].src = "images/Mycards/Q1.png";
cardsDeck[40].val = 12;
cardsDeck[40].myType = "a";

cardsDeck[41] = new Image();
cardsDeck[41].src = "images/Mycards/Q2.png";
cardsDeck[41].val = 12;
cardsDeck[41].myType = "b";

cardsDeck[42] = new Image();
cardsDeck[42].src = "images/Mycards/Q3.png";
cardsDeck[42].val = 12;
cardsDeck[42].myType = "c";

cardsDeck[43] = new Image();
cardsDeck[43].src = "images/Mycards/Q4.png";
cardsDeck[43].val = 12;
cardsDeck[43].myType = "d";

cardsDeck[44] = new Image();
cardsDeck[44].src = "images/Mycards/K1.png";
cardsDeck[44].val = 13;
cardsDeck[44].myType = "a";

cardsDeck[45] = new Image();
cardsDeck[45].src = "images/Mycards/K2.png";
cardsDeck[45].val = 13;
cardsDeck[45].myType = "b";

cardsDeck[46] = new Image();
cardsDeck[46].src = "images/Mycards/K3.png";
cardsDeck[46].val = 13;
cardsDeck[46].myType = "c";

cardsDeck[47] = new Image();
cardsDeck[47].src = "images/Mycards/K4.png";
cardsDeck[47].val = 13;
cardsDeck[47].myType = "d";

cardsDeck[48] = new Image();
cardsDeck[48].src = "images/Mycards/A1.png";
cardsDeck[48].val = 14;
cardsDeck[48].myType = "a";

cardsDeck[49] = new Image();
cardsDeck[49].src = "images/Mycards/A2.png";
cardsDeck[49].val = 14;
cardsDeck[49].myType = "b";

cardsDeck[50] = new Image();
cardsDeck[50].src = "images/Mycards/A3.png";
cardsDeck[50].val = 14;
cardsDeck[50].myType = "c";

cardsDeck[51] = new Image();
cardsDeck[51].src = "images/Mycards/A4.png";
cardsDeck[51].val = 14;
cardsDeck[51].myType = "d";
import "../../../../assets/css/style01.css";

import Tabs from "../../../../components/machine/Tabs.vue";
export default {
  components: {
    Tabs,
  },
  props: [
    "passClientRandom","passLastTwentyResults"
  ],
  data() {
    return {
      message : "Hello world",
    }
  },
  watch: {
    passLastTwentyResults() {
    },
  },
   computed: {
    orderResult() {
      return this.getListResults();
    },
    orderResultUnderOver() {
      return this.getListResultsRange();
    },
  },
 
  methods: {
   getListResults() {
    let row = 1;
      let col = 1;
      let newResult = [];
      for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
        const item = this.passLastTwentyResults[index];
        if (index == this.passLastTwentyResults.length - 1) {
          newResult.push({
            ...item,
            row,
            col,
          });
        } else {
          const pre = newResult[newResult.length - 1];
          if (pre.small_large.name !== item.small_large.name) {
            newResult.push({
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          } else {
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      return newResult;
    },
 
mixedTheDeckMobile (){   
    cardsDeck.sort(function() { return 0.5 - Math.random() });

    //clean table from the 5 cards in case you start another game from the start
    $("#mainMobile images").attr("src", "");

    // Audio
    var dealing4cards = document.createElement('audio');
    dealing4cards.setAttribute('src', 'sound/dealing4cards.wav');
    dealing4cards.setAttribute('autoplay', 'autoplay');
    dealing4cards.load();
    

    //  document.getElementById("block01Mobile").src = null;
    //  document.getElementById("block02Mobile").src = null;
     
    //  document.getElementById("computerCard01Mobile").src = null;
    //  document.getElementById("computerCard02Mobile").src = null;
     document.getElementById("blockDMobile").src = '';
     document.getElementById("blockTMobile").src = '';
     document.getElementById("blockWinMobile").src = ''; 
    

    // animation of cards dealing, just an animation

    $("#GFX01Mobile").show(1);
    $("#GFX01Mobile").addClass("effect04");
    $("#GFX01Mobile").hide(500);

    setTimeout(now02,250);
    function now02(){
    $("#GFX02Mobile").show(1);
    $("#GFX02Mobile").addClass("effect05");
    $("#GFX02Mobile").hide(700);
    }

    setTimeout(now03,750);
    function now03(){
        $("#GFX03Mobile").show(1);
        $("#GFX03Mobile").addClass("effect05");
        $("#GFX03Mobile").hide(500);
    }

    setTimeout(now04,1000);
    function now04(){
        $("#GFX04Mobile").show(1);
        $("#GFX04Mobile").addClass("effect05");
        $("#GFX04Mobile").hide(700);
    }  

    // User Hand
    setTimeout(startItUser, 1700);
    function startItUser(){
        // Clearing the Animation
        $("#GFX01Mobile").removeClass("effect04");       
        $("#GFX03Mobile").removeClass("effect05");  
    }

    // Computer Hand
    setTimeout(startItComputer, 850)
    function startItComputer(){
    // computer block cards (so the user can't see)
    $("#blockCardsMobile").show(0);
    document.getElementById("block01Mobile").src = "images/Mycards/b1fv.png";
    document.getElementById("block02Mobile").src = "images/Mycards/b1fv.png";
    // computer hand
    document.getElementById("computerCard01Mobile").src = cardsDeck[7].src;
    document.getElementById("computerCard02Mobile").src = cardsDeck[8].src;
    // Titles
   
    }
  },
    
    resultTheDeckMobile: function(){
      document.getElementById("blockDMobile").src = "images/Dragon/Q.png";
      document.getElementById("blockTMobile").src = "images/Tiger/J.png";
    },
    resultWinMobile: function(){
      document.getElementById("blockWinMobile").src = "images/TigerWin.png";   
    },
    dragonOpenMobile: function(){
      $("#block01Mobile").hide(1);
      $("#block02Mobile").hide(1);
      $("#computerCard02Mobile").show(1);
      $("#computerCard01Mobile").show(1);
    },
    
    tigerOpenMobile: function(){
      $("#block01Mobile").hide(1);
      $("#block02Mobile").hide(1);
      $("#computerCard02Mobile").show(1);
      $("#computerCard01Mobile").show(1);
    },

  }
};
</script>
