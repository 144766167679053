<template>
    <div >
      <v-col cols="12" class="plus-content-right-bet">
        <v-btn
          class="mb-0 w-100"
          @click="_goBetting"
          :disabled="_calAllowBetting()"
          :class="_calAllowBetting() ? 'plus-bet-btn-close' : 'plus-bet-btn'"
        >
          <span>{{ $t("title.submit_betting") }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" class="plus-content-right-textbox">
        <ul>
          <li class="text-bg pt-3 pb-2 row mx-0 pl-2">
            <v-spacer></v-spacer>
            <span class="item-right">
              <span>{{ $t("title.min") }}: </span>
              <span class="text-yellow text-xs">{{
                amount_min
                  ? amount_min.toLocaleString("en") +
                    _displayCurrencySymbol(userInfo.account_currency)
                  : "Loading.."
              }}</span>
              <span> | </span> <span>{{ $t("title.max") }}: </span>
              <span class="text-yellow text-sm">{{
                amount_max
                  ? amount_max.toLocaleString("en") +
                    _displayCurrencySymbol(userInfo.account_currency)
                  : "Loading.."
              }}</span>
            </span>
          </li>
          <li class="input-bet mt-3">
            <!-- @input="inputAmount" -->
            <input
              placeholder="ENTER BET AMOUNT"
              :value="getBettingAmount"
              type="number"
              pattern="[0-9]*"
              min="1"
              onlynumber="true"
              style="border-radius: 5px"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                 @input="(event) => inputAmount(event.target.value)"
            />
            <span><v-icon @click="handleClear">mdi-history</v-icon></span>
          </li>
        </ul>
      </v-col>
      <v-col cols="12" class="plus-content-right-buttom mb-0">
        <v-btn
          class="lotto-button-item py-2 mb-2"
          style="text-align: center"
          v-for="(item, i) in buttonPlayGame"
          :key="i"
          @click="getAmount(item.amount)"
        >
          <span style="font-family: 'khmer mef1'; font-size: 1.15rem; color:white!important">{{
            item.label
          }}</span>
        </v-btn>
      </v-col>
      </div>
</template>

<script>
import moment from "moment";
import Mixin from "../../../../../mixin/index";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex"
export default {
  data() {
    return {
      amount_min: null,
      amount_max: null,
      mixins: [Mixin],
    };
  },
  created() {
    this.getLimit();
  },
  computed: {
    ...mapState("$_machine_lottery", {
      getBettingAmount: "getBettingAmount",
    }),
    ...mapGetters("$_modules", {
      userInfo: "getUserInfo",
    }),
    ...mapGetters("$_machine_lottery", {
      getIsBetting: "getIsBetting",
      getLastOneResult: "getLastOneResult",
      currentGame: "getCurrentGame",
      buttonPlayGame: "getButtonMoney",
      amount: "getBettingAmount",
      getBettingAmount: "getBettingAmount",
      getBettingData: "getBettingData",
      getBettingType: "getSelectedBettingType",
      getSelectedChannelPost: "getSelectedChannelPost",
      selectedListBettingNumber: "getSelectedListBettingNumber",
      getClientTime: "getClientTime",
    }),
  },
  methods: {
    moment,
    _calAllowBetting() {
      if (
        this.getSelectedChannelPost.length == 0 ||
        this.getIsBetting ||
        !this.getClientTime.is_open_betting ||
        this.selectedListBettingNumber.length == 0 ||
        this.amount <= 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    async _goBetting() {
      var newBettingArrayNumber = [];
      for (
        let index = 0;
        index < this.selectedListBettingNumber.length;
        index++
      ) {
        const element = this.selectedListBettingNumber[index];
        newBettingArrayNumber.push(element);
      }
      const { getIsBetting, userInfo } = this;
      let amount;
      if (this.amount == null) {
        this.$toastr.e(`input amount`);
      }
      amount = parseFloat(this.amount);
      if (getIsBetting) return;
      if (!amount || amount < 0 || parseFloat(userInfo.balance) <= amount) {
        this.errorMessage = "title.not_enought_balance";
        this.$toastr.e(this.$t(`title.not_enought_balance`));
        return;
      }
      if (amount < this.amount_min || amount > this.amount_max) {
        this.errorMessage = "title.bet_over_limit";
        this.$toastr.e(this.$t(`title.bet_over_limit`));
        return;
      }

      try {
        let data = {
          bet_a: null,
          bet_b: null,
          bet_c: null,
          bet_d: null,
          bet_e: null,
        };
        let tem = {
          numbers: newBettingArrayNumber,
          type: 1,
          sub_amount: amount,
        };
        for (
          let index = 0;
          index < this.getSelectedChannelPost.length;
          index++
        ) {
          const element = this.getSelectedChannelPost[index];
          if (element == "A") {
            data.bet_a = tem;
          } else if (element == "B") {
            data.bet_b = tem;
          } else if (element == "C") {
            data.bet_c = tem;
          } else if (element == "D") {
            data.bet_d = tem;
          } else if (element == "E") {
            data.bet_e = tem;
          }
        }
        this.UPDATE_DATA_BETTING(data);

        if (this.selectedListBettingNumber.length > 0) {
          let res = await this.createBet({ data: data });
          if (res.data.code) {
            this.handleClear();
            this.fetchUserInfo();
            this.$toastr.s(this.$t(`title.${res.data.message.descriptions}`));
            // this.playSoundBetSuccess()
          } else {
            this.$toastr.e(this.$t(`title.${res.data.message.descriptions}`));
          }
        } else {
          this.$toastr.e(this.$t(`title.please_bet`));
        }
      } catch (error) {
        // console.log('ERROR:',error);
        return error;
      }
    },
    getAmount(money) {
      this.UPDATE_BETTING_AMOUNT(money);
      this.errorMessage = null;
    },

    handleClear() {
      this.CLEAR_TO_DEFAULT();
      this.errorMessage = null;
    },
    inputAmount(money) {
      this.INPUT_BETTING_AMOUNT(money);
      this.errorMessage = null;
    },
    async getLimit() {
      await this.$request
        .getSlot({
          url: "m_lottery/limit",
        })
        .then((res) => {
          if (res.data.code) {
            this.amount_min = res.data.data.amount_min;
            this.amount_max = res.data.data.amount_max;
          }
        });
    },
    ...mapMutations("$_machine_lottery", [
      "UPDATE_BETTING_AMOUNT",
      "UPDATE_DATA_BETTING",
      "CLEAR_TO_DEFAULT",
      "IS_BETTING",
      "INPUT_BETTING_AMOUNT",
    ]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
    ...mapActions("$_machine_lottery", ["createBet"]),
    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
};
</script>

<style>
.plus-content-right-bet {
  padding: 16px 3px 0px 3px !important;
}
.plus-bet-btn {
  padding: 12px 12px 12px 12px;
  background: linear-gradient(180deg, goldenrod 0, white 51%, #7bb3ff);
  text-align: center;
  border-radius: 6px;
  border: 1px solid white;
}
.plus-bet-btn span {
  font-size: 1.8rem;
  color: rgb(51, 72, 187);
  font-weight: 900;
  text-shadow: 0px 0.5px black;
  margin-top: -1px;
}
.plus-bet-btn-close {
  padding: 12px 12px 12px 12px;
  background: rgb(184, 182, 182, 0.5);
  text-align: center;
  border-radius: 6px;
}
.plus-bet-btn-close span {
  font-size: 1.2rem;
  color: rgb(179, 179, 179);
  font-weight: 900;
}
.plus-content-right-result {
  padding: 16px 3px 0px 3px !important;
}
.plus-content-box-title {
  padding: 6px 6px 6px 6px;
  background: linear-gradient(180deg, #00baf9 0, #009df5 51%, #006eed);
}
.plus-content-box-title span {
  color: white;
  font-size: 1rem;
  font-weight: 400;
}

.plus-content-item:nth-child(odd) {
  background-color: #eadcdc;
}
.plus-content-item:nth-child(even) {
  background-color: #d3e4f6;
}
.plus-content-item-head {
  padding-left: 6px;
}
.plus-content-item-head span {
  font-size: 0.78rem;
  color: #003366;
}
.plus-content-row-col {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row: minmax(50px, auto);
  padding-top: 0px !important;
  padding: 10px 0px 10px 10px;
}
.plus-content-col {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
}
.plus-content-col span {
  color: white;
  line-height: 35px;
  font-size: 1.3rem;
  font-weight: 800;
}
.plus-color-yellow {
  background-color: #cfb006;
}
.plus-color-blue {
  background-color: #0c468a;
}
.plus-color-green {
  background-color: #05a723;
}
.plus-color-maroon {
  background-color: #6c0606;
}
.plus-color-red {
  background-color: #ac0707;
}

.plus-content-right-textbox {
  padding-top: 16px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.plus-content-right-textbox ul {
  list-style: none;
  padding-left: 0 !important;
}
.plus-content-right-textbox ul li:first-child {
  color: white;
}
.plus-content-right-textbox ul li .item-right {
  float: right;
}
.plus-content-right-textbox .text-yellow {
  color: yellow;
}
.plus-content-right-textbox .text-bg {
  font-size: 0.85rem;
}
.plus-content-right-textbox .text-sm {
  font-size: 0.85rem;
}
.plus-content-right-textbox ul .input-bet {
  padding-top: 6px;
  position: relative;
}
.plus-content-right-textbox ul .input-bet input {
  border: 1px solid white;
  padding: 8px 62px 8px 8px;
  width: 100%;
  color: white !important;
  border-radius: 5px;
}

.plus-content-right-textbox ul .input-bet span {
  border: 1px solid white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 62px;
  height: 42px;
  position: absolute;
  right: 0;
  text-align: center;
  line-height: 30px;
}
.plus-content-right-textbox ul .input-bet span button {
  color: yellow;
  font-size: 2.5rem;
}
.plus-content-right-textbox ul .input-bet span:hover {
  cursor: pointer;
}

.plus-content-right-buttom {
  padding-top: 16px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 0px;
}
.plus-content-right-buttom > .lotto-button-item {
  border: 1px solid white;
  float: left;
  margin-right: 11px;
  margin-bottom: 12px;
  border-radius: 18px 18px 18px 18px;
  background: linear-gradient(180deg, #0146fb, #46aeff 0%, #0146fb, #0146fb);
}
.plus-content-right-buttom > .lotto-button-item:nth-child(4) {
  margin-right: 0px;
}
.plus-content-right-buttom > .lotto-button-item:nth-child(8) {
  margin-right: 0px;
}
.lotto-button-item div {
  width: 90%;
  height: 98%;
  border: 1px solid rgb(54, 44, 44);
  border-radius: 18px 18px 18px 18px;
  margin: auto;
  padding: auto;
  text-align: center;
  font-size: 1.2rem;
  color: white;
  line-height: 36px;
  font-family: nsk-bold !important;
}
.lotto-button-item:hover {
  cursor: pointer;
}

@media screen and (min-width: 1904px) {
  .plus-content-right-textbox ul .input-bet span button {
    color: yellow;
    font-size: 2.5rem;
  }

  .plus-content-col {
    width: 45px;
    height: 45px;
  }
  .plus-content-col span {
    line-height: 45px;
    font-size: 1.8rem;
    font-weight: 950;
  }
  .plus-content-row-col {
    padding: 10px 10px 10px 13px;
  }
}

@media screen and (max-width: 1263px) {
  .plus-content-col {
    width: 30px;
    height: 30px;
  }
  .plus-content-col span {
    line-height: 27px;
    font-size: 1.2rem;
    font-weight: 800;
  }

  .plus-content-right-buttom > .lotto-button-item {
    margin-right: 8px;
    margin-bottom: 12px;
    border-radius: 12px 12px 12px 12px;
  }

  .plus-content-right-textbox ul li .item-right {
    font-size: 13px;
  }
  .plus-content-right-textbox ul .input-bet span button {
    font-size: 2.1rem;
    padding-top: 2.8px;
  }

  .plus-content-right-buttom {
    padding-top: 1px;
  }
}

@media screen and (max-width: 959px) {
  .plus-content-col {
    width: 50px;
    height: 50px;
  }
  .plus-content-col span {
    line-height: 44px;
    font-size: 2rem;
    font-weight: 800;
  }
  .plus-content-row-col {
    padding: 10px 0px 8px 90px;
  }
}

@media screen and (max-width: 764px) {
  .plus-content-col {
    width: 50px;
    height: 50px;
  }
  .plus-content-col span {
    line-height: 44px;
    font-size: 2rem;
    font-weight: 800;
  }
  .plus-content-row-col {
    padding: 10px 2px 8px 45px;
  }
}
.avatar-img {
  height: 52px;
  min-width: 52px;
  width: 52px;
  padding-top: 5px;
}

.format-currency {
  font-size: 1rem;
  margin-top: -1px;
  font-weight: 400;
  color: gold;
  margin-left: 1px;
}
</style>
