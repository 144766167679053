<template>
  <div>
      <CockFightTableResultComponent
          v-if="passSelectedChannel == 18 || passSelectedChannel == 19 || passSelectedChannel == 15 || passSelectedChannel == 16 || passSelectedChannel == 1 || passSelectedChannel == 2 || passSelectedChannel == 3 || passSelectedChannel == 4"
          :rows="6" :cols="35" />

      <KlaklokTableResultComponent v-if="passSelectedChannel == 5" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />

      <BaccaratTableResultComponent v-if="passSelectedChannel == 6 || passSelectedChannel == 7"
      :rows="6" :cols="35" />

      <TigerDragonTableResultComponent v-if="passSelectedChannel == 8 || passSelectedChannel == 9"
      :rows="6" :cols="35" />

      <LottoTableResultComponent v-if="passSelectedChannel == 10" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35"/>

      <YukiTableResultComponent v-if="passSelectedChannel == 11" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />

      <AbongTableResultComponent v-if="passSelectedChannel == 12 || passSelectedChannel == 17 " :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />

      <FanTanResultComponent v-if="passSelectedChannel == 13" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />

      <TaiSievTableResultComponent v-if="passSelectedChannel == 14" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="35" />
      <Han2GameTableResultComponent  class="pt-4" v-if="passSelectedChannel == 20" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="20" />

      <Han3GameTableResultComponent class="pt-4" v-if="passSelectedChannel == 21" :passCurrentTableResult="passCurrentTableResult"
      :rows="6" :cols="20" />
  </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CockFightTableResultComponent from "./_TableResultComponent/CockFightTableResultComponent.vue";
import BaccaratTableResultComponent from "./_TableResultComponent/BaccaratTableResultComponent.vue";
import TigerDragonTableResultComponent from "./_TableResultComponent/TigerDragonTableResultComponent.vue";
import KlaklokTableResultComponent from "./_TableResultComponent/KlaklokTableResultComponent.vue";
import LottoTableResultComponent from "./_TableResultComponent/LottoTableResultComponent.vue";
import YukiTableResultComponent from "./_TableResultComponent/YukiTableResultComponent.vue";
import AbongTableResultComponent from "./_TableResultComponent/AbongTableResultComponent.vue";
import FanTanResultComponent from "./_TableResultComponent/FanTanResultComponent.vue";
import TaiSievTableResultComponent from "./_TableResultComponent/TaiSievTableResultComponent.vue";

import Han2GameTableResultComponent from "./_TableResultComponent/Han2GameTableResultComponent.vue";
import Han3GameTableResultComponent from "./_TableResultComponent/Han3GameTableResultComponent.vue";

export default {
  components: {Han2GameTableResultComponent,Han3GameTableResultComponent, AbongTableResultComponent, YukiTableResultComponent, LottoTableResultComponent, KlaklokTableResultComponent, CockFightTableResultComponent, BaccaratTableResultComponent, TigerDragonTableResultComponent, FanTanResultComponent, TaiSievTableResultComponent },
  props: ["passCurrentTableResult", "passTodayTableResult", 'passLoadingTodayTableResult', 'passSelectedChannel'],
  methods: {
      _sentRefreshTodayTableResult() {
          this.$emit("sentRefreshTodayTableResult");
      },
      _sentRefreshCurrentTableResult() {
          this.$emit("sentRefreshCurrentTableResult");
      }
  },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(9, 17, 40, .7)
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(40, 53, 89, .8);
  border-radius: 10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(78, 106, 181, .2)
}

@keyframes rotate {

  from {
      transform: rotate(0deg)
  }

  to {
      transform: rotate(360deg)
  }

}


.refresh-start {

  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;

}

.text-meron {
  color: #f93c3c !important
}

.txt-white {
  color: rgb(255, 255, 255, 0.5)
}

.txt-gold {
  color: #FFC008
}

.txt-grey {
  color: #6C757D
}
</style>