<template>
  <v-col cols="12" class="plus-content-right-result">
    <div class="plus-content-box" >
        <div class="plus-content-box-title" >
          <span>{{ $t('title.lottery_result') }}</span>
        </div>
        <div style="height:332px; overflow-y:scroll" >
          <div class="plus-content-item " v-for="data in lastTwentyResults" :key="data._id">
            <div class="plus-content-item-head">
              <span class="list-value">{{formatDateId(data)}}</span>
            </div>
            <div class="plus-content-row-col">
                <div class="plus-content-col plus-color-yellow"><span>{{data.result_a.name}}</span></div>
                <div class="plus-content-col plus-color-blue"><span>{{data.result_b.name}}</span></div>
                <div class="plus-content-col plus-color-green"><span>{{data.result_c.name}}</span></div>
                <div class="plus-content-col plus-color-maroon"><span>{{data.result_d.name}}</span></div>
                <div class="plus-content-col plus-color-red"><span>{{data.result_e.name}}</span></div>
            </div>
          </div>
        </div>
       
    </div>
</v-col>
</template>
<script>
import { mapGetters} from "vuex";
import moment from "moment";
import "@/assets/css/lottery.css";
export default({
  props: ["passRandomNumber","passClientRandom"],
  computed: {
  ...mapGetters("$_machine_lottery", {
    lastTwentyResults: "getLastTwentyResult",
  }),
},
methods:{
  formatDateId(data){    
    return moment(data.date).format("YYYYMMDD")+"#"+data.no
  }
}

})
</script>
