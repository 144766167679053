export default {
	ticket_id : "សំបុត្រលេខ",
	game_no: "ហ្គេមលេខ",
	game_id: "ហ្គេមលេខ",
	date : "កាលបរិច្ឆេទ",
	type_betting : "ប្រភេទចាក់",
	bet_point : "ផលសង",
	win_loss: "ឈ្នះ/ចាញ់",
	cancel :"បោះបង់",
	print:"ព្រីន",
	account:"ឈ្មោះ",
	channel: 'បុស្តិ័',
	invoice_no:"លេខសន្លឹក",
	time:"កាលបរិច្ឆេទ",
	bet_point2:"ផលសង",
	number:"លេខចាក់"
};
