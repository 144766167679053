const UPDATE_LOADING = (state, data) => {
    state.loading = data;
};
const REPORT_DEPOSIT = (state, data) => {
    state.reportDeposit = data;
};

export default {
    UPDATE_LOADING,
    REPORT_DEPOSIT
};
