export default {
	receipt: 'វិក័យប័ត្រ',
	invoice_no: 'លេខវិក័យប័ត្រ',
	doctor: 'ទន្តពេទ្យ',
	date: 'ថ្ងៃខែឆ្នាំ',
	gender: 'ភេទ',
	patient: 'អ្នកជំងឺ',
	age: 'អាយុ',
	phone_number: 'លេខទូរស័ព្ទ',
	description: 'សេវា',
	qty: 'បរិមាណ',
	unit_price: 'តម្លៃ',
	subtotal: 'តម្លៃ​',
	discount: 'បញ្ចុះតម្លៃ',
	total: 'សរុប',
	total_price: 'តម្លៃសរុប',
	notes: 'កំណត់ចំណាំ',
	deposit: 'ការដាក់ប្រាក់'
};
