<template>

    <v-flex class="login-logo-section p-5">
        <div class="m-auto text-center">
            <img class="hero-img img-fluid mb-5" src="/images/logo.png" style="border-radius:100%;">
            <!-- <div class="font-weight-bold header-text text-left px-2" style="margin-top:-100px">
                <img class="img-fluid login-logo-footer" src="//static3.goperya.com/landing/img/slogan-lg.png?v=10.60">
            </div> -->
        </div>
    </v-flex>
</template> 
<script>
import "@/assets/css/login.css";
export default {

}
</script>