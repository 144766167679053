export default {
	update: 'cập nhật',
	delete: 'xóa bỏ',
	delete_all: 'xóa hết',
	view: 'quan điểm',
	cancel: 'sự hủy bỏ',
	yes: 'đúng',
	save: 'cứu',
	detail: 'chi tiết',
	create: 'tạo ra',
	upload_file: 'cập nhật dử liệu',
	clear: 'sạch',
	filter: 'lọc',
	local_leng: 'ខ្មែរ',
	create_invoice: 'tạo hóa đơn',
	print: 'in',	
	change_password: 'đổi mật khẩu',
	logout: 'đăng xuất',
	meron:'màu đỏ MERON',
	tie:'BET TIE',
	wala:'màu xanh dương WALA',
	search:'Tìm kiếm',
	today:'hôm nay',
	last_day:'ngày cuối',
	this_week:'tuần này',
	last_week:'tuần trước',
	this_month:'tháng này',
	last_month:'tháng trước',
	login:'đăng nhập',
	home:'Trang Chủ',
	all:'tất cả các',
	small : "U",
	large : 'O',
	odd : "O",
	even : 'E',
};
