export default {
    permission: 'Permission',
    role: 'Role',
    description: 'Description',
    menu_name: 'Menu Name',
    icon: 'Icon',
    order: 'Order',
    active: 'Active',
    actions: 'Actions',
    balance: 'Balance',
    created_at: 'Created At',
    bank_account: 'Bank Account',
    user_name: 'Username',
    deposit_withdraw: 'Deposit/Withdraw',
    no :"No",
    game:"Game",
    date:"Date",
    result:"Result",
    detail:"Detail",
    headtail:"Over / Under",
    range:"Range",
    id:"Id",
    account:"Account",
    bet:"Bet Amount",
    type:"Bet Type",
    payout:"payout",
    winLose:"Win / Lose",
    oldBalance:"Old Balance",
    newBalance:"New Balance",
    amountw: "Amount",
    small_large: "Small / Large",
    invoice_no: "Invoice No",
    turn_over: "Turn Over",
    see_detail:"Detail",
    total_cast:"Total Cast",
    bettingType:'Betting Type',
    post:'Channel',
    game_no:'Game No',
    group:'Group',
    action:'Action',
    old_balance:'Old Balance',
    new_balance:'New Balance',
    dateTime:'DateTime',
    Amount:'Amount',
    Balance:'Balance',
    Old_Balance:"Old Balance",
    New_Balance:'New Balance',
    
    all_statement: 'All Statement',
    total_win_lose: 'Total Win/Lose',
    start_date: 'Start Date',
    end_date: 'End Date',
    fight_no: 'Fight No',
    channel: 'Channel',
    group_no: 'Group No',
    date_time: 'Date1Time',
    cast: 'Cast',
    amount_win: 'Amount Win',

    invoice_id:"Invoice No",
    amount_betting:"Amount Betting"
};
