
const getLastResults = (state) => state.lastResults;
const getCurrentBetting = (state) => state.currentBetting;
const getPreviousBetting = (state) => state.previousBetting;
const getCurrentGame = (state) => state.currentGame;
const getHeadTailType = (state) => state.headTailType;
const getRangeType = (state) => state.rangeType;
const getLastOneResult = (state) => state.lastOneResult;
const getLastTwentyResult = (state) => state.lastTwentyResult;
const getIsBetting = (state) => state.is_Betting;
const getButtonMoney = (state) => state.buttonMoney;
const getLastFiveResults = (state) => state.lastFiveResults;


export default {
    getLastResults,
    getCurrentBetting,
    getPreviousBetting,
    getCurrentGame,
    getHeadTailType,
    getRangeType,
    getLastOneResult,
    getLastTwentyResult,
    getIsBetting,
    getButtonMoney,
    getLastFiveResults
};
