export default {
    permission: 'សិទ្ធអ្នកប្រើប្រាស់',
    role: 'ប្រភេទអ្នកប្រើប្រាស់',
    description: 'បរិយាយ',
    menu_name: 'ឈ្មោះ Menu',
    icon: 'រូបតំណាង',
    order: 'លេខលំដាប់',
    active: 'សកម្ម',
    actions: 'សកម្មភាព',
    balance: 'ទឹកប្រាក់',
    created_at: 'កាលបរិច្ឆេទ',
    bank_account: 'ព័ត៌មានគណនីធនាគារ',
    user_name: 'ឈ្មោះអ្នកប្រើប្រាស់',
    deposit_withdraw: 'ដក/ដាក់ប្រាក់',
    no :"ល.រ",
    game:"ហ្គេម",
    date:"កាលបរិច្ឆេទ",
    result:"លទ្ធផល",
    detail:"លំអិត",
    headtail:"ធំ / តូច",
    range:"ចន្លោះ",
    id:"លេខសំគាល់",
    account:"គណនី",
    bet:"ចាក់ចំនួន",
    type:"ចាក់ប្រភេទ",
    payout:"ផលសង",
    winLose:"ឈ្នះ / ចាញ់",
    newBalance:"លុយថ្មី",
    see_detail:"មើលលម្អិត",
    amount_betting: "ទឹកប្រាក់ចាក់",
    amount: "បានដាក់",
    amountw: "បានដក",
    small_large: "តូច / ធំ",
    invoice_no: "លេខវិកាយប័ត្រ",
    turn_over: "ប្រាក់សងត្រលប់",
    total_cast:"ប្រាក់សងត្រលប់",
    bettingType:'ចាក់ប្រភេទ',
    post:'ប៉ុស្តិ៍',
    game_no:'លេខហ្គេម',
    group: 'ក្រុម',
    action:'សកម្មភាព',
    old_balance:'ទឹកប្រាក់ចាស់',
    new_balance:'ទឹកប្រាក់ថ្មី',
    all_statement: 'របាយការណ៌សរុប',
    total_win_lose: 'សរុប ចាញ់ឈ្នះ',
    start_date: 'ចាប់ផ្តើម',
    end_date: 'បញ្ចប់',
    fight_no: 'ការប្រកួតលេខ',
    channel: 'បុស្តិ',
    group_no: 'ក្រុម',
    //date: 'កាលបរិច្ឆេទ',
    cast: 'លុយ',
    amount_win: 'ចំនួនឈ្នះ',
    oldBalance:"តុល្យភាពចាស់",
    invoice_id:"លេខវិក័យប័ត្រ",
};
