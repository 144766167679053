<template>
  <div class="trends limit-on-tab">
    <div>
      <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-wala">{{ resultObj.summary.EVEN}}</span>
          <p class="mb-0 count-label">{{ $t("menu.tsEven").toUpperCase() }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-meron">{{ resultObj.summary.ONE }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts1") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-black">{{ resultObj.summary.TWO }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts2") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle trend-black">{{ resultObj.summary.THREE }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts3") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle trend-thin">{{ resultObj.summary.SMALL }}</span>
          <p class="mb-0 count-label">{{ $t("menu.tsSmall") }}</p>
        </div>
      </div>
      <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-meron">{{ resultObj.summary.ODD}}</span>
          <p class="mb-0 count-label">{{ $t("menu.tsOdd") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-meron">{{ resultObj.summary.FOUR }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts4") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center mr-1"><span
            class="count rounded-circle trend-black">{{ resultObj.summary.FIVE }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts5") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle trend-black">{{ resultObj.summary.SIX }}</span>
          <p class="mb-0 count-label">{{ $t("menu.ts6") }}</p>
        </div>
        <div class="bad-cont d-inline-flex align-items-center "><span
            class="count rounded-circle trend-thick">{{ resultObj.summary.BIG }}</span>
          <p class="mb-0 count-label">{{ $t("menu.tsBig") }}</p>
        </div>
      </div>
      <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
        <div class="table-responsive pb-3 gp-scroll">
          <table class="kk-table">
            <tbody>
              <tr>
                <td v-for="col in cols" :key="col">
                  <div>
                    <div v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)" :key="i" class="text-center" style="width:30px;max-width:30px;margin-left: auto; margin-right: auto; text-align: center;">
                      <div v-if="(col-1) == i">
                          <v-img v-if="result.result1_name" :src="findImageOfEachAnimal(result.result1_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 30px;"></v-img>
                          <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result2_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 30px;"></v-img>
                          <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result3_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 30px;"></v-img>
                          <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result_odd_even)" width="50" style="text-align: center;margin-bottom: 4px; height: 30px;">{{ result.fight_no }}</v-img>
                          <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result_small_big)" width="50" style="text-align: center;margin-bottom: 4px; height: 30px;">{{ result.fight_no }}</v-img>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["rows", "cols"],

  computed: {
      ...mapGetters("$_dashboard_live", {
        resultObj: "getResult",
        channel: "getChannel",
      }),
      orderResult() {
          return this.getListResults();
      },
      number(bet) {
      return this.getNumber(bet);
      }
  },
  methods: {
    findImageOfEachAnimal(animalName) {
          switch (animalName) {
            case "1":
                return "/images/taisiev/1.png";
            case "2":
                return "/images/taisiev/2.png";
            case "3":
                return "/images/taisiev/3.png";
            case "4":
                return "/images/taisiev/4.png";
            case "5":
                return "/images/taisiev/5.png";
            case "6":
                return "/images/taisiev/6.png";
            case "odd":
                return "/images/taisiev/odd.png";
            case "small":
                return "/images/taisiev/small.png";
            case "even":
                return "/images/taisiev/even.png";
            case "big":
                return "/images/taisiev/big.png";
            default:
              return '';
          }
    },
    _resultColor(color) {
      switch (color) {
        case 'cir-blue':
          return 'trend-wala'
        case 'cir-red':
          return 'trend-meron'
        case 'cir-green':
          return 'trend-draw'
        case 'cir-cancel':
          return 'trend-cancel'
        default:
          return ''
      }
    }
  },
};
</script>

<style scoped>
.kk-table td {
width: 50px;
height: 163px;
line-height: 30px;
font-size: 10px;
text-align: center;
border: 1px solid #c5c5c5;
padding: 0px;
}

.kk-table td>div {
width: 30px;
}
table tr td{
      text-align: center;
      margin-left: auto;
      margin-right: auto;
  border: 1px solid #c5c5c5;
  }
  table{
      width: 100% !important;
      max-width: 100% !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
</style>