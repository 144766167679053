<template>
  <div class="result-img w-100 row space-screen">
    <div class="elem-2" style="z-index:1000; ">
      <div class="row w-100 m-0 ">
        <div class="text-center w-100">
          <img :src="passListYuki ? `${_detectImg(passListYuki.image)} ` : '?'" class="card-style-rotate" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["passListYuki"],
  components: {
  },

  methods: {
    _detectImg(index) {
      switch (index) {
        case '0001.png':
          return '/images/yuki/y1.png';
        case '0002.png':
          return '/images/yuki/y2.png';
        case '0003.png':
          return '/images/yuki/y3.png';
        case '0004.png':
          return '/images/yuki/y4.png';
        case '0005.png':
          return '/images/yuki/y5.png';
        case '0006.png':
          return '/images/yuki/y6.png';
        case '0007.png':
          return '/images/yuki/y7.png';
        case '0008.png':
          return '/images/yuki/y8.png';
        case '0009.png':
          return '/images/yuki/y9.png';
        case '0010.png':
          return '/images/yuki/y10.png';
        case '0011.png':
          return '/images/yuki/y11.png';
        case '0012.png':
          return '/images/yuki/y12.png';
        default:
          return ''
      }
    },
  }
}
</script>
<style scoped>
.elem-2 {
  width: 100%;
  height: 51px;
  content: "";

  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  /* border-style: solid;
  border-width: 2px; */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.4), rgba(0, 0, 0, 0.2));
  /* -o-border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
    border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1; */
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  /* background: rgb(0, 0, 0,0.5); */

}

.card-style-rotate {
  width: 70px;
  height: 52px;
  margin-top: 0px;
  background: white;
  border-radius: 5px;
}

@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 1px;
  }
}
</style>
