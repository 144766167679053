<template>
    <div class="trends limit-on-tab">
      <div>
        <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-1"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk1") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-2"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk2") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-3"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk3") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-4"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk4") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-5"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk5") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-6"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk6") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary.CANCEL }}</span>
            <p class="mb-0 count-label">Cancelled</p>
          </div>
        </div>
        <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
          <div class="table-responsive pb-3 gp-scroll">
            <table class="kk-table" style="height:167px">
              <tbody>
                <tr>
                  <td v-for="col in cols" :key="col">
                    <div>
                      <div v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)" :key="i" class="text-center" style="width:50px;max-width:50px;margin-left: auto; margin-right: auto; text-align: center;">
                        <div v-if="(col-1) == i">
                            <div width="50" style="text-align: center;margin-bottom: 4px; color: black; background-color: rgb(113 184 255);"><b>#{{result.fight_no}}</b></div>
                            <v-img v-if="result.result1_name" :src="findImageOfEachAnimal(result.result1_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 50px;"></v-img>
                            <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result2_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 50px;"></v-img>
                            <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result3_name)" width="50" style="text-align: center;margin-bottom: 4px; height: 50px;"></v-img>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
                <v-row style="padding:0; margin:0">
                    <v-col cols="12" md="12" sm="12" lg="12" style="padding:0 5px 0 0; margin:0">
                            <div class="table-responsive pb-3 gp-scroll">
                              <table class="trend-table">
                                <tbody>
                                  <tr>
                                    <td v-for="col in cols" :key="col">
                                      <div>
                                        <div v-for="(result, i) in resultObj.results" :key="i" class="text-center" style="width:50px;max-width:50px;margin-left: auto; margin-right: auto; text-align: center;">
                                          <div v-if="(col-1) == i">
                                              <v-img v-if="result.result1_name" :src="findImageOfEachAnimal(result.result1_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                              <v-img v-if="result.result2_name" :src="findImageOfEachAnimal(result.result2_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                              <v-img v-if="result.result3_name" :src="findImageOfEachAnimal(result.result3_name)" width="50" style="text-align: center;margin-bottom: 4px; object-fit: cover; height: 50px;"></v-img>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" style="padding:0; margin:0">
                            <div class="table-responsive pb-3 gp-scroll">
                                <table class="trend-table">
                                    <tbody>
                                        <tr v-for="row in rows" :key="row">
                                            <td v-for="col in cols" :key="col">
                                              <div style="width: 25px;">
                                                <div v-for="(result, i) in resultObj.results" :key="i">
                                                  <span v-if="col == result.cid && row == result.rid">
                                                    <v-avatar 
                                                      :color="result.color"
                                                      size="30"                  
                                                      style="box-shadow: rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                                                      >                  
                                                      <span class="black--text" style="font-weight:bold;">
                                                        {{result.fight_no}}
                                                      </span>
                                                    </v-avatar>
                                                  </span>
                                                </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </v-col>
                </v-row>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: ["rows", "cols"],
  
    computed: {
        ...mapGetters("$_dashboard_live", {
          resultObj: "getResult",
          channel: "getChannel",
        }),
        orderResult() {
            return this.getListResults();
        },
        number(bet) {
        return this.getNumber(bet);
        }
    },
    methods: {
      findImageOfEachAnimal(animalName) {
            switch (animalName) {
              case "ក្តាន់":
                  return "/images/klaklok/0001.png";
              case "ឃ្លោក":
                  return "/images/klaklok/0002.png";
              case "មាន់":
                  return "/images/klaklok/0003.png";
              case "បង្កង":
                  return "/images/klaklok/0004.png";
              case "ក្ដាម":
                  return "/images/klaklok/0005.png";
              case "ត្រី":
                  return "/images/klaklok/0006.png";
              case "ខ្លា":
                  return "/images/klaklok/0007.png";
              default:
                return ''
            // code block
            }
      },
      _resultColor(color) {
        switch (color) {
          case 'cir-blue':
            return 'trend-wala'
          case 'cir-red':
            return 'trend-meron'
          case 'cir-green':
            return 'trend-draw'
          case 'cir-cancel':
            return 'trend-cancel'
          default:
            return ''
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .kk-table td {
  width: 50px;
  height: 163px;
  line-height: 30px;
  font-size: 10px;
  text-align: center;
  border: 1px solid #c5c5c5;
  padding: 0px;
}

.kk-table td>div {
  width: 50px;
}
  table tr td{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    border: 1px solid #c5c5c5;
    }
    table{
        width: 100% !important;
        max-width: 100% !important;
      overflow-y: scroll !important;
      overflow-x: scroll !important;
    }
  </style>