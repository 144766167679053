import Request from '@/services/axios'

let request = new Request;

const fetchReportDeposit = async ({commit}, data) => {
    commit('UPDATE_LOADING', true)
    const response = await request.post({
        url: 'report/deposit',
        data
    })
    if (response.data.code) {
        commit('REPORT_DEPOSIT', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

export default {
	fetchReportDeposit
};