<template>
  <div>
    <div class="lotto-nav-head">
      <Tabs />
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="grid-container">
        <div v-for="number in 80" :key="number" class="lotto-number number">
          <div v-for="(result, i ) in passClientRandom.lotto" :key="i" :class="{
            'blink':
              passClientRandom.lotto[
              passClientRandom.lotto.length - 1
              ] == number,
            'l-number-active': result == number,
          }">
            {{ result == number ? number : "" }}
          </div>
          <span>{{ number ? number : 0 }}</span>
        </div>
      </v-col>

    </v-row>

    <v-row no-gutters>

      <div class="lotto-result-between-min content-middle-on-mobi" >
        <v-row no-gutters>
          <v-col cols="12" sm="12" lg="12" md="12" xs="12" class="x-x-lotto-resul-x-1">

            <div class="lotto-result-between-title">{{ $t("title.result") }} ( {{ $t("title.bet_between") }} )</div>
            <div style="overflow: auto;">
              <table class="tb-record">

                <tr v-for="row in 6" :key="row">
                  <td v-for="col in 20" :key="col" class="stage">
                    <div v-for="(result, i) in orderResultUnderOver" :key="i" class="div-result">
                      <div v-if="col == result.col && row == result.row" :class="`range_${result.range.name}`"
                        class="l-result-icon">
                        {{ result.range.name }}</div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </v-col>

          <v-col cols="12" sm="12" lg="12" md="12" xs="12" class="x-x-lotto-resul-x-2">
            <div class="lotto-result-between-box">
              <div class="lotto-result-between-title">{{ $t("title.result") }} ( {{ $t("title.bet_small_large") }} )</div>
              <div style="overflow: auto;">
                <table class="tb-record pt-1">

                  <tr v-for="row in 6" :key="row">
                    <td v-for="col in 15" :key="col" class="stage">
                      <div v-for="(result, i) in  orderResult" :key="i" class="div-result">
                        <span v-if="col == result.col && row == result.row" :class="result.small_large.name == 'small' ? 'cir-blue' : 'cir-red'
                          " class="l-result-icon"></span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="f-end-line"></div>

            </div>
          </v-col>
        </v-row>

      </div>


    </v-row>
  </div>
</template>


<script>
import Tabs from "../../../../components/machine/Tabs.vue";
export default {
  components: {
    Tabs,
  },
  props: [
    "passClientRandom", "passLastTwentyResults"
  ],
  data() {
    return {
      message: "Hello world",
    }
  },
  watch: {
    passLastTwentyResults() {
    },
  },
  computed: {
    orderResult() {
      return this.getListResults();
    },
    orderResultUnderOver() {
      return this.getListResultsRange();
    },
  },
  methods: {
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
        const item = this.passLastTwentyResults[index];
        if (index == this.passLastTwentyResults.length - 1) {
          newResult.push({
            ...item,
            row,
            col,
          });
        } else {
          const pre = newResult[newResult.length - 1];
          if (pre.small_large.name !== item.small_large.name) {
            newResult.push({
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          } else {
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      return newResult;

    },
    getListResultsRange() {
      if (this.passLastTwentyResults) {
        let row = 1;
        let col = 1;
        let newResult = [];
        for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
          const item = this.passLastTwentyResults[index];
          if (index == this.passLastTwentyResults.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.range.name !== item.range.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= 6) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
          // });
        }
        return newResult;
      }
      return [];

    },


  },
};
</script>
