var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"lotto-nav-head"},[_c('Tabs')],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grid-container",attrs:{"cols":"12"}},_vm._l((80),function(number){return _c('div',{key:number,staticClass:"lotto-number number"},[_vm._l((_vm.passClientRandom.lotto),function(result,i){return _c('div',{key:i,class:{
          'blink':
            _vm.passClientRandom.lotto[
            _vm.passClientRandom.lotto.length - 1
            ] == number,
          'l-number-active': result == number,
        }},[_vm._v(" "+_vm._s(result == number ? number : "")+" ")])}),_c('span',[_vm._v(_vm._s(number ? number : 0))])],2)}),0)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"lotto-result-between-min content-middle-on-mobi"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"x-x-lotto-resul-x-1",attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xs":"12"}},[_c('div',{staticClass:"lotto-result-between-title"},[_vm._v(_vm._s(_vm.$t("title.result"))+" ( "+_vm._s(_vm.$t("title.bet_between"))+" )")]),_c('div',{staticStyle:{"overflow":"auto"}},[_c('table',{staticClass:"tb-record"},_vm._l((6),function(row){return _c('tr',{key:row},_vm._l((20),function(col){return _c('td',{key:col,staticClass:"stage"},_vm._l((_vm.orderResultUnderOver),function(result,i){return _c('div',{key:i,staticClass:"div-result"},[(col == result.col && row == result.row)?_c('div',{staticClass:"l-result-icon",class:`range_${result.range.name}`},[_vm._v(" "+_vm._s(result.range.name))]):_vm._e()])}),0)}),0)}),0)])]),_c('v-col',{staticClass:"x-x-lotto-resul-x-2",attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xs":"12"}},[_c('div',{staticClass:"lotto-result-between-box"},[_c('div',{staticClass:"lotto-result-between-title"},[_vm._v(_vm._s(_vm.$t("title.result"))+" ( "+_vm._s(_vm.$t("title.bet_small_large"))+" )")]),_c('div',{staticStyle:{"overflow":"auto"}},[_c('table',{staticClass:"tb-record pt-1"},_vm._l((6),function(row){return _c('tr',{key:row},_vm._l((15),function(col){return _c('td',{key:col,staticClass:"stage"},_vm._l((_vm.orderResult),function(result,i){return _c('div',{key:i,staticClass:"div-result"},[(col == result.col && row == result.row)?_c('span',{staticClass:"l-result-icon",class:result.small_large.name == 'small' ? 'cir-blue' : 'cir-red'}):_vm._e()])}),0)}),0)}),0)]),_c('div',{staticClass:"f-end-line"})])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }