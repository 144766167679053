export default {
	ticket_id : "Ticket ID",
	game_id: "Game ID",
	date : "Date",
	type_betting : "Betting Type",
	bet_point : "Payout",
	win_loss: "Win/lose",
	cancel :"Cancel",
	print:"Print",
	account:"Account",
	channel: 'Post',
	invoice_no:"Invoice no",
	time:"Date",
	bet_point2:"Bet Point",
	number:"Numbers"
};
