/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'

let request = new Request;

const fetchLogout = async () => {
	await request.post({
		url: 'logout'
	});
};

const fetchLogIn = async ({}, data) => {
	// commit('IS_LOGGING', true);
	return await request.post({
		url: 'user/login',
		data
	})
	// .then(()=>{
	// 	commit('IS_LOGGING', false);
	// });
	
};

// const changePassword = async ({vm, data}) => {
// 	const response = await request.post({
// 		url: 'users/changePassword' + data._id,
// 		data
// 	});
// 	if (response.data.code) {
// 		vm.$toastr.s(`${response.data.message.descriptions}`)
// 	} else vm.$toastr.e(`${response.data.message.descriptions}`)
// }

export default {
	fetchLogout,
	fetchLogIn,
	// changePassword
};
