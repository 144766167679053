<template>
  <v-container style="background: none !important">
    <v-toolbar
      flat
      dark
      style="background-color: transparent !important; text-align: center; margin-top: -75px;">
    </v-toolbar>
    <v-card flat class="card-report">
      <v-card-text class="p-0 card-backround">
        <template>
          <v-form>
            <v-container>
              <v-row>
                <v-col sm="12" lg="12" md="12">
                  <template>
                    <HeaderDeposit @child-checkbox="dateWinLoseFilterValue" />
                    <v-data-table
                      style="
                        font-family: 'khmer mef1';
                        background-color: rgba(0, 0, 0, 0.6);
                      "
                      hide-default-header
                      class="elevation-1 theme--dark data-table"
                      item-key="_id"
                      mobile-breakpoint="NaN"
                      :headers="headersReportDeposit"
                      :footer-props="footerProps"
                      :page.sync="dataRB.page"
                      :items="dataReportDeposit.reports"
                      :items-per-page.sync="dataRB.rowsPerPage"
                      :server-items-length="dataReportDeposit.total_rows"
                      :loading="getLoading"
                      @update:options="reportDeposit"
                      @update:page="reportDeposit"
                      @update:items-per-page="reportDeposit"
                      @update:sort-desc="reportDeposit"
                    >
                      <template v-slot:header="{ props }">
                        <th
                          v-for="(header, index) in props.headers"
                          :key="index"
                          class="text-center pt-3 header-text-style"
                        >
                        {{ $t(`title.${header.text}`) }}
                        </th>
                      </template>
                      <template v-slot:[`header.amount`]="{ header }">
                        {{ header.text }} ({{ getUserCurrency() }})
                      </template>
                      <template v-slot:[`item.bet_type.name`]="{ item }">
                        <v-chip
                          x-small
                          label
                          :class="item.bet_type.color"
                          style="padding: 10px; color: #ffffff; font-size: 12px"
                        >
                          <span class="text-white">
                            {{ item.bet_type.name }}
                          </span>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                        {{ item.date }}
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span v-if="item.amount >= 0" class="c-white">
                          {{ currencyFormat(item.amount) }}
                        </span>
                        <span v-if="item.amount < 0" class="c-red">
                          {{ currencyFormat(item.amount) }}
                        </span>
                      </template>
                      <template v-slot:[`item.old_balance`]="{ item }">
                        {{ currencyFormat(item.old_balance) }}
                      </template>
                      <template v-slot:[`item.new_balance`]="{ item }">
                        {{ currencyFormat(item.new_balance) }}
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import headersReportDeposit from "./_api/columnsReportDeposit";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    HeaderDeposit: () => import("./components/HeaderDeposit.vue"),
  },
  data() {
    return {
      headersReportDeposit: headersReportDeposit,
      newpassword: null,
      accname: null,
      accnumber: null,
      accinfo: null,
      accbalance: null,

      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      loading: false,
      channelType: 1,
      dialog: false,
      account_info: null,
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
    };
  },
  item: {
    type: Object,
    default: null,
  },
  mounted() {
    this.channelType = this.$cookies.get("2bwinnwer_channel_type")
      ? this.$cookies.get("2bwinnwer_channel_type")
      : 1;
  },
  watch: {
    channelId: function (newVal, oldVal) {
      // console.log(`channelId new: ${newVal} old: ${oldVal}`)
      if (newVal != oldVal) {
        this.channelType = newVal;
        this.reportDeposit();
      }
    },
  },
  computed: {
    ...mapGetters("$_deposit", {
      dataReportDeposit: "getReportDeposit",
    }),
    ...mapGetters("$_deposit", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },

  methods: {
    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10,
      };
      this.reportDeposit();
    },
    reset() {
      this.$refs.form.reset();
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$";
      } else if (this.userInfor.currency_type === 2) {
        return "៛";
      } else if (this.userInfor.currency_type === 3) {
        return "฿";
      }
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    async reportDeposit() {
      let body = {};
      if (
        this.dateFilterRB.startDate != null &&
        this.dateFilterRB.endDate != null
      ) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate,
        };
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
        };
      }
      await this.fetchReportDeposit(body);
    },
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_deposit", ["REPORT_DEPOSIT"]),
    ...mapActions("$_deposit", ["fetchReportDeposit"]),
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
}

.container {
  height: auto !important;
}

.header-text-style {
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.698);
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(128, 128, 128, 0.5);
}

.theme--light.v-select .v-select__selections {
  color: white !important;
}

.v-data-footer__icons-before {
  color: white !important;
}

.theme--light.v-btn.v-btn--icon {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: white !important;
}

.theme--light.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled .v-btn__loading,
.theme--light.v-btn.v-btn--disabled .v-icon {
  color: rgba(256, 256, 256, 0.26) !important;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
</style>