const UPDATE_LOADING = (state, data) => {
    state.loading = data;
};
const REPORT_WITHDRAW = (state, data) => {
    state.reportWithdraw = data;
};

export default {
    UPDATE_LOADING,
    REPORT_WITHDRAW
};