<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(todayReport._id)"
      x-small color="primary"
      id="print-button"
    >
    Print
    </v-btn >
    <table :id="`printMe${todayReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            2bwinner
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.fight') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.fight_no ? todayReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.type') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.betting ?  $t(sameGameFindName(passSelectedChannel) + todayReport.betting): "" }} 
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.time') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{todayReport.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.cast') }}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.result != null ? todayReport.result : todayReport.amount != null ? todayReport.amount : todayReport}}</span>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('header.result') }}</td>
                  <td style="padding: 5px; text-align: left" 
                    v-if="todayReport.result_name">
                    {{ todayReport.result_name ?  $t(sameGameFindName(passSelectedChannel) + todayReport.result_name): "" }} 
                  </td>
               
                  <td style="padding: 5px; text-align: left" v-else-if="!todayReport.result_name">
                         {{ todayReport && todayReport.result1_name ? _getResultClass(todayReport.result1_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result2_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result3_name).n :"" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('header.winLose')}}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.is_win ? $t('title.win') :$t('title.lose') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import {  mapGetters} from "vuex";
export default {
  props: ["todayReport"],
  computed: {
        ...mapGetters("$_modules", {
          passSelectedChannel: "getChannelId"
        }),

    },
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>