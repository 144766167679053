export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'hoan nghênh:',
    account_balance: 'số dư tài khoản',
    user_role: 'vai trò người dùng',
    No:'Không',
    your_balance:'số dư của bạn',
    home:'Trang Chủ',
    sbc:'SBC',
    status:'trạng thái',
    time:'thời gian',
    result:'kết quả',
    five_k:'1.5 $',
    ten_k:'2.5 $',
    twenty_k:'5 $',
    five_k1:'12.5 $',
    ten_100k:'25 $',
    twenty_200k:'50 $',
    five_500k:'125 $',
    one_1m:'1M $',
    payout:'thanh toán',
    current_betting:'cá cược hiện tại',
    thin:"Under",
    thick:"Over",
    red:'Red',
    blue:'Blue',
    today_report:'báo cáo hôm nay',
    fight:'trận đánh',
    type:'kiểu',
    amount:'số lượng',
    total_amount: 'Total Amount',
    action:'hoạt động',
    my_profile:'thông tin của tôi',
    persional_info:'thông tin cá nhân',
    change_password:'đổi mật khẩu',
    result_betting:'cá cược kết quả',
    result_win:'kết quả thắng',
    report:'bản báo cáo',
    meron:'màu đỏ',
    wala:'màu xanh dương',
    tie:'cà vạt',
    cancel:'sự hủy bỏ',
    login:'đăng nhập',
    deposit:'đặt cọc',
    withdraw:'rút',
    betting:'cá cược',
    all_betting:'cá cược(All)',
    channel1:'kênh 1',
    channel2:'kênh 2',
    betting1:'BET',
    betLimit:'Giới hạn đặt cược',
    pleaseBet:'hãy đặt cược',
    channel3:'kênh 3',
    channel4:'kênh 4',
    min:'Nhỏ nhất',
    fight_sign:"#",
    max:'Lớn nhất',
    bet_between : 'phạm vi',
    bet_small_large: 'bé nhỏ / to lớn',
    return:"Return",
    result_year: "Result Year",
    result_small_big: "Result Small/Big",
    result_range: "Result Range",
    odd_even:'Odd/Even',
    blue_red:'Blue/Red',
    no_data: "No data ...",
    total_cast:"Tổng số diễn viên",
    total_amount_win:"Tổng số tiền trả nợ",


    yuki_wipe : "Lau đi",
    yuki_street : "Đường phố",
    yuki_karl : "Karl",
    yuki_thao : "Thảo",
    yuki_factory : "Nhà máy",
    yuki_net : "Mạng lưới",
    yuki_thin : "Gầy",
    yuki_thick : "Dày",
    yuki_mimi : "Mimi",
    yuki_mom : "Mẹ",
    yuki_vk : "vk",
    yuki_roka : "Roka",
    yuki_kor : "Kor",
    yuki_odd : "Số lẻ",
    yuki_pair : "Đôi",
    yuki_red : "màu đỏ",
    yuki_blue : "màu xanh da trời",
    yuki_dog : "chó",
    yuki_null : "null",

};
