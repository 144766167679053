<template>
  <div>
    <div class="lotto-nav-head">
      <v-row no-gutters>
        <v-col cols="6">
          <v-avatar style="height: 52px; min-width: 52px; width: 52px; display: none;">
            <img src="images/logo-lotto.png" />
          </v-avatar>
          <!--
          <div class="head-right-right" style="width: 70%">
            <ul style="width: 100%">
              <li>
                {{ moment(getLastOneResult.date).format("YYYY-MM-DD") }}
                <span class="item-right">#{{ getLastOneResult.no }}</span>
              </li>
              <li>
                {{ $t("title.total") }}
                <span class="item-right">{{
                  getLastOneResult.total_random
                }}</span>
              </li>
            </ul>
          </div>-->

          <div class="head-right-right" style="width: 100%; float:  left;">
            <ul style="width: 100%">
              <li>
                {{ moment(getLastOneResult.date).format("YYYY-MM-DD") }}
                <span class="item-right">#{{ getLastOneResult.no }}</span>
              </li>
              <li>
                {{ $t("title.total") }}
                <span class="item-right">{{
                  getLastOneResult.total_random
                }}</span>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="6" v-if="passClientTime && passClientTime.time" st>
          <div class="head-right-last" style="background-color: rgba(159, 203, 254, 0.3)">
            <!-- <div class="box-number"></div> -->
            <span class="span-number span-number-on-run"
              :style="passClientTime.time <= 10 ? 'color:darkred!important; text-shadow:1px 1px gold' : ''">{{
                passClientTime
                && passClientTime.time ? passClientTime.time : 0 }}</span>
          </div>
        </v-col>
        <v-col cols="3" v-if="passClientRandom && passClientTime.time <= 0" class="l-logo-result-random-result">
          <div :class="passRandomNumber ? '' : 'focus-one-result'">
            {{
              passRandomNumber
              ? passRandomNumber
              : passClientRandom.lotto
                ? passClientRandom.lotto.slice(-1)[0]
                : 0
            }}
          </div>
        </v-col>
        <v-col cols="3" v-if="!passClientTime.time && passClientRandom && passClientRandom.total
              ">
          <div class="head-right-last">
            <div class="box-number">
              {{
                passClientRandom.lotto && passClientRandom.lotto.length
                ? passClientRandom.lotto.length
                : 0
              }}
            </div>
            <span class="span-number">{{ passClientRandom.total }}</span>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row no-gutters>
      <v-col cols="6" class="content-right-col-box-blue" v-for="(item, index) in buttomTypeUnder" :key="index + item._id"
        :disable="!ready" @click="createBetting(item._id)">
        <div class="content-right-box-blue">
          <ul>
            <li style="font-family: 'khmer mef1'">{{ $t("title.small") }}</li>
            <li>{{ item.payout }}</li>
          </ul>
          <div class="content-box">
            {{ item.start_number }} - {{ item.end_number }}
          </div>
        </div>
      </v-col>

      <v-col cols="6" class="content-right-col-box-red" v-for="(item, index) in buttomTypeOver" :key="index + item._id"
        :disable="!ready" @click="createBetting(item._id)">
        <div class="content-right-box-red">
          <ul>
            <li style="font-family: 'khmer mef1'">{{ $t("title.large") }}</li>
            <li>{{ item.payout }}</li>
          </ul>
          <div class="content-box">
            {{ item.start_number }} - {{ item.end_number }}
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="content-right-item">
        <div class="right-item-box" v-for="(item, index) in buttomTypeGame" :key="index + item._id" :disable="!ready"
          @click="createBetting(item._id)">
          <div class="item-box-head">{{ item.name }}</div>
          <div class="item-box-body">
            <ul>
              <li>{{ item.start_number }} - {{ item.end_number }}</li>
              <li>{{ item.payout }}</li>
            </ul>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="content-right-textbox">
        <ul>
          <li class="text-bg pt-2">
            <!-- {{ $t("title.credit") }}: -->
            <!-- <span class="text-yellow text-bg">{{
              userInfor.balance
                ? userInfor.balance.toLocaleString("en")
                : "loading.."
            }}</span> -->
            <span class="item-right">
              <span>{{ $t("title.min") }}:</span>
              <span class="text-yellow text-sm">{{
                amount_min ? amount_min.toLocaleString("en") : "Loading.."
              }}</span>
              <span>|</span> <span>{{ $t("title.max") }}:</span>
              <span class="text-yellow text-sm">{{
                amount_max ? amount_max.toLocaleString("en") : "Loading.."
              }}</span>
            </span>
          </li>
          <li class="input-bet mt-5">
            <input placeholder="ENTER BET AMOUNT" v-model="amount" type="number" pattern="[0-9]*" min="1"
              onlynumber="true" class="" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
            <span><v-icon @click="handleClear">mdi-history</v-icon></span>
          </li>
        </ul>
      </v-col>

      <v-col cols="12" class="content-right-buttom">
        <div class="lotto-button-item py-2" style="text-align: center" v-for="(item, i) in buttonPayGame" :key="i"
          @click="getAmount(item.amount)">
          <span style="font-family: 'khmer mef1'; font-size: 1.15rem" class="">{{ item.label }}</span>
        </div>
      </v-col>


      <v-col cols="12" class="content-right-on-mobi lotto-result-between-min" style="padding-bottom: 46px !important;">
        <div class="lotto-result-between-title">{{ $t("title.result") }} ( {{ $t("title.bet_between") }} )</div>
        <div style="overflow: auto;">
          <table class="tb-record">

            <tr v-for="row in 6" :key="row">
              <td v-for="col in 20" :key="col" class="stage">
                <div v-for="(result, i) in orderResultUnderOver" :key="i" class="div-result">
                  <div v-if="col == result.col && row == result.row" :class="`range_${result.range.name}`"
                    class="l-result-icon">
                    {{ result.range.name }}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </v-col>

      <v-col cols="12" class="content-right-on-mobi lotto-result-between-min" style="padding-bottom: 16px !important;">

        <div class="lotto-result-between-box">
          <div class="lotto-result-between-title">{{ $t("title.result") }} ( {{ $t("title.bet_small_large") }} )</div>
          <div style="overflow: auto;">
            <table class="tb-record pt-1">

              <tr v-for="row in 6" :key="row">
                <td v-for="col in 15" :key="col" class="stage">
                  <div v-for="(result, i) in  orderResult" :key="i" class="div-result">
                    <span v-if="col == result.col && row == result.row" :class="result.small_large.name == 'small' ? 'cir-blue' : 'cir-red'
                      " class="l-result-icon"></span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  // DEFINE local VARIABLE
  props: ["passClientTime", "passClientRandom", 'passRandomNumber', 'passLastTwentyResults'],
  data() {
    return {
      buttomTypeGame: [],
      buttomTypeUnder: [],
      buttomTypeOver: [],
      buttonPayGame: [],
      amount: 0,
      amount_min: null,
      amount_max: null,
      ready: true
    };
  },
  watch: {
    passLastTwentyResults() {
    },
  },
  created() {
    this.getButtomGamge();
    this.getButtonPayGame();
    this.getLimit();
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_machine", {
      getLastOneResult: "getLastOneResult",
    }),
    orderResult() {
      return this.getListResults();
    },
    orderResultUnderOver() {
      return this.getListResultsRange();
    },
  },
  methods: {
    moment,
    async getButtomGamge() {
      await this.$request
        .getSlot({
          url: "m_lotto/type",
        })
        .then((res) => {
          let xData = [];
          if (res.data.code) {
            xData = res.data.data;
            xData.forEach((item) => {
              if (item.name == "small") {
                this.buttomTypeUnder.push(item);
              } else if (item.name == "large") {
                this.buttomTypeOver.push(item);
              } else {
                this.buttomTypeGame.push(item);
              }
            });
          }
        });
    },
    async createBetting(type_id_par) {
      if (!this.ready) return this.$toastr.e(this.$t('title.wait'));
      this.ready = false;
      let passData = {
        type_id: type_id_par,
        amount: parseInt(this.amount),
      };
      await this.$request
        .postSlot({
          url: "m_lotto/betting",
          data: passData,
        })
        .then((res) => {
          if (res.data.code) {
            this.$toastr.s(res.data.message.descriptions);
            this.fetchUserInfo();
            this.fetchCurrentBetting();
            this.ready = true;
            this.handleClear();
          } else {
            this.ready = true;
            this.$toastr.e(res.data.message.descriptions);
          }
        });
    },
    async getButtonPayGame() {
      await this.$request
        .getSlot({
          url: "m_lotto/button",
        })
        .then((res) => {
          if (res.data.code) {
            this.buttonPayGame = res.data.data;
          }
        });
    },

    getAmount(money) {
      if (this.amount) this.amount = parseInt(this.amount) + parseInt(money);
      else this.amount = parseInt(money);
      this.errorMessage = null;
    },
    handleClear() {
      this.amount = 0;
      this.errorMessage = null;
    },

    async getLimit() {
      await this.$request
        .getSlot({
          url: "m_lotto/limit",
        })
        .then((res) => {
          if (res.data.code) {
            this.amount_min = res.data.data.amount_min;
            this.amount_max = res.data.data.amount_max;
          }
        });
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
        const item = this.passLastTwentyResults[index];
        if (index == this.passLastTwentyResults.length - 1) {
          newResult.push({
            ...item,
            row,
            col,
          });
        } else {
          const pre = newResult[newResult.length - 1];
          if (pre.small_large.name !== item.small_large.name) {
            newResult.push({
              ...item,
              row: 1,
              col: pre.col + 1,
            });
          } else {
            if (pre.row >= 6) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              newResult.push({
                ...item,
                row: pre.row + 1,
                col: pre.col,
              });
            }
          }
        }
      }
      return newResult;

    },
    getListResultsRange() {
      if (this.passLastTwentyResults) {
        let row = 1;
        let col = 1;
        let newResult = [];
        for (var index = this.passLastTwentyResults.length - 1; index >= 0; index--) {
          const item = this.passLastTwentyResults[index];
          if (index == this.passLastTwentyResults.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.range.name !== item.range.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= 6) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
          // });
        }
        return newResult;
      }
      return [];

    },
    ...mapActions("$_machine", ["fetchCurrentBetting"]),

    ...mapActions("$_modules", ["fetchUserInfo"]),
  },
};
</script>
