import Request from '@/services/axios'
let request = new Request;
const fetchDarkMode = async ({ commit }, payload) => {
	commit('UPDATE_DARK_MODE', payload);
};
const updateBalance = async({ commit }, data) => {
    commit('UPDATE_BALANCE', data);
};
const updateUserInfo = async({ commit }, data) => {
    commit('UPDATE_USER_INFO', data);
};
const fetchUserInfo = async ({ commit }) => {
	const response = await request.get({
        url: 'user'
    })
	if (response.data.code) {
        commit('UPDATE_USER_INFO', response.data.data);
    }
};

export default {
	fetchDarkMode,
	updateBalance,
	updateUserInfo,
	fetchUserInfo
};