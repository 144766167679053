<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary pt-2">
                <div class="sides-wrapper gp-panel-bg rounded">
                    <div class="d-flex sides bet-btn-container">
                        <div v-if="meronButton" class="side-section meron-section pb-1 side-1"
                            :class="selectedBtnClassMeron ? 'on-active-btn' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''"
                            @click="_allowBtnBet('selectedMeron', meronButton)">

                            <div class=" pt-10 mb-0 h4 "><span style="font-weight:600">
                                {{
                                    _formatAmountCurrency(userInfor, passCurrentBetting.meron )
                                }}
                                </span>
                                <div class="side-title pt-3">
                                    <div class="truncate font-weight-bold">{{ meronButton ? $t('title.' + meronButton.name)
                                        : ''
                                    }}</div>
                                </div>

                                <div class="odds pt-3 h3">{{ passWeight ? passWeight.meron.payout : 'x.xx' }}</div>
                           
                            </div>
                        </div>
                        <div v-if="tieButton" class="side-section-tie tie-section  mx-2 side-1"
                            :class="selectedBtnClassTie ? 'on-active-btn' : ''" @click="_allowBtnBet('selectedTie')" 
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''">
                
                            <div class="pt-10 mb-0 h4 "><span style="font-weight:600">{{
                                _formatAmountCurrency(userInfor, passCurrentBetting.tie )
                            }}</span>
                            <div class="side-title pt-3">
                                <span class="truncate font-weight-bold">{{ $t('title.draw') }}</span>
                            </div>
                                <div class="odds h3 pt-3 ">{{ passWeight ? `1: 8` : 'x.xx' }}</div>
                   
                            </div>
                        </div>
                        <div
                            v-if="walaButton" 
                            class="side-section wala-section pb-1 side-1"
                            :class="selectedBtnClassWala ? 'on-active-btn' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:1;' : ''"
                            @click="_allowBtnBet('selectedWala')">
                            
                            <div class="pt-10 mb-0 h4"><span style="font-weight:600">{{
                                _formatAmountCurrency(userInfor, passCurrentBetting.wala )
                            }}</span>
                            <div class="side-title pt-3">
                                <div class="truncate font-weight-bold ">{{ walaButton ? $t('title.' + walaButton.name) :
                                    ''
                                }}</div>
                            </div>
                                <div class="odds h3 pt-3">{{ passWeight ? passWeight.wala.payout : 'x.xx' }}</div>
                       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Group Coin -->
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"/>
                <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting :passWeight="passWeight"/>
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfo.vue';
import LimitBetting from '../../../../components/LimitBetting.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import Mixin from "../../../../mixin/index.js"
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
    props: [
        "passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            selectedCoin: false,
            mixins: [Mixin],
        }
    },
    watch: {
        dialogBetting(newV) {
            !newV ? this.bettingMoney = 0 : '';
        },
    },
    computed: {

        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        _checkAllowSubmit() {
            if ( this.getAmountBetting > 0 && 
                    ( this.selectedBtnClassMeron || this.selectedBtnClassTie || this.selectedBtnClassWala )) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard", {
            getAmountBetting:"getAmountBetting",
            dialogBetting: "getDialogBetting",
            getIsSelectedCoin : "getIsSelectedCoin"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        checkSattus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        checkStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _detectCurrentMoneyCoinMeron(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorMeron(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinWala(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorWala(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinTie(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorTie(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _clearMoney() {
            this.CLEAR_BETTING_AMOUNT();
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
            this.CLEAR_COIN_SELECTION(false)
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.getAmountBetting
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.getIsSelectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.selectedButton = this.meronButton;
                    this.selectedBtnClassMeron = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassWala = false;
                  
                    break;
                case 'selectedTie':
                    this.selectedButton = this.tieButton;
                    this.selectedBtnClassTie = true;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                  
                    break;
                case 'selectedWala':
                    this.selectedButton = this.walaButton;
                    this.selectedBtnClassWala = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                  
                    break;
                default :
                    break;
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ពាន់";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "មុឺន";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "លាន";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatCoinCurrency(amount) {
            switch (amount) {
                case 5000:
                    return '5ពាន់';
                case 10000:
                    return '1មុឺន';
                case 20000:
                    return '2មុឺន';
                case 50000:
                    return '5មុឺន';
                case 100000:
                    return '10មុឺន';
                case 200000:
                    return '20មុឺន';
                default:
                    return amount;
            }

        },
        ...mapMutations("$_dashboard", [
            "BETTING_DIALOG",
            "CLEAR_BETTING_AMOUNT",
            "CLEAR_COIN_SELECTION"
        ]),
    }
}
</script>
<style>
.truncate{
    font-size:1.7rem!important;
    font-weight: 700;
    /* text-shadow: 3px 0px 7px rgba(245, 243, 230, 0.1), -3px 0px 7px rgba(252, 246, 229, 0.1), 0px 4px 7px rgba(221, 216, 199, 0.1); */
    text-shadow : -1px 0 2px black;
}
@media(min-width:1280px) {

   
}
.bet-btn-container{
    padding-right: 13px!important;
    padding-left: 10px!important;
}
</style>