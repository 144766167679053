<template>
  <v-container class="bg-surface-variant" >
      <v-row no-gutters class="lotto-border">
        <v-col>
          <MobileSection/>
          <MobileDT/>
        </v-col>
      </v-row>
    
  </v-container>
</template>

<script>
import MobileDT from "./components/MobileDT.vue";
import MobileSection from "./components/MiddleSection.vue";

export default {
  // DEFINE local VARIABLE
  components: {
  MobileDT,
  MobileSection
},
  props: ["passClientTime", "passClientRandom", 'passRandomNumber','passLastTwentyResults'],
  }
</script>

