<template>
  <div class="trends limit-on-tab">
    <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center pb-0 pt-5"
      style="background-color: #ffffff !important; padding: 6px !important;">
      <table width="100%" style="
    display: block;
    overflow-x: auto;
    white-space: nowrap;border: 0 !important; border-collapse: separate; border-spacing:10px 6px;">
          <tbody style="display: table;
  width: 100%;">
            <tr>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle trend-wala">
                  {{resultObj.summary.YEAR1}}</span>
                  <p class="mb-0 count-label">Rat</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle trend-wala">
                  {{resultObj.summary.YEAR2}}</span>
                  <p class="mb-0 count-label">Ox</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center mr-1"><span class="count rounded-circle trend-wala">{{
                  resultObj.summary.YEAR3
                }}</span>
                  <p class="mb-0 count-label">Tiger</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
                  resultObj.summary.YEAR4
                }}</span>
                  <p class="mb-0 count-label">Rabbit</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
                  resultObj.summary.YEAR5
                }}</span>
                  <p class="mb-0 count-label">Dragon</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
                  resultObj.summary.YEAR6
                }}</span>
                  <p class="mb-0 count-label">Snake</p>
                </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-thick">{{
                  resultObj.summary.LARGE
                }}</span>
                  <p class="mb-0 count-label">{{ $t("title.thick") }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 0 !important;">
      <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-wala">{{
        resultObj.summary.YEAR7
      }}</span>
        <p class="mb-0 count-label">Horse</p>
      </div>
              </td>
              <td style="border: 0 !important;">
      <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-wala">{{
        resultObj.summary.YEAR8
      }}</span>
        <p class="mb-0 count-label">Goat</p>
      </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-wala">{{
        resultObj.summary.YEAR9
      }}</span>
        <p class="mb-0 count-label">Monkey</p>
      </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
        resultObj.summary.YEAR10
      }}</span>
        <p class="mb-0 count-label">Rooster</p>
      </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
        resultObj.summary.YEAR11
      }}</span>
        <p class="mb-0 count-label">Dog</p>
      </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-meron">{{
        resultObj.summary.YEAR12
      }}</span>
        <p class="mb-0 count-label">Pig</p>
      </div>
              </td>
              <td style="border: 0 !important;">
                <div class="bad-cont d-inline-flex align-items-center "><span class="count rounded-circle trend-thin">{{
                  resultObj.summary.SMALL
                }}</span>
                  <p class="mb-0 count-label">{{$t("title.thin") }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
      style="position: relative; background-color: #ffffff !important;">
      <v-col cols="12" sm="12" style="padding:5px 0">
        <v-row style="padding:0; margin:0">
          <v-col cols="6" md="6" sm="6" lg="6" style="padding:0 5px 0 0; margin:0">
      <div class="table-responsive pb-3 gp-scroll">
        <table class="trend-table">
          <tbody>
            <tr v-for="row in rows" :key="row">
              <td class="result" v-for="col in channel.fix_column" :key="col">
                <div v-if="channel.column - channel.fix_column <= 0">
                  <div v-for="(result, i) in resultObj.results" :key="i">
                    <span v-if="col == result.cid && row == result.rid">
                      <v-img :src="findImageOfEachYear(result.year.year_number)"></v-img>
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(result, i) in resultObj.results" :key="i">
                    <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid">
                      <v-img :src="findImageOfEachYear(result.year.year_number)"></v-img>
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
          </v-col>
          <v-col cols="6" md="6" sm="6" lg="6" style="padding:0 5px 0 0; margin:0">
            <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td class="result" v-for="col in channel.fix_column" :key="col">
                        <div v-if="channel.column - channel.fix_column <= 0">
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="col == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="'background:' + result.small_large.color" style="width: 40px; height: 40px;" fab dark>
                                <v-icon>mdi-checkbox-blank-circle</v-icon>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                        <div v-else>
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="(col + (channel.column - channel.fix_column)) == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="'background:' + result.small_large.color" style="width: 40px; height: 40px;" fab dark>
                                <v-icon>mdi-checkbox-blank-circle</v-icon>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </v-col>
        </v-row>
      </v-col>
    </div>
    <!-- <v-tab-item>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="col == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="'background:' + result.small_large.color" style="width: 35px; height: 35px;" fab dark>
                                <v-icon>mdi-checkbox-blank-circle</v-icon>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="col == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="' background:' + result.range.color" style="width: 38px; height: 38px;" fab small dark class="btn-range">
                                <span class="range-name">
                                  {{result.range.name}}
                                </span>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="col == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="' background:' + result.odd_even.color" style="width: 35px; height: 35px;" fab small dark class="btn-range">
                                <span class="range-name">
                                  {{result.odd_even.name==='even' ? 'e' : 'o'}}
                                </span>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend" style="position: relative; background-color: #ffffff !important;">
              <div class="table-responsive pb-3 gp-scroll">
                <table class="trend-table">
                  <tbody>
                    <tr v-for="row in rows" :key="row">
                      <td v-for="col in cols" :key="col">
                        <div>
                          <div v-for="(result, i) in resultObj.results" :key="i" class="div-result">
                            <span v-if="col == result.cid && row == result.rid" class="l-result-icon">
                              <v-btn :style="' background:' + result.blue_red.color" style="width: 35px; height: 35px;" fab small dark class="btn-range">
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-tab-item> -->


  </div>
</template>
<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
export default {
  props: ["rows", "cols"],
  data() {
    return {
      fixCol: 30,
      fixColBac: 20
    }
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    orderResult() {
      return this.getListResults();
    },
    orderBaccaratResult() {
      return this.getBaccaratResults();
    },
    getColor(resultName) {
      if (resultName === 'BANKER') {
        return "error"
      } else if (resultName === 'PLAYER') {
        return "primary";
      } else if (resultName === 'TIE') {
        return "success";
      } else {
        return "default";
      }
    }
  },
  methods: {
    getBaccaratResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results !== null) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          // console.log(index)
          const item = resultList[index];
          // console.log(item)
          if (row <= this.rows) {
            // console.log('row <= this.rows')
            // console.log(row)
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          } else {
            // console.log('else')
            row = 1
            col += 1
            newResult.push({
              ...item,
              row,
              col,
            });
            row += 1;
          }
        }
      }
      return newResult;
    },
    getListResults() {
      let row = 1;
      let col = 1;
      let newResult = [];
      if (this.resultObj.results !== null && this.resultObj.results.length > 0) {
        let resultList = this.resultObj.results;
        for (var index = resultList.length - 1; index >= 0; index--) {
          const item = resultList[index];
          if (index == resultList.length - 1) {
            newResult.push({
              ...item,
              row,
              col,
            });
          } else {
            const pre = newResult[newResult.length - 1];
            if (pre.name !== item.name) {
              newResult.push({
                ...item,
                row: 1,
                col: pre.col + 1,
              });
            } else {
              if (pre.row >= this.rows) {
                newResult.push({
                  ...item,
                  row: 1,
                  col: pre.col + 1,
                });
              } else {
                newResult.push({
                  ...item,
                  row: pre.row + 1,
                  col: pre.col,
                });
              }
            }
          }
        }
        if (newResult[newResult.length - 1].col > this.cols) {
          this.fixCol = newResult[newResult.length - 1].col
        } else {
          this.fixCol = this.cols
        }
      }
      return newResult;
    },
    findImageOfEachYear(yearId) {
      switch (yearId) {
        case 1:
          return '/images/yuki/y1o.png';
        case 2:
          return '/images/yuki/y2o.png';
        case 3:
          return '/images/yuki/y3o.png';
        case 4:
          return '/images/yuki/y4o.png';
        case 5:
          return '/images/yuki/y5o.png';
        case 6:
          return '/images/yuki/y6o.png';
        case 7:
          return '/images/yuki/y7o.png';
        case 8:
          return '/images/yuki/y8o.png';
        case 9:
          return '/images/yuki/y9o.png';
        case 10:
          return '/images/yuki/y10o.png';
        case 11:
          return '/images/yuki/y11o.png';
        case 12:
          return '/images/yuki/y12o.png';
        default:
        // code block
      }
    },
  },
};
</script>

<style scoped>
.history-result td {
  text-align: center;
  height: 45px;
  width: 45px;
  padding: 2px;
  background: #f2f6f9;
  border: 1px solid #96afde;
  vertical-align: middle;
  box-shadow: -1px 0 #e4dbc5, 0 1px #e4dbc5, 1px 0 #e4dbc5, 0 -1px #e4dbc5;
  -moz-box-shadow: 0 0 2px 1px #fff;
  -webkit-box-shadow: 0 0 2px 1px #fff;
  -ms-box-shadow: 0 0 2px 1px #fff;
  -o-box-shadow: 0 0 2px 1px #fff;
}

.round-blue {
  background: url(../../../../assets/images/round-blue.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.round-red {
  background: url(../../../../assets/images/round-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.cycle-blue {
  background: url(../../../../assets/images/cycle-blue.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.cycle-red {
  background: url(../../../../assets/images/cycle-red.png) no-repeat center;
  background-size: 34px;
  display: block;
  height: 34px;
}

.center-padding-0 {
  text-align: center;
  padding: 0px !important;
}

.result-table {
  width: 100%;
  margin: 0;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.trend-table {
  border-spacing: 0px !important;
}

.trend-table td {
  width: 40px;
  height: 40px;
  font-size: 10px;
  text-align: center;
  padding: 0px;
}

.trend-table tr,
td {
  border: 1px solid #c5c5c5;
}

/* table tbody{
    height: 156px;
} */

td.result>div {
  width: 40px;
}

/* v-tabs{
  background: white !important;
} */
.theme--light.v-tabs>.v-tabs-bar {
  display: none !important;
}

.v-tabs-bar__content {
  display: none !important;
}
</style>
