<template>
  <v-container > 
      <template>
        <v-card>
          <v-toolbar
            flat
            dark
            style="background-color:#003366"
          >
            <v-toolbar-title style="font-family:'khmer mef1';line-height:2.3"> {{$t("title.result")}} 
                <span>{{this.channelType == 4 ? "KD" : this.channelType == 1 ? "PH" : `Channel${this.channelType}`}}</span>
            </v-toolbar-title>
            </v-toolbar>
           <v-card flat>
                    <v-card-text>
                        <template>
                          <v-form>
                            <v-container>
                            <v-row>
                             <v-col sm="12" lg="12" md="12"> 
                                <template>
                                  <HeaderResult
                                          @child-checkbox="dateWinLoseFilterValue"
                                      />
                                    <v-data-table
                                    style="font-family: 'khmer mef1'"
                                    class="elevation-1"
                                    item-key="_id"
                                    :headers="headersReportResult"
                                    mobile-breakpoint = NaN
                                    :footer-props="footerProps"
                                    :page.sync="dataRB.page"
                                    :items="dataReportResult.results"
                                    :items-per-page.sync="dataRB.rowsPerPage"
                                    :server-items-length="dataReportResult.total_rows"
                                    :loading="getLoading"
                                    @update:options="reportResult"
                                    @update:page="reportResult"
                                    @update:items-per-page="reportResult"
                                    @update:sort-desc="reportResult"
                                  >
                                    <template v-slot:[`item.date`]="{ item }">
                                      {{ convertDateTime(item.date) }} 
                                      {{ convertDateTime(item.text) }} 
                                    </template>
                                    <template v-slot:[`item.name`]="{ item }">
                                      <v-chip label :class="item.color" style="padding:10px;color:#ffffff; font-size:12px;height:22px">
                                          <span class="white--text">{{item.name}}</span>
                                      </v-chip>
                                    </template>
                                  </v-data-table>
                                </template>
                             </v-col>
                            </v-row>
                            </v-container>
                          </v-form>
                        </template>
                    </v-card-text>
                    </v-card>
        </v-card>
        </template>
    </v-container>
</template>


<script>
import headersReportResult from "./_api/columnsReportResult";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    HeaderResult: () => import("./components/HeaderResultBetting.vue"),
  },
  data () {
    return {
      headersReportResult: headersReportResult,
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      loading: false,
      dialog: false,
      channelType: 1,
      account_info:null,
      dataRB: {
        page: 1,
        rowsPerPage: 10
      },
      dateFilterRB:{

      },
    }
  },
  item: {
    type: Object,
    default: null,
  },
  // async created() {
  //   await this.fetchReportResult();
  // },
  computed: {
    ...mapGetters("$_result", {
      dataReportResult: 'getReportResult'
    }),
    ...mapGetters("$_result", ["getLoading"]),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
},
mounted(){
  this.channelType = this.$cookies.get('2bwinnwer_channel_type') ? this.$cookies.get('2bwinnwer_channel_type') : 1
},
watch:{
  channelId: function(newVal, oldVal) {
    // console.log(`channelId new: ${newVal} old: ${oldVal}`)
    if(newVal != oldVal){
      this.channelType = newVal
      this.reportResult()
    }
  },
},
methods: {
  dateWinLoseFilterValue: function(params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10
      }
      this.reportResult();
  },
  reset() {
    this.$refs.form.reset();
  },
  currencyFormat (num) {
    num = parseFloat(num)
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  },
  async reportResult(){
    let body ={};
    if(this.dateFilterRB.startDate != null && this.dateFilterRB.endDate != null){
        body = {
            rows_per_page: this.dataRB.rowsPerPage,
            page: this.dataRB.page,
            start_date: this.dateFilterRB.startDate,
            end_date: this.dateFilterRB.endDate
        }
    }else{
        body = {
            rows_per_page: this.dataRB.rowsPerPage,
            page: this.dataRB.page
        }
    }
    await this.fetchReportResult(body)
  },
  ...mapMutations("$_result", ["REPORT_RESULT"]),
  ...mapActions("$_result", ["fetchReportResult"]),
  }
}
</script>
