<template>
    <div class="result-img w-100 row space-screen">
        <div class="elem-2" style="z-index:1000; ">
            <div class="row w-100 m-0 ">
                <div class="text-center w-100">
                    <img  class="card-style-klaklok" v-if="passlistFantan[0] != null " :src="passlistFantan[0].image ? `/images/taisiev/${passlistFantan[0].image}` : ''">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["passlistFantan"],
    components: {
    },

    methods: {
        _detectImg(index) {
            switch (index) {
                default:
                    return ''
            }
        },
    }
}
</script>
<style scoped>
.card-style-klaklok {
  width: 51px;
  height: 47px;
  padding-top:3px;
}
.elem-2 {
    width: 100%;
    height: 51px;
    content: "";

    z-index: 1000;
    background-position: 56%, 0, 100% 0;
    /* border-style: solid;
    border-width: 2px; */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.4), rgba(0, 0, 0, 0.2));
    /* -o-border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
      border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1; */
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */

}

.card-style-rotate {
    width: 70px;
    height: 52px;
    margin-top: 0px;
    background: white;
    border-radius: 5px;
}

@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 1px;
  }
}
</style>
  