<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
        class="mt-3"
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary">
                <div class="sides-wrapper gp-panel-bg rounded"  :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                    <div class="d-flex sides">
                        <div v-if="walaButton" class="side-section meron-section pb-3 side-1"
                            :class="selectedBtnClassWala ? 'on-active-btn' : ''"
                            @click="_allowBtnBet('selectedWala', walaButton)">
                            <div class="side-title pt-2">
                                <div class="truncate font-weight-bold">{{ walaButton.name }}</div>
                            </div>
                            <div class="odds pt-5">{{ walaButton?' '+ walaButton.payout_display: 'x.xx'}}
                            </div>
                            <div class=" mb-0 "><span style="font-weight:600">{{
                            
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.dragon_amount) 
                            : formatBtnCurrency(passCurrentBetting.dragon_amount)
                            }}</span>
                            </div>
                        </div>
                        <div class="side-section-tie tie-section mx-1 side-1"
                            :class="selectedBtnClassTie ? 'on-active-btn' : ''" @click="_allowBtnBet('selectedTie')"
                            v-if="meronButton">
                            <div class="side-title pt-2">
                                <div class="truncate font-weight-bold">{{$t('title.tie')}}</div>
                            </div>

                            <div class="odds pt-5 font-weight-bold">{{ tieButton?`1 : ${ tieButton.payout_display } `: 'x.xx' }}</div>
                            <div class="mb-0 "><span style="font-weight:600">{{
                            
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.tie_amount) 
                            : formatBtnCurrency(passCurrentBetting.tie_amount)
                            }}</span>
                            </div>
                        </div>


                        <div class="side-section wala-section pb-3 side-1"
                            :class="selectedBtnClassMeron ? 'on-active-btn' : ''" v-if="meronButton"
                            @click="_allowBtnBet('selectedMeron')">
                            <div class="side-title pt-2">
                                <div class="truncate font-weight-bold">{{ meronButton.name }}</div>
                            </div>
                            <div class="odds pt-5">{{ meronButton? ' '+ meronButton.payout_display: 'x.xx'}}</div>
                            <div class=" mb-0"><span style="font-weight:600">{{
                            userInfor && userInfor.currency_type == 2 ? formatBtnCurrencyKh(passCurrentBetting.tiger_amount) 
                            : formatBtnCurrency(passCurrentBetting.tiger_amount)
                            }}</span>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                v-on:handleInputMonay="_inputAmount"/>

                <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
            <LimitBetting/>
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfoLive.vue';
import LimitBetting from '../../../../components/LimitBettingLive.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel',
        "passUserCurrency"
        
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            selectedBtnClassMeron: false,
            selectedBtnClassTie: false,
            selectedBtnClassWala: false,
            countDown: {},
            selectedCoin: false,

        }

    },

    computed: {

        meronButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        walaButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        tieButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        _checkAllowSubmit() {
            if ( this.bettingMoney> 0 && (this.selectedBtnClassMeron || this.selectedBtnClassWala || this.selectedBtnClassTie)
                ) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard_live", {
            getIsReport: "getIsReport",
            dialogBetting: "getDialogBetting",
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            getCountdown:"getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },

        _detectCurrentMoneyCoinWala(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },

        _inputAmount(money) {
                this.selectedCoin = true
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)
        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassMeron = false;
            this.selectedBtnClassTie = false;
            this.selectedBtnClassWala = false;
            this.selectedCoin = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedMeron':
                    this.selectedButton = this.meronButton;
                    this.selectedBtnClassMeron = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassWala = false;
                    break;
                case 'selectedTie':
                    this.selectedButton = this.tieButton;
                    this.selectedBtnClassTie = true;
                    this.selectedBtnClassMeron = false;
                    this.selectedBtnClassWala = false;
                    break;
                case 'selectedWala':
                    this.selectedButton = this.walaButton;
                    this.selectedBtnClassWala = true;
                    this.selectedBtnClassTie = false;
                    this.selectedBtnClassMeron = false;
                    break;
            }
        },
        ...mapMutations("$_dashboard_live", [
            "BETTING_DIALOG",
            "UPDATE_TIGER_CARD",
            "UPDATE_DRAGON_CARD",
            "UPDATE_IS_REPORT"
        ]),
    }
}
</script>
<style scoped>
@media(max-width:768px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 1;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .space-for-small-screen {
        text-align: center;
    }

    .betting-coin-meron {
        width: 40px;
        height: auto;
        margin: auto;
        bottom: 1px;
        left: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .betting-coin-wala {
        width: 40px;
        height: auto;
        margin: auto;
        bottom: 1px;
        right: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }


    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }



    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position-meron {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        position: absolute;
        z-index: 2000;
        bottom: -5px;
        left: 7px;
        font-size: 14px;
        width: 25px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .money-in-coin-position-wala {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        position: absolute;
        z-index: 2000;
        bottom: -5px;
        right: 9px;
        font-size: 14px;
        width: 25px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        width: 13.1vw;
        margin-top: -42px !important;
    }
}

@media(min-width:768px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .space-for-small-screen {
        text-align: center;
    }

    .betting-coin-meron {
        width: 40px;
        height: auto;
        margin: auto;
        bottom: 1px;
        left: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .betting-coin-wala {
        width: 40px;
        height: auto;
        margin: auto;
        bottom: 1px;
        right: 0;
        /* margin-top: 20px; */
        position: absolute;
        z-index: 1000 !important;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }



    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        width: 150px;
        color: white;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 0px;
        justify-content: center;
    }

    .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position-meron {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        position: absolute;
        z-index: 2000;
        bottom: -5px;
        left: 7px;
        font-size: 14px;
        width: 25px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .money-in-coin-position-wala {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        position: absolute;
        z-index: 2000;
        bottom: -5px;
        right: 9px;
        font-size: 14px;
        width: 25px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        width: 14.1vw;
        margin-top: -49px !important;
    }
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}

.betting-coin-range {
    width: 90px;
    height: auto;
    margin: auto;
    margin-top: -120px;
    z-index: 100 important
}

.money-in-coin-range-position {
    margin-top: -80px;
    margin-bottom: 15px;
    z-index: 100;
    font-weight: 900;
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-shadow: 1px 0px black;
    color: black
}
</style>