import english from './english';
import khmer from './khmer';
import thai from './thai';
import vietnam from './vietnam';

export default {
	english,
	khmer,
	thai,
	vietnam
};

